import h from "../helpers";
import { toJson, uniqArray, titleCase, renderFlowItemType } from "../helpers/typedHelpers";
import { FlowIsRunning, FlowConstants, FlowProcessingTypes } from "../helpers/constants";
import clone from "../helpers/clone";
import { folderReducerKeyForType } from "../helpers/folder";
import { normalize, schema } from "normalizr";
import { push } from "connected-react-router";
import { incAjaxCount, decAjaxCount, lossOfWorkRunQueuedAction, setVar, showModal, goToUrl } from "./actionCreators";
import { flowRelatedRequestStart, flowRelatedRequestCompleted } from "./loadingActions";
import { requestFieldFilters } from "./fieldTreeActions";
import { ToggleIsFlowTreeLoading, SetIsFlowLoadingExcludes } from "./treeBehaviorActions";
import { notifyGreen, notifyBlack, notifyBlue } from "./notifyActions";
import { SetPreviouslyClosedFlow, SetPreviouslySelectedFolder } from "./flowDesignerActions";
import { requestDestinations, requestDestinationsByAccess } from "./exportLayoutActions2";
import { GetTapadDeliveryTemplates } from "./companiesActions";
import { newFlowExpression } from "./flowExpressionActions";
import { newFlowDescription } from "./flowDescriptionActions";
import { gzip } from "node-gzip";

import { request } from "../helpers/httpInterceptor";
import { FlowBaseTypes, validFilters } from "../helpers/constants";
import { formatName } from "../helpers";
import { DeliveryType } from "../enums/company";

import { searchTree, findFolderById } from "../reducers/_folderGeneral";
import { getClientVariablesWithValuesForSelectedFlow } from "../reducers/clientVariables";
import {
    getFlowItemsArray,
    getFlowItemsForSelectedFlow,
    editUrlForFlowItem,
    editUrlForFlow,
    calculateNewItemPosition,
    isAnyParentIncomplete,
    variablesUrlForFlow,
    reportsUrlForFlow,
    makeGetSelectedFlowPermissions,
    makeGetPermissionsItemFromProps,
    getItemErrorsForSelectedFlow,
    getFlowItemTypeLookupForSelectedFlow,
} from "../reducers/flowItems";
import {
    getFlowRelationsForSelectedFlow,
    getFlowRelationsArray,
    getFlowItemChildrenIndex,
    isLoopDetected,
    validateFlowRelation,
    flowRelationExists,
    getExistingRelations,
    getFlowRelationsForAllFlows,
    get3rdPartyLinked,
    ancestorhasOACData,
} from "../reducers/flowRelations";
import { getFlowMultiExportsByFlowItemId, getFlowMultiExportForSelectedFlow } from "../reducers/flowMultiExports";
import { getFlowReportsForSelectedFlow, getFlowReportsByFlowItemId } from "../reducers/flowReports";
import { getFlowModelsForSelectedFlow, getFlowModelsByFlowItemId, getFlowModelsArray } from "../reducers/flowModels";
import { getFlowExportsByFlowItemId, getFlowExportsForSelectedFlow } from "../reducers/flowExports";
import { getFlowOutputsByFlowItemId, getFlowOutputsForSelectedFlow } from "../reducers/flowOutputs";
import { getFlowExportReportsForSelectedFlow, getFlowExportReportsArray } from "../reducers/flowExportReports";
import { getFlowCannedReportsForSelectedFlow, getFlowCannedReportsByFlowItemId } from "../reducers/flowCannedReports";
import { getFlowDescriptionsForSelectedFlow, getFlowDescriptionsByFlowItemId } from "../reducers/flowDescriptions";
import { getFlowFromCloudsByFlowItemId, getFlowFromCloudsForSelectedFlow } from "../reducers/flowFromClouds";
import { getFlowToCloudsByFlowItemId, getFlowToCloudsForSelectedFlow } from "../reducers/flowToClouds";
import { getFlowDataLoadsByFlowItemId, getFlowDataLoadsForSelectedFlow } from "../reducers/flowDataLoads";
import { getFlowDataLoadColumnsForSelectedFlow } from "../reducers/flowDataLoadColumns";
import {
    getFlowSVDedupesForSelectedFlow,
    getFlowSVDedupesByFlowItemId,
    getFlowSVDedupesArray,
} from "../reducers/flowSVDedupes";
import { getFlowFiltersByFlowItemId, getFlowFiltersForSelectedFlow } from "../reducers/flowFilters";
import { getFlowScriptsByFlowItemId, getFlowScriptsForSelectedFlow } from "../reducers/flowScripts";
import { getFlowScriptsDBUIByFlowItemId, getFlowScriptsDBUIForSelectedFlow } from "../reducers/flowScriptsDBUI";
import { getFlowEmptiesByFlowItemId, getFlowEmptiesForSelectedFlow } from "../reducers/flowEmpties";
import { getFlowSingleViewsForSelectedFlow, getFlowSingleViewsByFlowItemId } from "../reducers/flowSingleViews";
import { getFlowSplitsForSelectedFlow, getFlowSplitsByFlowItemId } from "../reducers/flowSplits";
import { getFlowCasesForSelectedFlow, getFlowCasesByFlowItemId } from "../reducers/flowCases";
import { getFlowMergesArray, getFlowMergesForSelectedFlow, getFlowMergesByFlowItemId } from "../reducers/flowMerges";
import {
    getFlowOfferMergesArray,
    getFlowOfferMergesForSelectedFlow,
    getFlowOfferMergesByFlowItemId,
} from "../reducers/flowOfferMerges";
import { getFlowClientVariablesForSelectedFlow } from "../reducers/flowClientVariables";
import {
    getFlowItemClientVariablesForSelectedFlow,
    getFlowItemClientVariablesArray,
} from "../reducers/flowItemClientVariables";
import { getFlowEndpointsForSelectedFlow } from "../reducers/flowItemEndpoints";
import { getFlowItemOfferCodesForSelectedFlow } from "../reducers/flowItemOfferCodes";
import {
    getFlowExportDistributionPlatformsForSelectedFlow,
    getFlowExportDistributionPlatformsArray,
} from "../reducers/flowExportDistributionPlatforms";
import {
    getFlowRelationParentLabelsForSelectedFlow,
    getFlowRelationsParentLabelsArray,
} from "../reducers/flowRelationParentLabels";

import { getFlowOffloadsByFlowItemId, getFlowOffloadsForSelectedFlow } from "../reducers/flowOffloads";
import {
    getFlowExternalServicesByFlowItemId,
    getFlowExternalServicesForSelectedFlow,
} from "../reducers/flowExternalServices";
import {
    getFlowOffloadColumnsByFlowItemId,
    getFlowOffloadColumnsForSelectedFlow,
} from "../reducers/flowOffloadColumns";
import { getFlowGuideSettingsForSelectedFlow } from "../reducers/flowGuideSettings";
import {
    getFlowExportTemplateFieldsForSelectedFlow,
    getFlowExportTemplateFieldsArray,
    getFlowExportTemplateFieldsByFlowItemId,
} from "../reducers/flowExportTemplateFields";
import {
    getFlowExportPinterestTemplateFieldsForSelectedFlow,
    getFlowExportPinterestTemplateFieldsArray,
    getFlowExportPinterestTemplateFieldsByFlowItemId,
} from "../reducers/flowExportPinterestTemplateFields";
import {
    getFlowExportTikTokTemplateFieldsForSelectedFlow,
    getFlowExportTikTokTemplateFieldsArray,
    getFlowExportTikTokTemplateFieldsByFlowItemId,
} from "../reducers/flowExportTikTokTemplateFields";
import {
    getFlowExportTradeDeskTemplateFieldsForSelectedFlow,
    getFlowExportTradeDeskTemplateFieldsArray,
    getFlowExportTradeDeskTemplateFieldsByFlowItemId,
} from "../reducers/flowExportTradeDeskTemplateFields";
import {
    getFlowExportFreewheelDriverFileFieldsForSelectedFlow,
    getFlowExportFreewheelDriverFileFieldsArray,
    getFlowExportFreewheelDriverFileFieldsByFlowItemId,
    FREEWHEEL_DRIVER_FILE_FIELDS,
} from "../reducers/freewheel";

import {
    getFlowExportTaxonomyFileFieldsForSelectedFlow,
    getFlowExportTaxonomyFileFieldsArray,
} from "../reducers/taxonomyLayout";
import FlowExportFreewheelDriverFileFields from "../types/stores/freewheel";
import { getFlowExpressionsByFlowItemId, getFlowExpressionsForSelectedFlow } from "../reducers/flowExpressions";

import wrap from "lodash/wrap";
import memoize from "lodash/memoize";
import debounce from "lodash/debounce";

import type { Dispatch, GetState, MergeBehavior, ThunkAction, Destination } from "../types/types";
import { VariableValueType, IFlowItemLayoutError } from "../types/stores/vars";
import { SET_FLOWITEM_LAYOUT_ERRORS } from "../reducers/vars";
import type { ActionTypeUFL } from "../types/actionTypes";
import type {
    Flow,
    FlowItem,
    FlowRelation,
    FlowFilter,
    FlowSplit,
    FlowCase,
    FlowEmpty,
    FlowCaseAndEmpty,
    FlowMerge,
    FlowOfferMerge,
    FlowReport,
    FlowModel,
    FlowExport,
    FlowMultiExport,
    FlowOutput,
    FlowExportReport,
    FlowCannedReport,
    FlowFromCloud,
    FlowToCloud,
    FlowSVDedupe,
    FlowSVDedupeD,
    FlowSingleView,
    FlowClientVariable,
    FlowClientVariableD,
    FlowItemClientVariable,
    FlowItemClientVariableD,
    FlowScript,
    FlowScriptDBUI,
    FlowScriptResult,
    FlowDataLoad,
    NormalizedFlowData,
    NewFlowLocation,
    FlowDataLoadColumn,
    EntityPluralName,
    FlowThings,
    FlowUserConfig,
    FlowPermissions,
    FlowAndItemPermissions,
    FlowItemEndpoint,
    FlowItemOfferCode,
    FlowExportDistributionPlatform,
    FlowExportTemplateFields,
    FlowExportPinterestTemplateFields,
    FlowExportTradeDeskTemplateFields,
    FlowExportTaxonomyFileFields,
    FlowRelationParentLabel,
    RequestFlowSave,
    FlowOffload,
    FlowOffloadColumn,
    FlowGuideSetting,
    FlowSegmentSplit,
    FlowSegmentSplitOffer,
    FlowExternalService,
    FlowExternalServiceParameter,
    FlowExternalServiceInput,
    FlowExternalServiceHardcode,
    FlowExportTikTokTemplateFields,
    FlowExportXandrDriverFields,
} from "../types/flowTypes";
import { getFlowSegmentSplitsByFlowItemId, getFlowSegmentSplitsForSelectedFlow } from "../reducers/flowSegmentSplits";
import {
    getFlowSegmentSplitOffersByFlowItemId,
    getFlowSegmentSplitOffersForSelectedFlow,
} from "../reducers/flowSegmentSplitOffers";
import { getFlowExternalServiceParametersForSelectedFlow } from "../reducers/flowExternalServiceParameters";
import { getFlowExternalServiceInputsForSelectedFlow } from "../reducers/flowExternalServiceInputs";
import { getFlowExternalServiceHardcodesForSelectedFlow } from "../reducers/flowExternalServiceHardcodes";
import { getFlowExpressionsConstraintsForSelectedFlow } from "../reducers/flowExpressionConstraints";
import { requestExternalServiceLayoutData } from "./flowControlActions";
import { clearSelectedCannedReport } from "./cannedReportActions";
import { DeploySettings } from "../types/stores/companyTable";
import {
    deleteFlowExportXandrDriverFields,
    newFlowExportXandrDriverFields,
    xandrDriverFieldsPrefixAndEntity,
} from "./flowExportXandrDriverFieldActions";
import {
    getFlowExportXandrDriverFieldsForSelectedFlow,
    getFlowExportXandrDriverFieldsArray,
    getFlowExportXandrDriverFieldsByFlowItemId,
} from "../reducers/flowExportXandrDriverFields";
import { getAncestorFilterFieldIdsForFlowItemId } from "../helpers/flowItems";
import { getFieldRatesByParentFlowItemId } from "../reducers/fieldsByCompany";

import React from "react";
if (!top.RDX) {
    top.RDX = {};
}

export const updateFlowList = (
    normalizedData: NormalizedFlowData,
    mergeBehavior: MergeBehavior = "replace",
    flowClientVariableIdsToDelete: ?Array<number> = null
): ActionTypeUFL => ({
    type: "UPDATE_FLOW_LIST",
    normalizedData,
    mergeBehavior,
    flowClientVariableIdsToDelete,
});
top.RDX.updateFlowList = flows => top.store.dispatch(updateFlowList(flows));

export const clearSelectedFlow = () => ({
    type: "CLEAR_SELECTED_FLOW",
});
top.RDX.clearSelectedFlow = () => top.store.dispatch(clearSelectedFlow());

const ucFirst = string => string.charAt(0).toUpperCase() + string.slice(1);

let updatedNodeIDs = [
    {
        oldId: 0,
        newId: 0,
    },
];

export const goEditFlowItem =
    (flowItemId: number, isResetFlowControlTab: boolean = true) =>
    (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const flowItemsById = state.flowItems.byId;
        let flowItem: FlowItem = flowItemsById[flowItemId];
        let flowItemEditId: number = flowItemId;

        if (flowItem == null) {
            return;
        }

        if (flowItem.FlowItemType == "empty") {
            const flowRelations = Object.values(state.flowRelations.byId);
            const parentRelation = flowRelations.find(
                x => x.ChildFlowItemId == flowItem.FlowItemId && x.ParentFlowItemId != 0
            );
            if (parentRelation && flowItemsById[parentRelation.ParentFlowItemId]) {
                flowItem = flowItemsById[parentRelation.ParentFlowItemId];
                flowItemEditId = flowItem.FlowItemId;
            }
        }

        if (isResetFlowControlTab) {
            dispatch(setNewTabValue(0));
        }

        dispatch(setSelectedFlowItemId(flowItemEditId)); // This makes FlowItemEdit load faster
        dispatch(goToUrl(editUrlForFlowItem(flowItem.FlowId, flowItemEditId)));
    };

export const goEditFlow = (flowId: number) => (dispatch: Dispatch) => {
    dispatch(goToUrl(editUrlForFlow(flowId)));
    dispatch(requestFlow(flowId));
};

export const goVariablesFlow = (flowId: number) => (dispatch: Dispatch) => {
    dispatch(goToUrl(variablesUrlForFlow(flowId)));
};

export const goReportsFlow = (flowId: number) => (dispatch: Dispatch) => {
    dispatch(goToUrl(reportsUrlForFlow(flowId)));
};

export const newFlow = (flowName?: string, baseType?: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const userId = state.session.userId;
    const companyId = state.session.companyId;

    //Getting top level folder Id #1483
    const topLevelFolderId = findTopLevelFlowFolderForUser(state, userId);
    const newFlow: Flow = {
        FlowId: 0,
        FlowName: flowName || "New Flow", // 150
        FlowDescription: baseType ? "New Campaign" : "New Description", // 200
        FlowIsActive: true,
        FlowCompanyId: companyId,
        FlowFolderId: topLevelFolderId,
        FlowDateCreated: "", // timestamp
        FlowCreatedBy: userId,
        FlowDateUpdated: "", // timestamp
        FlowUpdatedBy: userId,
        FlowUniverse: 1,
        IsLocked: false,
        IsLockedPermanently: false,
        FlowLastCalculated: null,
        FlowBaseType: baseType ? baseType : FlowBaseTypes.Default,
        hasUnsavedChanges: false, // Local only
        isSaving: 0, // Local only
        isRenaming: false, // Local only
        shouldValidate: false,
        flowItemsRunning: 0, // Calculated, based on flow items IsRunning state
        isLoadingFlowData: false, // Local only
        isSplitEnabled: true, // Should we control this through a flow guide settings? in the db?
        hasUnsavedLayoutChanges: false,
    };
    const callback = id => dispatch(setSelectedFlowNoUnsavedCheck(id));
    return dispatch(addThing("flows", newFlow, callback, false));
};

// controls the local shouldValidate flag in the store _only_ validate when needed
export const updateFlowShouldValidate =
    (flowId: number, shouldValidate: boolean) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const flow: ?Flow = state.flows.byId[state.selected.flow];

        if (flow == null) return;
        dispatch(updateAttribute("flows", flowId, "shouldValidate", shouldValidate));
    };

export const newFlowEmpty =
    (flowItemId: number, idCallback?: number => any, criteria: ?string) => (dispatch: Dispatch) => {
        const thing: FlowEmpty = {
            FlowEmptyId: 0,
            FlowItemId: flowItemId,
            FlowEmptyCriteria: criteria,
        };
        dispatch(addThing("flowEmpties", thing, idCallback));
        // Automatically change the name on the associated flowItem to criteria.
        dispatch(updateAttribute("flowItems", flowItemId, "FlowItemName", criteria));
    };

// When making a new node, a user can pass in a location through either a
// direct X+Y coordinate, or a parentId for us to put the node under
const newFlowLocationToXY = (state: any, newFlowLocation: ?NewFlowLocation) => {
    const defaultLocation = { x: 10, y: 10 };
    // They passed us nothing, default to above coordinates
    if (newFlowLocation == null) {
        return defaultLocation;
    }
    // They passed us a parent ID, compute where the node should be
    if (newFlowLocation != null && newFlowLocation.getLocationFromThisParent != null) {
        return calculateNewItemPosition(state, newFlowLocation.getLocationFromThisParent);
    }
    // They passed us X+Y coords, just pass through
    if (newFlowLocation != null && newFlowLocation.x != null && newFlowLocation.y != null) {
        if (newFlowLocation.x) {
            newFlowLocation.x = Math.round(newFlowLocation.x);
        }
        if (newFlowLocation.y) {
            newFlowLocation.y = Math.round(newFlowLocation.y);
        }

        return {
            x: newFlowLocation.x,
            y: newFlowLocation.y,
        };
    }
    return defaultLocation;
};

export const newFlowItem =
    (idCallback?: number => any, flowItemName?: string, flowItemType?: string, newFlowLocation?: NewFlowLocation) =>
    (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const userId = state.session.userId;
        const flowId: number = state.selected.flow;

        // Look for location specified by either parent ID or exact x+y, otherwise default
        const newPosition = newFlowLocationToXY(state, newFlowLocation);
        const thing: FlowItem = {
            FlowItemId: 0,
            FlowId: flowId,
            FlowItemType: flowItemType || "", // 80
            FlowItemName: flowItemName || "", // 150
            IsActive: true,
            IsValid: true,
            HasResultTable: false,
            HasQATable: false,
            IsRunning: 0,
            IsError: false,
            FlowItemCreatedDate: "", // timestamp
            FlowItemCreatedBy: userId,
            FlowItemUpdatedDate: "", // timestamp
            FlowItemUpdatedBy: userId,
            FlowItemStart: "",
            FlowItemEnd: "",
            FlowItemQty: 0,
            SysDescription: "",
            x: newPosition.x,
            y: newPosition.y,
            requestId: null,
            SubmittedProcessId: null,
            SubmittedProcessName: "",
            IsCancelled: false,
            IsComputeQA: false,
            width: null,
            height: null,
            SortByFieldsJSON: null,
            IsRandom: true,
            IsLoading: false,
            isRenaming: false, // Local only
            OverlapQuantity: 0,
            IsIncomplete: false,
        };
        dispatch(addThing("flowItems", thing, idCallback));
        dispatch(flowEnsureItemDetailsExist());
    };

export const newFlowRelation = (parentId: number, childId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const relations = getFlowRelationsArray(state);
    const alreadyExists =
        relations.filter(x => x.ParentFlowItemId == parentId && x.ChildFlowItemId == childId).length > 0;
    if (alreadyExists) {
        return;
    }

    const thing: FlowRelation = {
        FlowRelationId: 0,
        ParentFlowItemId: parentId,
        ChildFlowItemId: childId,
    };
    dispatch(addThing("flowRelations", thing));
    // Invalidate Child Items
    dispatch(invalidateItemAndChildren(childId));
};

// I think we need to brainstorm a differenct approach to turning off `SimpleFlow`
// as we expand more and more 'types' of simple flow this is going to get messy.
//
// Especially when we expand to allow MANY filters
//
export const stillSimpleFlow = () => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const flow: ?Flow = state.flows.byId[state.selected.flow];
    if (!flow || (flow && !flow.FlowBaseType) || (flow && flow.FlowBaseType == FlowBaseTypes.Default)) {
        return false;
    }

    const flowItems = getFlowItemsForSelectedFlow(state);
    const flowMultiExport = flowItems.filter(x => x.FlowItemType === "multiexport"); //Simple-Flow Multi Export to keep it simple flow
    if (flow.FlowBaseType == FlowBaseTypes.Campaign) {
        const flowSplits = flowItems.filter(x => x.FlowItemType === "split");
        const flowExport = flowItems.filter(x => x.FlowItemType === "export");
        const flowCases = flowItems.filter(x => x.FlowItemType === "case");
        if (flowMultiExport.length == 1 && state.session.enabledFeatures.includes("simple-flow")) {
            dispatch(updateAttribute("flows", state.selected.flow, "FlowBaseType", 1));
            return true;
        }
        if (flowCases.length > 1 || flowExport.length > 1) {
            dispatch(updateAttribute("flows", state.selected.flow, "FlowBaseType", 0));
            return false;
        }
        if (flowSplits.length > 1 || flowExport.length > 1) {
            dispatch(updateAttribute("flows", state.selected.flow, "FlowBaseType", 0));
            return false;
        }
        if (flowItems.filter(x => !validFilters.includes(x.FlowItemType)).length > 0) {
            dispatch(updateAttribute("flows", state.selected.flow, "FlowBaseType", 0));
            return false;
        }
    } else if (flow.FlowBaseType == FlowBaseTypes.Model) {
        const flowModels = flowItems.filter(x => x.FlowItemType === "model");

        if (flowModels.length > 1) {
            dispatch(updateAttribute("flows", state.selected.flow, "FlowBaseType", 0));
            return false;
        }
    } else if (flow.FlowBaseType == FlowBaseTypes.Undecided) {
        if (flowMultiExport.length == 1) {
            dispatch(updateAttribute("flows", state.selected.flow, "FlowBaseType", 1));
            return true;
        }
        // if there's anything other than a filter, flip it to regular flow
        if (
            flowItems.filter(x => !["filter"].includes(x.FlowItemType)).length > 0 &&
            flowItems.filter(x => !["case", "empty", "export"].includes(x.FlowItemType)).length > 0
        ) {
            dispatch(updateAttribute("flows", state.selected.flow, "FlowBaseType", 0));
            return false;
        }
    } else {
        return false;
    }
    return true;
};

export const updateTikTokTemplateFieldName = (flowItem, itemName, flowTikTokTemplateFields) => {
    const updateTikTokTemplates = Object.values(flowTikTokTemplateFields);
    const tikTokTemplatesToUpdate = updateTikTokTemplates
        .filter(x => x.ParentFlowItemId == flowItem.FlowItemId)
        .map(x => x.FlowExportTemplateId);
    return tikTokTemplatesToUpdate;
};

// Special Case:  Update a FlowFilter if you don't have its id and only have the flowItemId
// used by FlowBrowserTest.js
export const updateFilterCriteriaByItemId =
    (flowItemId: number, jsonCriteria: string) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const flowFilters = getFlowFiltersForSelectedFlow(state);
        const theseFilters = flowFilters.filter(x => x.FlowItemId == flowItemId);
        if (theseFilters.length == 0) {
            return;
        }
        const thisFilter = theseFilters[0];
        dispatch(updateAttribute("flowFilters", thisFilter.FlowFilterId, "FlowFilterCriteria", jsonCriteria));
    };

export const newFlowFilter = (flowItemId: number) => (dispatch: Dispatch) => {
    const thing: FlowFilter = {
        FlowFilterId: 0,
        FlowItemId: flowItemId,
        FlowFilterCriteria: '{"condition": "AND","rules": [],"description": "","valid": true}', // Max
        FlowFilterCriteriaText: "",
    };
    dispatch(addThing("flowFilters", thing));
};

export const newFlowMultiExport = (flowItemId: number) => (dispatch: Dispatch) => {
    const thing: FlowMultiExport = {
        FlowMultiExportId: 0,
        FlowItemId: flowItemId,
    };
    dispatch(addThing("flowMultiExports", thing));
};

export const newFlowScript = (flowItemId: number) => (dispatch: Dispatch) => {
    const thing: FlowScript = {
        FlowScriptId: 0,
        FlowItemId: flowItemId,
        Script: "",
        SelectedScript: "",
    };
    dispatch(addThing("flowScripts", thing));
};

export const newFlowScriptDBUI = (flowItemId: number) => (dispatch: Dispatch) => {
    const thing: FlowScriptDBUI = {
        FlowScriptDBUIId: 0,
        FlowItemId: flowItemId,
        Script: "",
        SelectedScript: "",
    };
    dispatch(addThing("flowScriptsDBUI", thing));
};

export const newFlowScriptResult = (flowItemId: number) => (dispatch: Dispatch) => {
    const thing: FlowScriptResult = {
        FlowScriptResultId: 0,
        FlowItemId: flowItemId,
        ScriptId: 0,
        Sql: "",
        IsValid: true,
        InvalidReason: null,
        RowsAffected: 0,
        Preview: null,
    };
    dispatch(addThing("flowScriptResults", thing));
};

export const newFlowSingleView =
    (flowItemId: number, partnerId: number, isSelected: boolean) => (dispatch: Dispatch) => {
        const thing: FlowSingleView = {
            FlowSingleViewId: 0,
            FlowItemId: flowItemId,
            PartnerId: partnerId,
            FlowSingleViewQuantity: 0,
            Counted: false,
            IsSelected: isSelected,
            HouseholdQuantity: 0,
            IndividualQuantity: 0,
            DedupedHouseholdQuantity: 0,
            DedupedIndividualQuantity: 0,
            IsApprovalRequired: false,
            IsCountApproved: false,
            MeetsMinimumThreshold: false,
            StatusText: "",
            SingleViewKeyword: "",
        };
        dispatch(addThing("flowSingleViews", thing));
    };

export const newFlowExport = (flowItemId: number) => (dispatch: Dispatch) => {
    const thing: FlowExport = {
        FlowExportId: 0,
        FlowItemId: flowItemId,
        ExportId: null,
        HasPII: false,
        ExportOnCalculate: false,
        DestinationId: 0,
        ShouldDeploy: true,
        CustomDedupe: false,
        IsMaxQtyOn: false,
        IsSplitOn: false,
        SelectedOfferId: null,
        IsApprovalRequired: false,
        IsCountApproved: false,
        MeetsMinimumThreshold: false,
        StatusText: "",
        IsDedupeSaved: false,
        IsCustomDedupeSaved: false,
        DeliveryTypeId: null,
        ScheduleId: null,
        SnowflakeSettingsId: 0,
        SnowflakeTable: "",
        AppendToExistingTable: false,
    };
    dispatch(addThing("flowExports", thing));
};

export const newBaseFlowExport =
    (multiId: number, destinationId: number) => (dispatch: Dispatch, getState: GetState) => {
        let newItemId = 0;
        multiId = Number(multiId); // Convert multiId to a number
        dispatch(
            newFlowItem(id => (newItemId = id), "New Item", "export", {
                getLocationFromThisParent: multiId,
            })
        );

        dispatch(newFlowRelation(multiId, newItemId));
        // flowEnsureItemDetailsExist adds flowexport automatically so we have to update it afterwards
        const state = getState();
        const flowExports = getFlowExportsForSelectedFlow(state);
        const flowExport = flowExports.find(x => x.FlowItemId == newItemId);
        dispatch(updateAttribute("flowExports", flowExport.FlowExportId, "DestinationId", destinationId));
    };

export const newFlowOutput = (flowItemId: number) => (dispatch: Dispatch) => {
    const thing: FlowOutput = {
        FlowOutputId: 0,
        FlowItemId: flowItemId,
        LayoutId: null,
        HasPII: false,
        ExportOnCalculate: false,
        DestinationId: 0,
        ShouldDeploy: true,
        IsApprovalRequired: false, // Local only
        IsCountApproved: false, // Local only
        MeetsMinimumThreshold: false, // Local only
        StatusText: "", // Local only
    };
    dispatch(addThing("flowOutputs", thing));
};

export const newFlowFromCloud = (flowItemId: number) => (dispatch: Dispatch) => {
    const thing: FlowFromCloud = {
        FlowFromCloudId: 0,
        FlowItemId: flowItemId,
        FromCompanyId: 0,
        FromUserId: 0,
        FromFlowId: 0,
        FromFlowItemId: 0,
    };
    dispatch(addThing("flowFromClouds", thing));
};

export const newFlowToCloud = (flowItemId: number) => (dispatch: Dispatch) => {
    const thing: FlowToCloud = {
        FlowToCloudId: 0,
        FlowItemId: flowItemId,
        ExpireDays: 30,
        TableResultCreationDate: "",
    };
    dispatch(addThing("flowToClouds", thing));
};

export const newFlowClientVariable =
    (variableId: number, flowId: number, variableValue: VariableValueType, isVisible: boolean) =>
    (dispatch: Dispatch) => {
        const thing: FlowClientVariableD = {
            Id: 0,
            VariableId: variableId,
            FlowId: flowId,
            VariableValue: variableValue,
            IsVisible: isVisible,
        };
        dispatch(addThing("flowClientVariables", thing));
    };

export const newFlowItemOfferCode =
    (flowItemId: number, variableId: number, isVisible: boolean, sortOrder: number | null) => (dispatch: Dispatch) => {
        const thing: FlowItemOfferCode = {
            FlowItemOfferCodeId: 0,
            FlowItemId: flowItemId,
            VariableId: variableId,
            IsVisible: isVisible,
            SortOrder: sortOrder,
        };
        dispatch(addThing("flowItemOfferCodes", thing));
    };

export const newFlowItemClientVariable =
    (
        variableId: number,
        flowId: number,
        flowItemId: number,
        childFlowItemId: number,
        variableValue: VariableValueType
    ) =>
    (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const flowItemCVs = getFlowItemClientVariablesForSelectedFlow(state);
        const flowItemClientVariables = flowItemCVs        
            .filter(x => x.FlowItemId == flowItemId && x.ChildFlowItemId == childFlowItemId && x.VariableId == variableId);
        if (flowItemClientVariables.length == 0) {
            const thing: FlowItemClientVariableD = {
                FlowItemClientVariableId: 0,
                VariableId: variableId,
                FlowId: flowId,
                FlowItemId: flowItemId,
                ChildFlowItemId: childFlowItemId,
                VariableValue: variableValue,
            };
            dispatch(addThing("flowItemClientVariables", thing));
        }
    };

export const newFlowSVDedupe =
    (flowItemId: number, dedupeKey: string = "", sortByFields: string = "") =>
    (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const defaultDedupeKey = dedupeKey || state.session.defaultDedupeKey;
        const dedupeSortByFields = sortByFields || state.session.dedupeSortByFields;
        const thing: FlowSVDedupeD = {
            FlowSVDedupeId: 0,
            FlowItemId: flowItemId,
            SVField: defaultDedupeKey || null,
            SortByFields: dedupeSortByFields && dedupeSortByFields.length > 0 ? JSON.parse(dedupeSortByFields) : [],
        };
        dispatch(addThing("flowSVDedupes", thing));
    };

export const newFlowSVDedupeByDestination =
    (flowItemId: number, defaultDedupeKey: string, dedupeSortByFields: string) => (dispatch: Dispatch) => {
        const thing: FlowSVDedupeD = {
            FlowSVDedupeId: 0,
            FlowItemId: flowItemId,
            SVField: defaultDedupeKey || null,
            SortByFields: dedupeSortByFields && dedupeSortByFields.length > 0 ? JSON.parse(dedupeSortByFields) : [],
        };
        dispatch(addThing("flowSVDedupes", thing));
    };

export const newFlowMerge = (flowItemId: number, parentId: number) => (dispatch: Dispatch) => {
    const thing: FlowMerge = {
        FlowMergeId: 0,
        FlowItemId: flowItemId,
        ParentFlowItemId: parentId,
        FlowMergeIsSuppresion: false,
        DupesQty: 0,
        UniquesQty: 0,
        FlowMergeIsInnerJoin: false,
        WillTrackDuplicates: false,
    };
    dispatch(addThing("flowMerges", thing));
};

export const newFlowOfferMerge = (flowItemId: number, parentId: number) => (dispatch: Dispatch) => {
    const thing: FlowOfferMerge = {
        FlowOfferMergeId: 0,
        FlowItemId: flowItemId,
        ParentFlowItemId: parentId,
        FlowOfferMergeIsSuppresion: false,
        DupesQty: 0,
        UniquesQty: 0,
        FlowOfferPriority: 0,
        MaxQty: 0,
        OutputQty: 0,
        FinalQty: 0,
    };
    dispatch(addThing("flowOfferMerges", thing));
};

export const updateFlowOfferMerge = (flowOfferMergeId: number, flowOfferPriority: number) => (dispatch: Dispatch) => {
    dispatch(updateAttribute("flowOfferMerges", flowOfferMergeId, "FlowOfferPriority", flowOfferPriority));
};

export const newFlowReport = (flowItemId: number) => (dispatch: Dispatch) => {
    const thing: FlowReport = {
        FlowReportId: 0,
        FlowItemId: flowItemId,
        DimensionFieldKeys: "",
        MeasureFieldKeys: "",
        UniverseTableKey: 0,
        TransformMetadata: true,
        PivotConfig: null,
        FlowReportCriteria: null,
    };
    dispatch(addThing("flowReports", thing));
};

export const newFlowModel = (flowItemId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const thing: FlowModel = {
        FlowModelId: 0,
        FlowItemId: flowItemId,
        DesiredHHQuantity: null,
        ModelFilename: "Default 500k sample",
        TargetFlowItemId: null,
        BaseFlowItemId: null,
        BaseFilename: "",
        DestinationCompanyId: state.session.companyId,
        ModelStatus: "",
        BaseLuidQty: null,
        TargetLuidQty: null,
        Bundle: 1,
    };
    dispatch(addThing("flowModels", thing));
};

export const newFlowExportReport = (parentFlowItemId: number, LayoutId: number) => (dispatch: Dispatch) => {
    let newItemId = 0;
    dispatch(
        newFlowItem(id => (newItemId = id), "New Item", "exportreport", {
            getLocationFromThisParent: parentFlowItemId,
        })
    );
    const thing: FlowExportReport = {
        FlowExportReportId: 0,
        FlowItemId: newItemId,
        ParentFlowItemId: parentFlowItemId,
        DimensionFieldKeys: "",
        MeasureFieldKeys: "",
        LayoutId,
        TransformMetadata: true,
        PivotConfig: null,
    };
    dispatch(addThing("flowExportReports", thing));

    dispatch(newFlowRelation(parentFlowItemId, newItemId));
};

export const newFlowCannedReport = (flowItemId: number) => (dispatch: Dispatch) => {
    const thing: FlowCannedReport = {
        FlowCannedReportId: 0,
        FlowItemId: flowItemId,
        DimensionFieldKeys: "",
        MeasureFieldKeys: "",
        StoredProcedureID: 0,
        DatabaseIn: "",
        SchemaIn: "",
        TableIn: "",
        DatabaseOut: "",
        SchemaOut: "",
        TableOut: "",
        SortByColumns: null,
    };

    dispatch(addThing("flowCannedReports", thing));
};

export const duplicatedFlowExportReport =
    (parentFlowItemId: number, LayoutId: number, childrenId: number) => (dispatch: Dispatch, getState: GetState) => {
        let newItemId = 0;
        let value = [];
        let dimensionFieldKeys = "";
        let measureFieldKeys = "";
        const state = getState();
        const flowExportReportDuplicated = state.flowExportReports.byId;
        for (const key in flowExportReportDuplicated) {
            if (flowExportReportDuplicated[key].FlowItemId == childrenId) {
                value = flowExportReportDuplicated[key];
                dimensionFieldKeys = value.DimensionFieldKeys;
                measureFieldKeys = value.MeasureFieldKeys;
            }
        }
        dispatch(
            newFlowItem(id => (newItemId = id), state.flowItems.byId[childrenId].FlowItemName, "exportreport", {
                getLocationFromThisParent: parentFlowItemId,
            })
        );
        const thing: FlowExportReport = {
            FlowExportReportId: 0,
            FlowItemId: newItemId,
            ParentFlowItemId: parentFlowItemId,
            DimensionFieldKeys: dimensionFieldKeys,
            MeasureFieldKeys: measureFieldKeys,
            LayoutId,
            TransformMetadata: true,
            PivotConfig: null,
        };
        dispatch(addThing("flowExportReports", thing));

        dispatch(newFlowRelation(parentFlowItemId, newItemId));
    };

export const newFlowSplit =
    (
        flowItemId: number,
        absoluteOrRelative: "absolute" | "relative",
        isDefault: boolean,
        flowItemHasBalance: boolean
    ) =>
    (dispatch: Dispatch, getState: GetState) => {
        const flowSplitsByItemId = getFlowSplitsByFlowItemId(getState());
        let numSplits = 0;

        if (flowSplitsByItemId[flowItemId] != null) {
            if (flowSplitsByItemId[flowItemId].filter(x => !x.IsBalance) != null) {
                numSplits = flowSplitsByItemId[flowItemId].filter(x => !x.IsBalance).length;
            }
            numSplits++;
        }
        if (numSplits == 0 && !isDefault && flowItemHasBalance) {
            numSplits = 1;
            dispatch(newFlowSplit(flowItemId, absoluteOrRelative, true, true)); // creates default first
        }
        if (numSplits == 0) {
            numSplits = 1;
        }

        const newLetter = String.fromCharCode("A".charCodeAt(0) + ((numSplits % 26) - 1));
        // Add Split Row
        let newSplitId = 0;
        const thing: FlowSplit = {
            FlowSplitId: 0,
            FlowItemId: flowItemId,
            AbsoluteOrRelative: absoluteOrRelative,
            FlowSplitQty: 0,
            ChildFlowItemId: 0,
            FlowSplitSort: "", // 1
            FlowSplitQuantile: 0,
            AbsoluteNumRows: 0,
            IsBalance: isDefault,
            RelativePercentRows: 0,
            FlowItemHasBalance: flowItemHasBalance,
        };
        dispatch(addThing("flowSplits", thing, id => (newSplitId = id)));

        // Add FlowItem to hold the empty
        let newEmptyItemId = 0;
        dispatch(
            newFlowItem(id => (newEmptyItemId = id), "AutoGenEmpty", "empty", { getLocationFromThisParent: flowItemId })
        );

        const criteria = isDefault ? "Balance" : newLetter;
        // Add FlowEmpty Row
        dispatch(newFlowEmpty(newEmptyItemId, () => false, criteria));

        // Update FlowSplit to point to the new empty row
        dispatch(updateAttribute("flowSplits", newSplitId, "ChildFlowItemId", newEmptyItemId));
        // Also Add Relation
        dispatch(newFlowRelation(flowItemId, newEmptyItemId));
    };

export const cloneCase = (flowCase: FlowCaseAndEmpty) => (dispatch: Dispatch, getState: GetState) => {
    const flowCasesByItemId = getFlowCasesByFlowItemId(getState());
    let numCases = 0;
    let flowItemId = flowCase.FlowItemId;

    if (flowCasesByItemId[flowItemId] != null) {
        numCases = flowCasesByItemId[flowItemId][0].FlowItemHasBalance
            ? flowCasesByItemId[flowItemId].length
            : flowCasesByItemId[flowItemId].length + 1;
    }

    const newLetter = String.fromCharCode("A".charCodeAt(0) + ((numCases % 26) - 1));

    let newCaseId = 0;
    const thing: FlowCase = {
        FlowCaseId: 0,
        FlowItemId: flowItemId,
        FlowCaseCriteria: flowCase.FlowCaseCriteria,
        FlowCasePriority: numCases,
        ChildFlowItemId: 0,
        FlowCaseLabel: newLetter,
        IsBalance: false,
        FlowItemHasBalance: flowCase.FlowItemHasBalance,
    };
    dispatch(addThing("flowCases", thing, id => (newCaseId = id)));

    // Add FlowItem to hold the empty
    let newEmptyItemId = 0;
    dispatch(
        newFlowItem(id => (newEmptyItemId = id), "AutoGenEmpty", "empty", { getLocationFromThisParent: flowItemId })
    );

    // Add FlowEmpty Row
    const criteria = newLetter;
    dispatch(newFlowEmpty(newEmptyItemId, () => false, criteria));

    // Update FlowSplit to point to the new empty row
    dispatch(updateAttribute("flowCases", newCaseId, "ChildFlowItemId", newEmptyItemId));
    // Also Add Relation
    dispatch(newFlowRelation(flowItemId, newEmptyItemId));
};

export const newFlowDataLoad = (flowItemId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();

    const thing: FlowDataLoad = {
        FlowDataLoadId: 0,
        FlowItemId: flowItemId,
        FlowTableToLoad: null,
        ParentFolderId: null,
        FolderName: null,
        RetentionDays: state.session.DataRetentionDaysDefault,
        RefreshMetadata: true,
    };
    dispatch(addThing("flowDataLoads", thing));
};

export const newFlowDataLoadColumn =
    (flowItemId: number, columnName: string, isDefault: boolean = false) =>
    (dispatch: Dispatch) => {
        const thing: FlowDataLoadColumn = {
            FlowDataLoadColumnId: 0,
            FlowItemId: flowItemId,
            ColumnName: columnName,
            Invisible: false,
            JoinColumn: null,
            IsDefault: isDefault,
        };
        dispatch(addThing("flowDataLoadColumns", thing));
    };

export const newFlowOffload = (flowItemId: number) => (dispatch: Dispatch) => {
    const thing: FlowOffload = {
        FlowOffloadId: 0,
        FlowItemId: flowItemId,
        TableName: null,
        DestinationId: 0,
        FileName: null,
        FileExtension: 0,
        FileDelimiter: null,
        IsFixedFile: true,
        IncludeLayoutFile: false,
        AutoRefreshColums: false,
    };
    dispatch(addThing("flowOffloads", thing));
};

export const newFlowOffloadColumn =
    (
        flowItemId: number,
        columnName: string,
        order: number,
        columnLength: number,
        deployColumn: boolean = true,
        sortOrder: number = 0
    ) =>
    (dispatch: Dispatch) => {
        const thing: FlowOffloadColumn = {
            FlowOffloadColumnId: 0,
            FlowItemId: flowItemId,
            ColumnName: columnName,
            DeployColumn: deployColumn,
            ColumnLength: columnLength > 0 ? columnLength : 10,
            Order: order,
            SortOrdinance: sortOrder,
        };
        dispatch(addThing("flowOffloadColumns", thing));
    };

export const newFlowExternalService = (flowItemId: number) => (dispatch: Dispatch) => {
    const thing: FlowExternalService = {
        FlowExternalServiceId: 0,
        FlowItemId: flowItemId,
        ServiceModelId: 0,
        ServicesJSON: [],
        FileLocationId: 0,
        FileName: null,
        OutputLocationId: 0,
        InputHeaderRow: false,
        AdditionalPersons: "none",
        DeploySettingId: 0,
        SnowflakeSettingsId: 0,
        SnowflakeTable: "",
        AppendToExistingTable: false,
    };
    dispatch(addThing("flowExternalServices", thing));
};

export const newFlowExternalServiceParameter =
    (flowItemId: number, serviceId: number, parameterId: number, value: string) => (dispatch: Dispatch) => {
        const thing: FlowExternalServiceParameter = {
            FlowServiceParameterId: 0,
            FlowItemId: flowItemId,
            ServiceId: serviceId,
            ParameterId: parameterId,
            Value: value,
        };
        dispatch(addThing("flowExternalServiceParameters", thing));
    };

export const newFlowExternalServiceInput =
    (flowItemId: number, number: number, name: string, quantity: number, nasServerId: number = 0) =>
    (dispatch: Dispatch) => {
        const thing: FlowExternalServiceInput = {
            InputId: 0,
            FlowItemId: flowItemId,
            InputNumber: number,
            InputName: name,
            InputQuantity: quantity,
            NasServerId: nasServerId,
        };
        dispatch(addThing("flowExternalServiceInputs", thing));
    };

export const newFlowExternalServiceHardcode =
    (flowItemId: number, name: string, value: string) => (dispatch: Dispatch) => {
        const thing: FlowExternalServiceHardcode = {
            HardcodeId: 0,
            FlowItemId: flowItemId,
            HardcodeName: name,
            HardcodeValue: value,
        };
        dispatch(addThing("flowExternalServiceHardcodes", thing));
    };

export const newFlowGuideSetting =
    (flowId: number, isSplitOn: boolean = false) =>
    (dispatch: Dispatch) => {
        const thing: FlowGuideSetting = {
            FlowGuideId: 0,
            FlowId: flowId,
            IsSplitOn: isSplitOn,
        };
        dispatch(addThing("flowGuideSettings", thing));
    };

export const newFlowExportTemplateFields =
    (flowItemId: number, parentId: number) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const newMetaTemplate = state.session.enabledFeatures.includes("new-metadata-driver-field");
        const thing: FlowExportTemplateFields = {
            FlowExportTemplateId: 0,
            FlowItemId: flowItemId,
            ParentFlowItemId: parentId,
            Description: null,
            AdAccountIds: null,
            CustomerFileSource: newMetaTemplate ? "PARTNER_PROVIDED_ONLY" : null,
            EmailAddress: null,
        };
        dispatch(addThing("flowExportTemplateFields", thing));
    };

export const getDefaultDate = () => {
    const defaultDate = new Date();
    defaultDate.setMonth(defaultDate.getMonth() + 6);
    return defaultDate;
};

export const newFlowExportPinterestTemplateFields =
    (flowItemId: number, parentId: number) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const parent: ?FlowItem = state.flowItems.byId[parentId];

        const thing: FlowExportPinterestTemplateFields = {
            TargetingAudienceName: parent.FlowItemName,
            FlowExportTemplateId: 0,
            FlowItemId: flowItemId,
            ParentFlowItemId: parentId,
            PinterestAccountID: null,
            OldAudienceID: null,
            NotificationEmails: null,
        };
        dispatch(addThing("flowExportPinterestTemplateFields", thing));
    };

export const newFlowExportTikTokTemplateFields =
    (flowItemId: number, parentId: number) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const parent: ?FlowItem = state.flowItems.byId[parentId];

        const thing: FlowExportTikTokTemplateFields = {
            TargetingAudienceName: parent.FlowItemName,
            FlowExportTemplateId: 0,
            FlowItemId: flowItemId,
            ParentFlowItemId: parentId,
            AdvertiserID: null,
        };
        dispatch(addThing("flowExportTikTokTemplateFields", thing));
    };

export const newFlowExportFreewheelDriverFileFields =
    (flowItemId: number, parentId: number) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const parent: ?FlowItem = state.flowItems.byId[parentId];
        const ratesByFlowItemId = getFieldRatesByParentFlowItemId(state);
        const fieldRate = ratesByFlowItemId[parentId];
        const hasFieldRatePricing = state.session.enabledFeatures.includes("display-field-rates");
        const maxCPM = hasFieldRatePricing ? fieldRate : null;

        const thing: FlowExportFreewheelDriverFileFields = {
            FlowExportFreewheelDriverFileFieldId: 0,
            FlowItemId: flowItemId,
            ParentFlowItemId: parentId,
            IdTypes: null,
            SegmentName: formatName(parent.FlowItemName).substring(0, 25),
            NetworkId: "",
            CategoryName: "",
            SegmentDescription: "",
            SubGroupDescription: "",
            Custom: true,
            Price: maxCPM,
            CompanyName: "",
        };

        dispatch(addThing("flowExportFreewheelDriverFileFields", thing));
    };

export const updateFlowExportMagniteDriverFileCPMFields =
    (ratesByFlowItem: { [key: number]: number }) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();

        const flowExportFreewheelDriverFileFields = getFlowExportFreewheelDriverFileFieldsForSelectedFlow(state);

        for (const item of flowExportFreewheelDriverFileFields) {
            const cpm = ratesByFlowItem[item.ParentFlowItemId] || null;

            if (item.Price != cpm) {
                dispatch(
                    updateAttribute(
                        "flowExportFreewheelDriverFileFields",
                        item.FlowExportFreewheelDriverFileFieldId,
                        "Price",
                        cpm
                    )
                );
            }
        }
    };

export const newFlowExportTaxonomyFileFields =
    (flowItemId: number, parentId: number, values: string) => (dispatch: Dispatch) => {
        // const state = getState();
        // const parent: ?FlowItem = state.flowItems.byId[parentId];

        const thing: FlowExportTaxonomyFileFields = {
            FlowExportTaxonomyFileFieldId: 0,
            FlowItemId: flowItemId,
            ParentFlowItemId: parentId,
            ValueJSON: JSON.stringify(values),
        };
        dispatch(addThing("flowExportTaxonomyFileFields", thing));
    };

export const newFlowExportTradeDeskTemplateFields =
    (flowItemId: number, parentId: number) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const parent: ?FlowItem = state.flowItems.byId[parentId];
        const hasFieldRatePricing = state.session.enabledFeatures.includes("display-field-rates");

        const thing: FlowExportTradeDeskTemplateFields = {
            AudienceName: parent.FlowItemName,
            FlowExportTemplateId: 0,
            FlowItemId: flowItemId,
            ParentFlowItemId: parentId,
            Description: null,
            ExpansionType: "HOUSEHOLD",
            RateType: "CPM",
            CostPerMillion: newCPMandPOM(state, parent, flowItemId, parentId, hasFieldRatePricing, 1, true),
            PercentOfMediaCost: newCPMandPOM(state, parent, flowItemId, parentId, hasFieldRatePricing, 2, true),
            TTL: 30,
            ParentElementId: null,
            ParentElementName: null,
            ExpirationDate: getDefaultDate(),
            IsAutoGeneratedCPM: hasFieldRatePricing ? true : false,
            IsAutoGeneratedPOM: hasFieldRatePricing ? true : false,
        };
        dispatch(addThing("flowExportTradeDeskTemplateFields", thing));
    };

export const getUpdatedCPMandPOM =
    (parent, flowItemId, parentId, hasFieldRatePricing, cpmOrPom) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        let returnValue = newCPMandPOM(state, parent, flowItemId, parentId, hasFieldRatePricing, cpmOrPom, true);
        return returnValue;
    };

function getAllIdValues(obj, idValues) {
    for (const [key, value] of Object.entries(obj)) {
        if (value && typeof value === "object") {
            getAllIdValues(value, idValues);
        } else if (key == "id") {
            idValues.push(value);
        }
    }
}

const newCPMandPOM = (state, parent, flowItemId, parentId, hasFieldRatePricing, cpmOrPom, hasTTDPricing) => {
    let returnCPMvalue = 0;
    let returnPOMvalue = 0;
    const flowFiltersByFlowItemId = getFlowFiltersByFlowItemId(state);
    const fields = state.fields;
    const fieldRatesById = state.fieldsByCompany.fieldRates;
    const taxFieldRatesById = state.fieldsByCompany.taxmanFieldRates;

    if (hasFieldRatePricing) {
        // let cpmValue = 0.0;
        // let pomValue = 0.0;
        let cpmFRValue = 0.0;
        let pomFRValue = 0.0;

        // const flowItemsArray = Object.values(flowItems);
        const FlowItem = parent; // flowItemsArray.find(x => x.FlowItemId == cellInfo.ParentFlowItemId);
        if (!FlowItem || !(FlowItem.FlowItemType == "filter" || FlowItem.FlowItemType == "empty")) {
            return returnCPMvalue;
        }
        if (FlowItem.FlowItemType == "filter") {
            const flowFiltersArray = Object.values(flowFiltersByFlowItemId);
            const FlowFilter = flowFiltersArray.find(x => x.FlowItemId == parentId);
            const conditions = FlowFilter.FlowFilterCriteria;

            if (!conditions) {
                return returnCPMvalue;
            }

            const conditionsJSON = JSON.parse(conditions);
            const ids = [];

            getAllIdValues(conditionsJSON, ids);

            if (fields && ids) {
                ids.map(id => {
                    const fieldINeed = fields.byId[id];
                    if (hasFieldRatePricing) {
                        let fieldRatetoUse = fieldRatesById[fieldINeed.UmbrellaFieldId];
                        let taxmanFieldRatetoUse = taxFieldRatesById[id || 0];
                        if (fieldRatetoUse) {
                            let cpmValueForCurrentFieldId = hasTTDPricing
                                ? fieldRatetoUse.CPMCapTTD
                                : fieldRatetoUse.CPM;
                            let pomValueForCurrentFieldId = hasTTDPricing
                                ? fieldRatetoUse.PercentOfMediaTTD
                                : fieldRatetoUse.PercentOfMedia;
                            if (cpmValueForCurrentFieldId > cpmFRValue) {
                                cpmFRValue = cpmValueForCurrentFieldId;
                            }
                            if (pomValueForCurrentFieldId > pomFRValue) {
                                pomFRValue = pomValueForCurrentFieldId;
                            }
                        } else if (taxmanFieldRatetoUse) {
                            let cpmValueForCurrentFieldId = hasTTDPricing
                                ? taxmanFieldRatetoUse.CPMCapTTD
                                : taxmanFieldRatetoUse.CPM;
                            let pomValueForCurrentFieldId = hasTTDPricing
                                ? taxmanFieldRatetoUse.PercentOfMediaTTD
                                : taxmanFieldRatetoUse.PercentOfMedia;
                            if (cpmValueForCurrentFieldId > cpmFRValue) {
                                cpmFRValue = cpmValueForCurrentFieldId;
                            }
                            if (pomValueForCurrentFieldId > pomFRValue) {
                                pomFRValue = pomValueForCurrentFieldId;
                            }
                        }
                    }
                });
            }
        }

        if (FlowItem.FlowItemType == "empty") {
            const ids = GetFieldsIds(parentId, state);
            if (fields && ids) {
                ids.map(id => {
                    const fieldINeed = fields.byId[id];

                    if (hasFieldRatePricing) {
                        let fieldRatetoUse = fieldRatesById[fieldINeed.id];
                        let taxmanFieldRatetoUse = taxFieldRatesById[id || 0];
                        if (fieldRatetoUse) {
                            let cpmValueForCurrentFieldId = hasTTDPricing
                                ? fieldRatetoUse.CPMCapTTD
                                : fieldRatetoUse.CPM;
                            let pomValueForCurrentFieldId = hasTTDPricing
                                ? fieldRatetoUse.PercentOfMediaTTD
                                : fieldRatetoUse.PercentOfMedia;
                            if (cpmValueForCurrentFieldId > cpmFRValue) {
                                cpmFRValue = cpmValueForCurrentFieldId;
                            }
                            if (pomValueForCurrentFieldId > pomFRValue) {
                                pomFRValue = pomValueForCurrentFieldId;
                            }
                        } else if (taxmanFieldRatetoUse) {
                            let cpmValueForCurrentFieldId = hasTTDPricing
                                ? taxmanFieldRatetoUse.CPMCapTTD
                                : taxmanFieldRatetoUse.CPM;
                            let pomValueForCurrentFieldId = hasTTDPricing
                                ? taxmanFieldRatetoUse.PercentOfMediaTTD
                                : taxmanFieldRatetoUse.PercentOfMedia;
                            if (cpmValueForCurrentFieldId > cpmFRValue) {
                                cpmFRValue = cpmValueForCurrentFieldId;
                            }
                            if (pomValueForCurrentFieldId > pomFRValue) {
                                pomFRValue = pomValueForCurrentFieldId;
                            }
                        }
                    }
                });
            }
        }

        if (hasFieldRatePricing) {
            returnCPMvalue = cpmFRValue;
            returnPOMvalue = pomFRValue;
        }
    }
    if (cpmOrPom == 1) {
        return returnCPMvalue;
    } else {
        return returnPOMvalue;
    }
};

const GetFieldsIds = (parentId, state) => {
    let ids;

    // Looking into Case nodes
    const flowCases = getFlowCasesForSelectedFlow(state);
    const flowCasesArray = Object.values(flowCases);
    const flowCase = flowCasesArray.find(x => x.ChildFlowItemId == parentId);
    const flowRelations = Object.values(state.flowRelations.byId);
    const flowSplits = getFlowSplitsForSelectedFlow(state);
    const flowFilters = getFlowFiltersForSelectedFlow(state);

    if (flowCase) {
        const conditions = flowCase.FlowCaseCriteria;

        if (!conditions) {
            return ids;
        }

        const conditionsJSON = JSON.parse(conditions);
        const rules = conditionsJSON.rules;

        ids = rules.map(rule => rule.id);
    } else {
        // Looking into Split nodes because the flow item isn't from Case node
        const flowSplitsArray = Object.values(flowSplits);
        const flowSplit = flowSplitsArray.find(x => x.ChildFlowItemId == parentId);

        if (flowSplit) {
            ids = getAncestorFilterFieldIdsForFlowItemId(
                flowSplit.FlowItemId,
                Object.values(flowRelations),
                Object.values(flowFilters)
            );
        }
    }

    return ids;
};

export const newFlowCase =
    (flowItemId: number, isDefault: boolean, flowItemHasBalance: boolean) =>
    (dispatch: Dispatch, getState: GetState) => {
        // Add Case Row
        const flowCasesByItemId = getFlowCasesByFlowItemId(getState());
        let numCases = 0;

        if (flowCasesByItemId[flowItemId] != null) {
            if (flowCasesByItemId[flowItemId].filter(x => !x.IsBalance) != null) {
                numCases = flowCasesByItemId[flowItemId].filter(x => !x.IsBalance).length;
            }
            numCases++;
        }
        if (numCases == 0 && !isDefault && flowItemHasBalance) {
            numCases = 1;
            dispatch(newFlowCase(flowItemId, true, true)); // creates default first
        }

        if (numCases == 0) {
            numCases = 1;
        }

        const newLetter = String.fromCharCode("A".charCodeAt(0) + ((numCases % 26) - 1));

        let newCaseId = 0;
        const thing: FlowCase = isDefault
            ? {
                  FlowCaseId: 0,
                  FlowItemId: flowItemId,
                  FlowCaseCriteria: null,
                  FlowCasePriority: 0,
                  ChildFlowItemId: 0,
                  FlowCaseLabel: "Balance",
                  IsBalance: true,
                  FlowItemHasBalance: flowItemHasBalance,
              }
            : {
                  FlowCaseId: 0,
                  FlowItemId: flowItemId,
                  FlowCaseCriteria: null,
                  FlowCasePriority: numCases,
                  ChildFlowItemId: 0,
                  FlowCaseLabel: newLetter,
                  IsBalance: false,
                  FlowItemHasBalance: flowItemHasBalance,
              };
        dispatch(addThing("flowCases", thing, id => (newCaseId = id)));

        // Add FlowItem to hold the empty
        let newEmptyItemId = 0;
        dispatch(
            newFlowItem(id => (newEmptyItemId = id), "AutoGenEmpty", "empty", { getLocationFromThisParent: flowItemId })
        );

        // Add FlowEmpty Row
        const criteria = isDefault ? "Balance" : newLetter;
        dispatch(newFlowEmpty(newEmptyItemId, () => false, criteria));

        // Update FlowSplit to point to the new empty row
        dispatch(updateAttribute("flowCases", newCaseId, "ChildFlowItemId", newEmptyItemId));
        // Also Add Relation
        dispatch(newFlowRelation(flowItemId, newEmptyItemId));
    };

export const newFlowSegmentSplit =
    (flowItemId: number, parentFlowItemId: number, absoluteOrRelative: string) =>
    (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const segmentSplitsByItemId = getFlowSegmentSplitsByFlowItemId(state);
        const thisItemSplits = segmentSplitsByItemId[flowItemId] || [];
        const parentSplits = thisItemSplits.filter(x => x.ParentFlowItemId == parentFlowItemId);

        // using the same logic used in flowSplits
        const numSplits = parentSplits.length == 0 ? 1 : parentSplits.length + 1;
        const newLetter = String.fromCharCode("A".charCodeAt(0) + ((numSplits % 26) - 1));
        const thing: FlowSegmentSplit = {
            SegmentSplitId: 0,
            SegmentName: newLetter,
            FlowItemId: flowItemId,
            ParentFlowItemId: parentFlowItemId,
            AbsoluteOrRelative: absoluteOrRelative,
            AbsoluteNumRows: 0,
            RelativePercentRows: 0,
            SplitQuantity: 0,
            IsIncludeInDeploy: true,
        };

        dispatch(addThing("flowSegmentSplits", thing));
    };

export const newFlowSegmentSplitAndOffers =
    (
        flowItemId: number,
        parentFlowItemId: number,
        absoluteOrRelative: string,
        requiredOfferCodes: Array<FlowClientVariable>
    ) =>
    (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const segmentSplitsByItemId = getFlowSegmentSplitsByFlowItemId(state);
        const thisItemSplits = segmentSplitsByItemId[flowItemId] || [];
        const parentSplits = thisItemSplits.filter(x => x.ParentFlowItemId == parentFlowItemId);

        // using the same logic used in flowSplits
        const numSplits = parentSplits.length == 0 ? 1 : parentSplits.length + 1;
        const newLetter = String.fromCharCode("A".charCodeAt(0) + ((numSplits % 26) - 1));
        const thing: FlowSegmentSplit = {
            SegmentSplitId: 0,
            SegmentName: newLetter,
            FlowItemId: flowItemId,
            ParentFlowItemId: parentFlowItemId,
            AbsoluteOrRelative: absoluteOrRelative,
            AbsoluteNumRows: 0,
            RelativePercentRows: 0,
            SplitQuantity: 0,
            IsIncludeInDeploy: true,
        };

        let newSplitId = 0;

        dispatch(addThing("flowSegmentSplits", thing, id => (newSplitId = id)));

        requiredOfferCodes.forEach(element => {
            const offer: FlowSegmentSplitOffer = {
                SegmentSplitOfferId: 0,
                SegmentSplitId: newSplitId,
                DestinationOfferId: element.Id,
                Value: "",
                FlowItemId: flowItemId,
            };
            dispatch(addThing("flowSegmentSplitOffers", offer));
        });
    };

export const newFlowSegmentSplitOffers =
    (flowItemId: number, segmentSplitId: number, requiredOfferCodes: Array<FlowClientVariable>) =>
    (dispatch: Dispatch) => {
        requiredOfferCodes.forEach(element => {
            const offer: FlowSegmentSplitOffer = {
                SegmentSplitOfferId: 0,
                SegmentSplitId: segmentSplitId,
                DestinationOfferId: element.Id,
                Value: "",
                FlowItemId: flowItemId,
            };
            dispatch(addThing("flowSegmentSplitOffers", offer));
        });
    };

export const newFlowSegmentSplitOverWrite =
    (flowItemId: number, parentFlowItemId: number, absoluteOrRelative: string) =>
    (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const thisFlowParentItem: FlowItem = state.flowItems.byId[parentFlowItemId];
        if (thisFlowParentItem) {
            // Added to prevent blank screen when no parent exists. #44922
            // using the same logic used in flowSplits
            const thing: FlowSegmentSplit = {
                SegmentSplitId: 0,
                SegmentName: thisFlowParentItem.FlowItemName,
                FlowItemId: flowItemId,
                ParentFlowItemId: parentFlowItemId,
                AbsoluteOrRelative: absoluteOrRelative,
                AbsoluteNumRows: 0,
                RelativePercentRows: 100,
                SplitQuantity: 0,
                IsIncludeInDeploy: true,
            };

            dispatch(addThing("flowSegmentSplits", thing));
        }
    };

export const newFlowSegmentSplitAndOffersOverWrite =
    (
        flowItemId: number,
        //parentFlowItemId: number,
        segmentSplits: Array<FlowSegmentSplit>,
        requiredOfferCodes: Array<FlowClientVariable>,
        segmentSplitOffers: Array<FlowSegmentSplitOffer>
    ) =>
    (dispatch: Dispatch) => {
        for (const split of segmentSplits) {
            const existSegmentSplitOffers = segmentSplitOffers.filter(x => x.SegmentSplitId == split.SegmentSplitId);
            if (existSegmentSplitOffers != null && existSegmentSplitOffers.length == 0) {
                requiredOfferCodes.forEach(element => {
                    const offer: FlowSegmentSplitOffer = {
                        SegmentSplitOfferId: 0,
                        SegmentSplitId: split.SegmentSplitId,
                        DestinationOfferId: element.Id,
                        Value: "",
                        FlowItemId: flowItemId,
                    };
                    dispatch(addThing("flowSegmentSplitOffers", offer));
                });
            }
        }
    };

export const clearAndRedirect = () => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const flowFolderId = state.flows.byId[state.selected.flow].FlowFolderId;
    dispatch(SetPreviouslyClosedFlow(true));
    dispatch(SetPreviouslySelectedFolder(flowFolderId));
    dispatch(clearSelectedFlow());
    dispatch(goToUrl("/flows/design"));
};

export const closeFlow = (flowId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const flow: ?Flow = state.flows.byId[flowId];
    if (flow && flow.FlowId > 0 && flow.hasUnsavedChanges) {
        dispatch(setVar("loss_of_work_queued_action", clearAndRedirect()));
        dispatch(setVar("loss_of_work_canceled_queued_action", {}));
        dispatch(showModal("LOSS_OF_WORK", { flow }));
    } else {
        dispatch(setVar("loss_of_work_queued_action", {}));
        dispatch(setVar("loss_of_work_canceled_queued_action", {}));
        dispatch(clearAndRedirect());
    }
};

export const setSelectedFlow = (flowId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const flow: ?Flow = state.flows.byId[state.selected.flow];
    if (flow != null && flow.FlowId > 0 && flow.hasUnsavedChanges == true) {
        // Has HasUnsavedChanges
        dispatch(setVar("loss_of_work_queued_action", setSelectedFlowNoUnsavedCheck(flowId)));
        dispatch(setVar("loss_of_work_canceled_queued_action", {}));
        dispatch(showModal("LOSS_OF_WORK", { flow }));
    } else {
        dispatch(setVar("loss_of_work_queued_action", {}));
        dispatch(setVar("loss_of_work_canceled_queued_action", {}));
        dispatch(flowEnsureItemDetailsExist());
        dispatch(setSelectedFlowNoUnsavedCheck(flowId));
        dispatch(flowRemoveUnsavedItems());
    }
};

export const setSelectedFlowItemId = (flowItemId: number) => (dispatch: Dispatch) => {
    dispatch({ type: "SET_SELECTED_FLOW_ITEM", flowItemId });
};

export const setSimpleFlowGuideActiveStep = (newStep: number) => (dispatch: Dispatch) => {
    dispatch({ type: "SET_SIMPLE_FLOW_GUIDE_ACTIVE_STEP", newStep });
};

export const setNewTabValue = (newTabValue: number) => (dispatch: Dispatch) => {
    dispatch({ type: "SET_FLOW_CONTROL_TAB_VALUE", newTabValue });
};

export const setSelectedFlowItems = (flowItems: Array<number>) => (dispatch: Dispatch) => {
    dispatch({ type: "SET_SELECTED_FLOW_ITEMS", flowItems });
};

export const setSelectedFlowRelations = (flowRelations: Array<number>) => (dispatch: Dispatch) => {
    dispatch({ type: "SET_SELECTED_FLOW_RELATIONS", flowRelations });
};

export const setFlowItemLocation = (flowItemId: number, x: any, y: any) => (dispatch: Dispatch) => {
    dispatch(
        updateMultipleAttribute("flowItems", flowItemId, {
            x,
            y,
        })
    );
};

export const setSelectedFlowNoUnsavedCheck = (flowId: number) => (dispatch: Dispatch, getState: GetState) => {
    dispatch({ type: "SET_SELECTED_FLOW", flowId, meta: { doNotBatch: true } });
    const state = getState();
    const flow: ?Flow = state.flows.byId[state.selected.flow];

    if (!state.router || !state.router.location) {
        return;
    }

    // For some pages, update the url to include the flowId in it.  Possibly need a better solution
    // if we get more of these?
    const location = state.router.location;

    //If I am already on the page I am supposed to be on, don't do a redirect
    const onFlowsLandingRefresh = !flowId && location.pathname === "/flows/design";
    const onOpenFlowRefresh = location.pathname === `/flows/${flowId}/design`;
    if (onFlowsLandingRefresh || onOpenFlowRefresh) return;

    if (flowId == null) {
        if (location.pathname.includes("/flows")) {
            dispatch(push("/flows/design"));
        } else {
            return;
        }
    }
    if (location.pathname.includes("/flows") && location.pathname.includes("/design") && flowId) {
        if (flow && flow.FlowBaseType && flow.FlowBaseType != 0) {
            let flowItems = getFlowItemsForSelectedFlow(state);
            const filterItem = flowItems.find(x => x.FlowItemType == "filter");

            if (filterItem) {
                dispatch(push(`/flows/${flowId}/item/${filterItem.FlowItemId}`));
            } else {
                dispatch(push(`/flows/${flowId}/item`));
            }
        } else {
            dispatch(push(`/flows/${flowId}/design`));
        }
    }
};

// Careful: This actually mutates the given argument.
const parseJsonInsideFlowData = flowData => {
    if (Array.isArray(flowData.flowClientVariables)) {
        flowData.flowClientVariables = flowData.flowClientVariables.map(x => ({
            Id: x.Id,
            VariableId: x.VariableId,
            FlowId: x.FlowId,
            VariableValue: JSON.parse(x.VariableValueJSON),
            IsVisible: x.IsVisible,
        }));
    }

    if (Array.isArray(flowData.flowItemClientVariables)) {
        flowData.flowItemClientVariables = flowData.flowItemClientVariables.map(x => ({
            FlowItemClientVariableId: x.FlowItemClientVariableId,
            VariableId: x.VariableId,
            FlowId: x.FlowId,
            FlowItemId: x.FlowItemId,
            ChildFlowItemId: x.ChildFlowItemId,
            VariableValue: JSON.parse(x.VariableValueJSON),
        }));
    }

    if (Array.isArray(flowData.flowExportTaxonomyFileFields)) {
        flowData.flowExportTaxonomyFileFields = flowData.flowExportTaxonomyFileFields.map(x => ({
            FlowExportTaxonomyFileFieldId: x.FlowExportTaxonomyFileFieldId,
            FlowItemId: x.FlowItemId,
            ParentFlowItemId: x.ParentFlowItemId,
            ValueJSON: x.ValueJSON,
        }));
    }

    if (Array.isArray(flowData.flowSVDedupes)) {
        flowData.flowSVDedupes = flowData.flowSVDedupes.map(x => ({
            FlowSVDedupeId: x.FlowSVDedupeId,
            FlowItemId: x.FlowItemId,
            SVField: x.SVField,
            SortByFields: JSON.parse(x.SortByFieldsJSON),
        }));
    }

    if (Array.isArray(flowData.flowExternalServices)) {
        flowData.flowExternalServices = flowData.flowExternalServices.map(x => ({
            ...x,
            ServicesJSON: JSON.parse(x.ServicesJSON),
            InputTableColumns: JSON.parse(x.InputTableColumns),
        }));
    }
    return flowData;
};

const normalizeFlowData = flowData => {
    const flow = new schema.Entity("flows", {}, { idAttribute: "FlowId" });
    const flowItem = new schema.Entity("flowItems", {}, { idAttribute: "FlowItemId" });
    const flowRelation = new schema.Entity("flowRelations", {}, { idAttribute: "FlowRelationId" });
    const flowFilter = new schema.Entity("flowFilters", {}, { idAttribute: "FlowFilterId" });
    const flowScript = new schema.Entity("flowScripts", {}, { idAttribute: "FlowScriptId" });
    const flowScriptDBUI = new schema.Entity("flowScriptsDBUI", {}, { idAttribute: "FlowScriptDBUIId" });
    const flowMultiExport = new schema.Entity("flowMultiExports", {}, { idAttribute: "FlowMultiExportId" });
    const flowOutput = new schema.Entity("flowOutputs", {}, { idAttribute: "FlowOutputId" });
    const flowScriptResult = new schema.Entity("flowScriptResults", {}, { idAttribute: "FlowScriptResultId" });
    const flowScriptResultsHistory = new schema.Entity(
        "flowScriptResultsHistory",
        {},
        { idAttribute: "FlowScriptResultId" }
    );
    const flowSingleView = new schema.Entity("flowSingleViews", {}, { idAttribute: "FlowSingleViewId" });
    const flowSplit = new schema.Entity("flowSplits", {}, { idAttribute: "FlowSplitId" });
    const flowCase = new schema.Entity("flowCases", {}, { idAttribute: "FlowCaseId" });
    const flowMerge = new schema.Entity("flowMerges", {}, { idAttribute: "FlowMergeId" });
    const flowOfferMerge = new schema.Entity("flowOfferMerges", {}, { idAttribute: "FlowOfferMergeId" });
    const flowReports = new schema.Entity("flowReports", {}, { idAttribute: "FlowReportId" });
    const flowModels = new schema.Entity("flowModels", {}, { idAttribute: "FlowModelId" });
    const flowExportReport = new schema.Entity("flowExportReports", {}, { idAttribute: "FlowExportReportId" });
    const flowCannedReport = new schema.Entity("flowCannedReports", {}, { idAttribute: "FlowCannedReportId" });
    const flowFromCloud = new schema.Entity("flowFromClouds", {}, { idAttribute: "FlowFromCloudId" });
    const flowToCloud = new schema.Entity("flowToClouds", {}, { idAttribute: "FlowToCloudId" });
    const flowEmpty = new schema.Entity("flowEmpties", {}, { idAttribute: "FlowEmptyId" });
    const flowClientVariable = new schema.Entity("flowClientVariables", {}, { idAttribute: "Id" });
    const flowExport = new schema.Entity("flowExports", {}, { idAttribute: "FlowExportId" });
    const flowSVDedupe = new schema.Entity("flowSVDedupes", {}, { idAttribute: "FlowSVDedupeId" });
    const flowDataLoads = new schema.Entity("flowDataLoads", {}, { idAttribute: "FlowDataLoadId" });
    const flowDataLoadColumns = new schema.Entity("flowDataLoadColumns", {}, { idAttribute: "FlowDataLoadColumnId" });
    const flowOffloads = new schema.Entity("flowOffloads", {}, { idAttribute: "FlowOffloadId" });
    const flowOffloadColumns = new schema.Entity("flowOffloadColumns", {}, { idAttribute: "FlowOffloadColumnId" });
    const flowGuideSettings = new schema.Entity("flowGuideSettings", {}, { idAttribute: "FlowGuideId" });
    const flowSegmentSplits = new schema.Entity("flowSegmentSplits", {}, { idAttribute: "SegmentSplitId" });
    const flowSegmentSplitOffers = new schema.Entity(
        "flowSegmentSplitOffers",
        {},
        { idAttribute: "SegmentSplitOfferId" }
    );
    const flowExternalServices = new schema.Entity(
        "flowExternalServices",
        {},
        { idAttribute: "FlowExternalServiceId" }
    );
    const flowExternalServiceParameters = new schema.Entity(
        "flowExternalServiceParameters",
        {},
        { idAttribute: "FlowServiceParameterId" }
    );
    const flowExternalServiceInputs = new schema.Entity("flowExternalServiceInputs", {}, { idAttribute: "InputId" });
    const flowExternalServiceHardcodes = new schema.Entity(
        "flowExternalServiceHardcodes",
        {},
        { idAttribute: "HardcodeId" }
    );

    const flowItemClientVariable = new schema.Entity(
        "flowItemClientVariables",
        {},
        { idAttribute: "FlowItemClientVariableId" }
    );
    const flowItemEndpoint = new schema.Entity("flowItemEndpoints", {}, { idAttribute: "Id" });
    const flowItemOfferCode = new schema.Entity("flowItemOfferCodes", {}, { idAttribute: "FlowItemOfferCodeId" });
    const flowExportDistributionPlatform = new schema.Entity(
        "flowExportDistributionPlatforms",
        {},
        { idAttribute: "FlowExportDistributionPlatformId" }
    );
    const flowRelationParentLabel = new schema.Entity(
        "flowRelationParentLabels",
        {},
        { idAttribute: "FlowRelationParentLabelId" }
    );
    const flowExportTemplateField = new schema.Entity(
        "flowExportTemplateFields",
        {},
        { idAttribute: "FlowExportTemplateId" }
    );

    const flowExportPinterestTemplateField = new schema.Entity(
        "flowExportPinterestTemplateFields",
        {},
        { idAttribute: "FlowExportTemplateId" }
    );

    const flowExportTikTokTemplateField = new schema.Entity(
        "flowExportTikTokTemplateFields",
        {},
        { idAttribute: "FlowExportTemplateId" }
    );

    const flowExportFreewheelDriverFileField = new schema.Entity(
        "flowExportFreewheelDriverFileFields",
        {},
        { idAttribute: "FlowExportFreewheelDriverFileFieldId" }
    );

    const flowExportTaxonomyFileFields = new schema.Entity(
        "flowExportTaxonomyFileFields",
        {},
        { idAttribute: "FlowExportTaxonomyFileFieldId" }
    );

    const flowExportTradeDeskTemplateField = new schema.Entity(
        "flowExportTradeDeskTemplateFields",
        {},
        { idAttribute: "FlowExportTemplateId" }
    );
    const flowExportXanderDriverField = new schema.Entity(
        "flowExportXandrDriverFields",
        {},
        { idAttribute: "XandrDriverFieldId" }
    );
    const flowExpressions = new schema.Entity("flowExpressions", {}, { idAttribute: "FlowExpressionId" });
    const flowExpressionConstraints = new schema.Entity(
        "flowExpressionConstraints",
        {},
        { idAttribute: "FlowExpressionConstraintId" }
    );
    const flowDescriptions = new schema.Entity("flowDescriptions", {}, { idAttribute: "FlowDescriptionId" });

    const mySchema = {
        flows: [flow],
        flowItems: [flowItem],
        flowRelations: [flowRelation],
        flowFilters: [flowFilter],
        flowScripts: [flowScript],
        flowScriptsDBUI: [flowScriptDBUI],
        flowScriptResults: [flowScriptResult],
        flowScriptResultsHistory: [flowScriptResultsHistory],
        flowSplits: [flowSplit],
        flowCases: [flowCase],
        flowMerges: [flowMerge],
        flowOfferMerges: [flowOfferMerge],
        flowReports: [flowReports],
        flowSingleViews: [flowSingleView],
        flowModels: [flowModels],
        flowOutputs: [flowOutput],
        flowMultiExports: [flowMultiExport],
        flowExports: [flowExport],
        flowExportReports: [flowExportReport],
        flowCannedReports: [flowCannedReport],
        flowFromClouds: [flowFromCloud],
        flowToClouds: [flowToCloud],
        flowEmpties: [flowEmpty],
        flowClientVariables: [flowClientVariable],
        flowItemClientVariables: [flowItemClientVariable],
        flowSVDedupes: [flowSVDedupe],
        flowItemEndpoints: [flowItemEndpoint],
        flowItemOfferCodes: [flowItemOfferCode],
        flowExportDistributionPlatforms: [flowExportDistributionPlatform],
        flowRelationParentLabels: [flowRelationParentLabel],
        flowDataLoads: [flowDataLoads],
        flowDataLoadColumns: [flowDataLoadColumns],
        flowOffloads: [flowOffloads],
        flowOffloadColumns: [flowOffloadColumns],
        flowGuideSettings: [flowGuideSettings],
        flowExportTemplateFields: [flowExportTemplateField],
        flowExportPinterestTemplateFields: [flowExportPinterestTemplateField],
        flowExportTikTokTemplateFields: [flowExportTikTokTemplateField],
        flowExportTradeDeskTemplateFields: [flowExportTradeDeskTemplateField],
        flowExportFreewheelDriverFileFields: [flowExportFreewheelDriverFileField],
        flowExportTaxonomyFileFields: [flowExportTaxonomyFileFields],
        flowExportXandrDriverFields: [flowExportXanderDriverField],
        flowSegmentSplits: [flowSegmentSplits],
        flowSegmentSplitOffers: [flowSegmentSplitOffers],
        flowExternalServices: [flowExternalServices],
        flowExternalServiceParameters: [flowExternalServiceParameters],
        flowExternalServiceInputs: [flowExternalServiceInputs],
        flowExternalServiceHardcodes: [flowExternalServiceHardcodes],
        flowExpressions: [flowExpressions],
        flowDescriptions: [flowDescriptions],
        flowExpressionConstraints: [flowExpressionConstraints],
    };

    return normalize(flowData, mySchema);
};

export const getScriptColumnsForTable =
    (table: string, db: string, schema: string, dbEnv: number) => (dispatch: Dispatch) => {
        dispatch(incAjaxCount());
        fetch(`/Flows/GetTableColumns`, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                table,
                db,
                schema,
                dbEnv,
            }),
        })
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                if (data.columns) {
                    if (dbEnv == 2) {
                        dispatch({
                            type: "UPDATE_SCRIPT_OBJECTS",
                            catalog: db,
                            schema,
                            table,
                            columns: data.columns,
                        });
                    } else if (dbEnv == 1) {
                        dispatch({
                            type: "UPDATE_UIDB_SCRIPT_OBJECTS",
                            catalog: db,
                            schema,
                            table,
                            columns: data.columns,
                        });
                    }
                }
                dispatch(decAjaxCount());
            })
            .catch(error => {
                dispatch(decAjaxCount());
                h.error("Error accessing columns for script table.", error);
            });
    };

export const getFlowBackup = (flowId: number) => (dispatch: Dispatch) => {
    if (flowId != null) {
        dispatch(incAjaxCount());
        request(
            `/Flows/GetFlowBackup/` + flowId,
            {
                method: "POST",
                credentials: "same-origin",
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                dispatch(setBackupList(data.backupList));
                dispatch(decAjaxCount());
                return;
            })
            .catch(error => {
                dispatch(decAjaxCount());
                h.error("Error getting flow backup.", error);
            });
    }
};

export const restoreFlowBackup = (flowId: number, flowBackupValue: string) => (dispatch: Dispatch) => {
    dispatch(incAjaxCount());
    dispatch(deleteAllItemsInFlow(flowId));
    dispatch(notifyBlue("Flow is being restored to a previous backup, please wait..."));
    let backupData = new FormData();
    backupData.append("value", flowBackupValue);
    fetch(`/Flows/RestoreBackup`, {
        credentials: "same-origin",
        method: "POST",
        body: backupData,
    })
        .then(h.checkStatus)
        .then(toJson)
        .then(() => {
            dispatch(notifyGreen("Flow backup successfully restored."));
            dispatch(decAjaxCount());
            dispatch(requestFlow(flowId));
            dispatch(setHasUnsavedChanges(false));
            return;
        })
        .catch(error => {
            dispatch(decAjaxCount());
            dispatch(requestFlow(flowId));
            h.error("Error restoring flow backup.", error);
        });
};

export const requestFlowProcess =
    (flowId: number, isSimpleFlowDeploy: boolean = false) =>
    (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const processType = state.flowDesigner.processType;

        // cynical check to stop and check if there are any before we get started
        if (processType == FlowProcessingTypes.withoutQuantities) {
            const flowItems = getFlowItemsArray(state);
            const itemsWithoutQuantities = flowItems
                .filter(x => x.FlowId == flowId && x.IsActive == true && (x.FlowItemQty == null || x.FlowItemQty == 0))
                .map(x => x.FlowItemId);

            if (!itemsWithoutQuantities || itemsWithoutQuantities.length == 0) {
                dispatch(notifyBlue("There are no qualified items to execute with this option."));
                return;
            }
        }

        dispatch(setFlowItemsIsRunning(flowId, FlowIsRunning.CLIENTSUBMITTED));

        dispatch(incAjaxCount());
        request(
            `/Flows/ClearAndProcessEntireFlow?FlowId=${flowId}&processType=${processType}&isSimpleFlowDeploy=${isSimpleFlowDeploy}`,
            {
                method: "POST",
                credentials: "same-origin",
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                if (!data.result) {
                    dispatch(setFlowItemsIsRunning(flowId, FlowIsRunning.IDLE));
                    if (data.error) {
                        h.error(data.error);
                    } else {
                        throw new Error("Error calculating flow.");
                    }
                }

                const flowItems = getFlowItemsArray(getState());
                const checkToCloud = flowItems.filter(x => x.FlowId == flowId && x.FlowItemType == "toCloud");
                if (checkToCloud.length > 0) {
                    dispatch(resetCloudFlowObjects());
                }

                dispatch(decAjaxCount());
            })
            .catch(error => {
                dispatch(decAjaxCount());
                dispatch(setFlowItemsIsRunning(flowId, FlowIsRunning.IDLE));
                h.error("Error calculating flow.", error);
            });
    };

export const setFlowItemsIsRunning =
    (flowId: number, newIsRunning: number) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const processType = state.flowDesigner.processType;

        const flowItems = getFlowItemsArray(state);
        let flowItemIds = [];
        switch (processType) {
            case FlowProcessingTypes.execute:
                flowItemIds = flowItems.filter(x => x.FlowId == flowId && x.IsActive == true).map(x => x.FlowItemId);
                break;
            case FlowProcessingTypes.withoutQuantities:
                flowItemIds = flowItems
                    .filter(
                        x => x.FlowId == flowId && x.IsActive == true && (x.FlowItemQty == null || x.FlowItemQty == 0)
                    )
                    .map(x => x.FlowItemId);
                break;
            default:
                flowItemIds = flowItems.filter(x => x.FlowId == flowId && x.IsActive == true).map(x => x.FlowItemId);
                break;
        }

        for (const id of flowItemIds) {
            dispatch(updateAttribute("flowItems", id, "IsRunning", newIsRunning));
        }
    };

export const requestFlowItemProcess =
    (flowItemId: number, isRunChildren: boolean = false) =>
    (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const anyParentIncomplete = isAnyParentIncomplete(state, flowItemId);
        if (anyParentIncomplete) {
            const thisFlowItem: FlowItem = state.flowItems.byId[flowItemId];
            dispatch(
                notifyBlack(
                    "The flow item " +
                        thisFlowItem.FlowItemName +
                        " cannot be calculated because at least one of the parents is incomplete."
                )
            );
            return;
        }

        const flowTypeCheck: FlowItem = state.flowItems.byId[flowItemId];
        if (flowTypeCheck.FlowItemType == "toCloud") {
            dispatch(resetCloudFlowObjects());
        }

        dispatch(updateAttribute("flowItems", flowItemId, "IsRunning", FlowIsRunning.CLIENTSUBMITTED));

        dispatch(incAjaxCount());
        fetch(`/Flows/ProcessFlowItem?FlowItemId=${flowItemId}&runChildren=${isRunChildren ? "true" : "false"}`, {
            method: "POST",
            credentials: "same-origin",
        })
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                if (!data.result) {
                    if (data.error) {
                        h.error(data.error);
                    } else {
                        dispatch(updateAttribute("flowItems", flowItemId, "IsRunning", FlowIsRunning.IDLE));
                        throw new Error("Error calculating flow.");
                    }
                }
                dispatch(decAjaxCount());
            })
            .catch(error => {
                dispatch(decAjaxCount());
                dispatch(updateAttribute("flowItems", flowItemId, "IsRunning", FlowIsRunning.IDLE));
                h.error("Error calculating flow.", error);
            });
    };

export const requestCancelEntireFlow = (flowId: number) => (dispatch: Dispatch) => {
    dispatch(incAjaxCount());
    dispatch(setFlowItemsIsRunning(flowId, FlowIsRunning.CANCELLING));
    fetch(`/Flows/CancelEntireFlow?FlowId=${flowId}`, {
        method: "POST",
        credentials: "same-origin",
    })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            if (!data.result) {
                if (data.error) {
                    h.error(data.error);
                } else {
                    throw new Error("Error cancelling flow.");
                }
            }
            dispatch(decAjaxCount());
            dispatch(requestFlow(flowId));
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error cancelling flow.", error);
        });
};

export const requestCancelFlowItem = (flowId: number, flowItemId: number) => (dispatch: Dispatch) => {
    dispatch(incAjaxCount());
    dispatch(setFlowItemsIsRunning(flowId, FlowIsRunning.CANCELLING));
    fetch(`/Flows/CancelFlowItem?FlowId=${flowId}&FlowItemId=${flowItemId}`, {
        method: "POST",
        credentials: "same-origin",
    })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            if (!data.result) {
                if (data.error) {
                    h.error(data.error);
                } else {
                    throw new Error("Error cancelling flow item.");
                }
            }
            dispatch(decAjaxCount());
            dispatch(requestFlow(flowId));
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error cancelling flow item.", error);
        });
};

export const requestCancelDeployment = (flowId: number) => (dispatch: Dispatch) => {
    dispatch(incAjaxCount());
    dispatch(setFlowItemsIsRunning(flowId, FlowIsRunning.CANCELDEPLOYING));
    fetch(`/Flows/CancelDeployExport?FlowId=${flowId}`, {
        method: "POST",
        credentials: "same-origin",
    })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            if (!data.result) {
                if (data.error) {
                    h.error(data.error);
                } else {
                    throw new Error("Error cancelling flow.");
                }
            }
            dispatch(decAjaxCount());
            //         dispatch(requestFlow(flowId));
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error cancelling flow.", error);
        });
};

type IdChange = Array<Array<number>>;
type IdChanges = {|
    Flows: IdChange,
    FlowItems: IdChange,
    FlowRelations: IdChange,
    FlowFilters: IdChange,
    FlowSplits: IdChange,
    FlowEmpties: IdChange,
|};
export const changeFlowUrlAfterIdChanges = (idChanges: IdChanges) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const location = state.router.location;

    if (location.pathname.includes("/flows")) {
        let matchingChanges = idChanges.Flows.filter(x => x[0] == state.selected.flow);

        // go back to item
        if (location.pathname.includes("/item")) {
            matchingChanges = idChanges.FlowItems.filter(x => x[0] == state.selected.flowItem);

            let flowItemId =
                matchingChanges && matchingChanges[0] && matchingChanges[0][1]
                    ? matchingChanges[0][1]
                    : state.selected.flowItem;

            if (state.selected.flowItem != flowItemId) {
                dispatch(goEditFlowItem(flowItemId, false));
            }

            return;
        }

        // go back to flow
        if (matchingChanges.length > 0) {
            dispatch(goEditFlow(matchingChanges[0][1]));
        }
    }
};

export const removeAnyDuplicateRelations = () => (dispatch: Dispatch, getState: GetState) => {
    let state = getState();
    let relations = getFlowRelationsArray(state);
    const links = relations.map(x => x.ParentFlowItemId + "-" + x.ChildFlowItemId);

    const count = things => things.reduce((a, b) => Object.assign(a, { [b]: (a[b] || 0) + 1 }), {});
    const duplicates = dict => Object.keys(dict).filter(a => dict[a] > 1);

    const duplicateLinks = duplicates(count(links));
    for (const duplicateLink of duplicateLinks) {
        const [parent, child] = duplicateLink.split("-").map(x => parseInt(x, 10));
        const idsToDelete = relations
            .filter(x => x.ParentFlowItemId == parent && x.ChildFlowItemId == child)
            .map(x => x.FlowRelationId)
            .slice(1);
        for (const id of idsToDelete) {
            dispatch(deleteFlowRelation(id));
        }

        state = getState();
        relations = getFlowRelationsArray(state);
    }
};

import { makeGetFlowExportTaxonomyFields } from "../components/flows/item/FlowExportItems/FlowExportOfferEdit";

export const processDragAndDrop = (parentId: number, childId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const parent: ?FlowItem = state.flowItems.byId[parentId];
    const child: ?FlowItem = state.flowItems.byId[childId];

    if (parent == null || child == null || parentId == childId) {
        return;
    }

    dispatch(updateFlowShouldValidate(state.selected.flow, true));

    // Already Exists
    const relationAlreadyExists = flowRelationExists(state, parentId, childId);
    if (relationAlreadyExists) {
        return;
    }

    // Loop
    const loopDetected = isLoopDetected(state, childId, parentId);
    if (loopDetected) {
        dispatch(notifyBlack("This link cannot be added because it would introduce a loop."));
        return;
    }

    // Not Allowed
    const isRelationAllowed = validateFlowRelation(state, parentId, parent.FlowItemType, childId, child.FlowItemType);
    if (!isRelationAllowed) {
        return; // validateFlowRelation() sent a message to the user for us
    }

    // We're going to make it - let's see if there's an invisible link to 0 we have to move
    const flowRelationParentZeroExists = flowRelationExists(state, 0, childId);
    const flowRelations = getFlowRelationsArray(state);

    if (flowRelationParentZeroExists) {
        const relationIdsToUpdate = flowRelations
            .filter(x => x.ParentFlowItemId == 0 && x.ChildFlowItemId == childId)
            .map(x => x.FlowRelationId);

        for (const flowRelationId of relationIdsToUpdate) {
            dispatch(updateAttribute("flowRelations", flowRelationId, "ParentFlowItemId", parentId));
        }

        // Invalidate Child Items
        dispatch(invalidateItemAndChildren(childId));
    } else {
        dispatch(newFlowRelation(parentId, childId));
    }

    if (child.FlowItemType == "merge") {
        // Create a flow merge
        dispatch(newFlowMerge(childId, parentId));
    } else if (child.FlowItemType == "offerMerge" || child.FlowItemType == "export") {
        dispatch(newFlowOfferMerge(childId, parentId));

        if (child.FlowItemType == "export") {
            if (state.vars.destinations && state.vars.destinations.length > 0) {
                const flowExports = getFlowExportsForSelectedFlow(state);
                const flowExport = flowExports.find(x => x.FlowItemId == child.FlowItemId);
                const destination = state.vars.destinations.find(x => x.PartnerAccessId == flowExport.DestinationId);
                const getFlowExportTaxonomyFields = makeGetFlowExportTaxonomyFields();
                const flowExportTaxonomyFileFields = getFlowExportTaxonomyFields(state, { flowItemId: childId }) || [];
                const layouts = state.layoutObjects.objects;
                const layout = layouts && layouts.filter(x => x.Layout.LayoutID == flowExport?.ExportId)[0];

                if (destination) {
                    if (
                        state.session.enabledFeatures.includes("flow-export-template-fields") &&
                        destination.DeploySetting == DeploySettings.DeployAdditionalFacebookTemplate
                    ) {
                        dispatch(newFlowExportTemplateFields(childId, parentId));
                    }

                    if (destination.DeploySetting == DeploySettings.DeployPinterestAutomationTemplate) {
                        dispatch(newFlowExportPinterestTemplateFields(childId, parentId));
                    }

                    if (destination.DeploySetting == DeploySettings.DeployTikTokDriverFile) {
                        dispatch(newFlowExportTikTokTemplateFields(childId, parentId));
                    }

                    if (destination.DeploySetting == DeploySettings.DeployTradedeskTemplate) {
                        dispatch(newFlowExportTradeDeskTemplateFields(childId, parentId));
                    }
                    //
                    if (layout && layout.LayoutTaxonomyObjects.length > 0) {
                        // dispatch(newFlowExportTradeDeskTemplateFields(childId, parentId));
                        const taxonomyField = flowExportTaxonomyFileFields.find(
                            x => x.FlowItemId == childId && x.ParentFlowItemId == parentId
                        );

                        let taxonomyValues = {};

                        layout.LayoutTaxonomyObjects.forEach(x => {
                            taxonomyValues[x.ExportName] = "";
                        });

                        if (!taxonomyField) {
                            newFlowExportTaxonomyFileFields(childId, parentId, taxonomyValues);
                        }
                    }

                    if (
                        destination.DeploySetting == DeploySettings.DeployFreewheelDriverFile ||
                        destination.DeploySetting == DeploySettings.DeployMagniteDriverFile
                    ) {
                        dispatch(newFlowExportFreewheelDriverFileFields(childId, parentId));
                    }

                    if (destination.DeploySetting == DeploySettings.DeployXandrTemplate) {
                        dispatch(newFlowExportXandrDriverFields(childId, parentId));
                    }
                }
            }
        }
    } else if (child.FlowItemType == "insights" || child.FlowItemType == "discovery") {
        // Validate Insight nodes linked data
        dispatch(validateInsightNodeLinks(child.FlowItemId, child.FlowItemType));
    }

    dispatch(updateFlowShouldValidate(state.selected.flow, false));
};

export const validateInsightNodeLinks =
    (flowItemId: number, flowItemType: string) => (dispatch: Dispatch, getState: GetState) => {
        let state = getState();
        // Check for Third Party data linked to Insights nodes
        const hasThirdParty = get3rdPartyLinked(state, flowItemId);

        // Check if the flow item has OAC data
        const hasOACData = ancestorhasOACData(state, flowItemId);

        // Function to handle showing the invalid link modal and removing the relation based on 3rd party data and OAC data
        const showInvalidModal = (hasThirdParty: boolean) => {
            const flowRelations = getFlowRelationsForSelectedFlow(state);
            const relation = flowRelations.find(x => x.ChildFlowItemId === flowItemId);

            if (relation) {
                // Delete the relationship
                dispatch(_deleteThing("flowRelations", relation.FlowRelationId));

                // Display a Modal notifying the user
                dispatch(showModal("INSIGHTS_INVALID_LINK", { nodeType: flowItemType, hasThirdParty }));
            }
        };

        // Third Party data cannot be linked to Insights nodes
        if (hasThirdParty || hasOACData) {
            // Passing hasThirdParty as the same Modal is used for both checks.
            // If true, the modal shows "Third Party data cannot be linked to Insights nodes".
            // If false, the modal shows "OAC data cannot be linked to Insights nodes".
            showInvalidModal(hasThirdParty);
        }
    };

export const saveFlowBackup = (flowId: number) => async (dispatch: Dispatch) => {
    let url = "/flows/SaveFlowBackup/" + flowId;
    dispatch(incAjaxCount());
    return fetch(url, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(() => {
            dispatch(decAjaxCount());
            dispatch(notifyGreen("Flow backup successfully created."));
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error creating flow backup", error);
        });
};

export const updateFlowItemIsIncomplete = (flowItems: Array<FlowItem>) => (dispatch: Dispatch, getState: GetState) => {
    let state = getState();
    const itemErrors = getItemErrorsForSelectedFlow(state);
    for (const item of flowItems) {
        let checkItem = itemErrors[item.FlowItemId];
        // No Errors found
        if (!checkItem || typeof checkItem == "undefined") {
            if (item.IsIncomplete) {
                dispatch(updateAttribute("flowItems", item.FlowItemId, "IsIncomplete", false));
            }
        } else if (!item.IsIncomplete) {
            // Errors
            dispatch(updateAttribute("flowItems", item.FlowItemId, "IsIncomplete", true));
        }
    }
};

export const requestFlowSave = (params: RequestFlowSave) => async (dispatch: Dispatch, getState: GetState) => {
    const {
        overwrite,
        flowItemId,
        copyOfferCodes,
        copyVariables,
        selectedCompany,
        isRunChildren,
        processEntireFlow,
        isSimpleFlowDeploy,
        isSaveAs,
        deployFlowExportAfterSave,
        tableKey,
    } = params;

    dispatch(removeAnyDuplicateRelations());

    let state = getState();
    let flowId: number = state.selected.flow;
    let flow: ?Flow = state.flows.byId[flowId];
    if (flow == null) {
        h.error("Error saving flow [2901a].");
        throw new Error("Error saving flow [2901a].");
    }
    dispatch(updateFlowShouldValidate(flowId, true));

    // FlowItems marked Incomplete for validation errors
    const updateItems = getFlowItemsForSelectedFlow(state);
    dispatch(updateFlowItemIsIncomplete(updateItems));

    // If SaveAs from Historical need to reset top level folder
    let resetFolder = false;
    if (isSaveAs) {
        const folderReducerKey = folderReducerKeyForType("W");
        const folder = findFolderById(state.folders[folderReducerKey], flow.FlowFolderId);

        resetFolder = !folder || folder.isHistorical;
    }

    if (flow.FlowFolderId <= 0 || resetFolder) {
        const folderId = findTopLevelFlowFolderForUser(state, state.session.userId);
        if (folderId > 0) {
            dispatch(updateAttribute("flows", flow.FlowId, "FlowFolderId", folderId));
            state = getState();
            flow = state.flows.byId[flowId];
        }
    }

    let originalFlowId;
    if (!overwrite && flowId != 0) {
        // We copy it to flowId = 0 locally, then let the save code act like it's saving a new flow
        originalFlowId = flowId;
        //Responsible for creating the new flow items with negative ids
        dispatch(flowCopyToIdZero(flowId, state.session.userId, copyOfferCodes, copyVariables));
        flowId = 0;

        dispatch({ type: "SET_SELECTED_FLOW", flowId, meta: { doNotBatch: true } });

        // Load in new info
        state = getState();
        flow = state.flows.byId[flowId];
        if (flow == null) {
            h.error("Error saving flow [2901b].");
            throw new Error("Error saving flow [2901b].");
        }
    }

    state = getState();
    let flowItems = getFlowItemsForSelectedFlow(state);

    if (flowItemId != -1) {
        /*Set isRunning right after getting the current state.  Lets ui respond immediately.
    Don't want to save isRunning, engine must set that itself or things will get stuck in a loop*/
        dispatch(updateAttribute("flowItems", flowItemId, "IsRunning", true));
    }

    const flowRelations = getFlowRelationsForSelectedFlow(state);
    const flowFilters = getFlowFiltersForSelectedFlow(state);
    const flowScripts = getFlowScriptsForSelectedFlow(state);
    const flowScriptsDBUI = getFlowScriptsDBUIForSelectedFlow(state);
    const flowSplits = getFlowSplitsForSelectedFlow(state);
    const flowEmpties = getFlowEmptiesForSelectedFlow(state);
    const flowCases = getFlowCasesForSelectedFlow(state);
    let flowMerges = getFlowMergesForSelectedFlow(state);
    let flowOfferMerges = getFlowOfferMergesForSelectedFlow(state);
    let flowReports = getFlowReportsForSelectedFlow(state);
    let flowSingleViews = getFlowSingleViewsForSelectedFlow(state);
    const flowModels = getFlowModelsForSelectedFlow(state);
    const flowMultiExports = getFlowMultiExportForSelectedFlow(state);
    const flowExports = getFlowExportsForSelectedFlow(state);
    const flowOutputs = getFlowOutputsForSelectedFlow(state);
    const flowExportReports = getFlowExportReportsForSelectedFlow(state);
    const flowCannedReports = getFlowCannedReportsForSelectedFlow(state);
    const flowFromClouds = getFlowFromCloudsForSelectedFlow(state);
    const flowToClouds = getFlowToCloudsForSelectedFlow(state);
    const flowDataLoads = getFlowDataLoadsForSelectedFlow(state);
    const flowDataLoadColumns = getFlowDataLoadColumnsForSelectedFlow(state);
    const flowSVDedupes = getFlowSVDedupesForSelectedFlow(state, false);
    const flowClientVariablesById = getFlowClientVariablesForSelectedFlow(state);
    const flowItemClientVariablesById = getFlowItemClientVariablesForSelectedFlow(state);
    const flowItemEndpoints = getFlowEndpointsForSelectedFlow(state);
    const flowItemOfferCodes = getFlowItemOfferCodesForSelectedFlow(state);
    //flow-export-distribution-platforms
    const flowExportDistributionPlatforms = getFlowExportDistributionPlatformsForSelectedFlow(state);
    //pivot-layout-parent-labels
    const flowRelationParentLabels = getFlowRelationParentLabelsForSelectedFlow(state);
    //flow-tool-offload
    const flowOffloads = getFlowOffloadsForSelectedFlow(state);
    const flowOffloadColumns = getFlowOffloadColumnsForSelectedFlow(state);
    const flowGuideSettings = getFlowGuideSettingsForSelectedFlow(state);
    const flowExportTemplateFields = getFlowExportTemplateFieldsForSelectedFlow(state);
    const flowExportPinterestTemplateFields = getFlowExportPinterestTemplateFieldsForSelectedFlow(state);
    const flowExportTikTokTemplateFields = getFlowExportTikTokTemplateFieldsForSelectedFlow(state);
    const flowExportTradeDeskTemplateFields = getFlowExportTradeDeskTemplateFieldsForSelectedFlow(state);
    const flowExportTaxonomyFileFields = getFlowExportTaxonomyFileFieldsForSelectedFlow(state);
    const flowExportFreewheelDriverFileFields = getFlowExportFreewheelDriverFileFieldsForSelectedFlow(state);
    const flowExportXandrDriverFields = getFlowExportXandrDriverFieldsForSelectedFlow(state);
    const flowSegmentSplits = getFlowSegmentSplitsForSelectedFlow(state);
    const flowSegmentSplitOffers = getFlowSegmentSplitOffersForSelectedFlow(state);

    //flow-control
    const flowExternalServices = getFlowExternalServicesForSelectedFlow(state);

    if (isSaveAs)
        flowExternalServices.forEach(x => {
            x.WFJobNumber = null;
            x.WFBillingDivCode = null;
            x.WFIFRSCode = null;
        });

    const flowExternalServiceParameters = getFlowExternalServiceParametersForSelectedFlow(state);
    const flowExternalServiceInputs = getFlowExternalServiceInputsForSelectedFlow(state);
    const flowExternalServiceHardcodes = getFlowExternalServiceHardcodesForSelectedFlow(state);
    const flowExternalServicesFormat = flowExternalServices.map(x => ({
        ...x,
        ServicesJSON: JSON.stringify(x.ServicesJSON),
        InputTableColumns:
            x.InputTableColumns && x.InputTableColumns.length > 0 ? JSON.stringify(x.InputTableColumns) : null,
    }));

    //flow-expression
    const flowExpressions = getFlowExpressionsForSelectedFlow(state);
    const flowExpressionConstraints = getFlowExpressionsConstraintsForSelectedFlow(state);

    const flowDescriptions = getFlowDescriptionsForSelectedFlow(state);

    // Check if any flowRelation would add Loops
    for (const flowRelation of flowRelations) {
        const loopDetected = isLoopDetected(state, flowRelation.ChildFlowItemId, flowRelation.ParentFlowItemId);
        if (loopDetected) {
            h.error("Error saving flow. [2902c]"); // loop detected
            throw new Error("Error saving flow [2902c].");
        }
    }

    const flowClientVariables = (Object.values(flowClientVariablesById): Array<FlowClientVariableD>);
    const flowClientVariablesServerFormat: Array<FlowClientVariable> = flowClientVariables
        .filter(x => x.VariableValue.ValueString || x.VariableValue.FieldId || !x.IsVisible)
        .map(x => ({
            Id: x.Id,
            VariableId: x.VariableId,
            FlowId: x.FlowId,
            VariableValueJSON: JSON.stringify(x.VariableValue),
            IsVisible: x.IsVisible,
        }));

    const flowItemClientVariables = (Object.values(flowItemClientVariablesById): Array<FlowItemClientVariableD>);
    const flowItemClientVariablesServerFormat: Array<FlowItemClientVariable> = flowItemClientVariables
        .filter(x => x.VariableValue.ValueString || x.VariableValue.FieldId)
        .map(x => ({
            FlowItemClientVariableId: x.FlowItemClientVariableId,
            VariableId: x.VariableId,
            FlowId: x.FlowId,
            FlowItemId: x.FlowItemId,
            ChildFlowItemId: x.ChildFlowItemId,
            VariableValueJSON: JSON.stringify(x.VariableValue),
        }));

    const flowSVDedupesServerFormat: Array<FlowSVDedupe> = flowSVDedupes.map(x => ({
        FlowSVDedupeId: x.FlowSVDedupeId,
        FlowItemId: x.FlowItemId,
        SVField: x.SVField,
        SortByFieldsJSON: JSON.stringify(x.SortByFields),
    }));

    const companyIdChanged = selectedCompany != 0 && flow.FlowCompanyId != selectedCompany;

    // Remove the HasResultTable from all FlowItems on Save As
    if (!overwrite) {
        if (companyIdChanged) {
            flow.FlowCompanyId = selectedCompany;
            if (flowReports.length > 0 && tableKey != 0) {
                flowReports = flowReports.map(x => ({ ...x, UniverseTableKey: tableKey }));
            }
        }
        flowItems = flowItems.map(x => ({
            ...x,
            HasResultTable: false,
            HasQATable: false,
            IsError: false,
            IsCancelled: false,
            IsRunning: 0,
            FlowItemStart: "",
            FlowItemEnd: "",
        }));
        flowMerges = flowMerges.map(x => ({ ...x, DupesQty: 0, UniquesQty: 0 }));
        flowOfferMerges = flowOfferMerges.map(x => ({ ...x, DupesQty: 0, UniquesQty: 0 }));
        flowSingleViews = flowSingleViews.map(x => ({
            ...x,
            FlowSingleViewQuantity: 0,
            Counted: false,
            IndividualQuantity: 0,
            HouseholdQuantity: 0,
            DedupedHouseholdQuantity: 0,
            DedupedIndividualQuantity: 0,
        }));
    } else {
        flowSingleViews = flowSingleViews.map(x => ({
            ...x,
            FlowSingleViewQuantity: x.IsSelected ? x.FlowSingleViewQuantity : 0,
            Counted: x.IsSelected ? x.Counted : false,
            IndividualQuantity: x.IsSelected ? x.IndividualQuantity : 0,
            HouseholdQuantity: x.IsSelected ? x.HouseholdQuantity : 0,
            DedupedIndividualQuantity: x.IsSelected ? x.DedupedIndividualQuantity : 0,
            DedupedHouseholdQuantity: x.IsSelected ? x.DedupedHouseholdQuantity : 0,
        }));
    }

    // If save or execute flow, remove all SelectedScript
    if (flowItemId == -1 || processEntireFlow) {
        for (const item of flowScripts) {
            item.SelectedScript = "";
        }

        for (const item of flowScriptsDBUI) {
            item.SelectedScript = "";
        }
    }

    dispatch(updateAttribute("flows", flow.FlowId, "isSaving", 1));

    if (flow.FlowBaseType > 0) {
        flowItems = flowItems.map(x => ({
            ...x,
            FlowItemName:
                x.FlowItemType == "export" || x.FlowItemType == "multiexport" ? flow.FlowDescription : x.FlowItemName,
        }));
    }

    const dataToSave = {
        Flow: flow,
        FlowItems: flowItems,
        FlowRelations: flowRelations,
        FlowFilters: flowFilters,
        FlowScripts: flowScripts,
        FlowScriptsDBUI: flowScriptsDBUI,
        FlowSplits: flowSplits,
        FlowEmpties: flowEmpties,
        FlowMerges: flowMerges,
        FlowOfferMerges: flowOfferMerges,
        FlowCases: flowCases,
        FlowReports: flowReports,
        FlowModels: flowModels,
        FlowMultiExports: flowMultiExports,
        FlowExports: flowExports,
        FlowOutputs: flowOutputs,
        FlowExportReports: flowExportReports,
        FlowCannedReports: flowCannedReports,
        FlowFromClouds: flowFromClouds,
        FlowToClouds: flowToClouds,
        FlowClientVariables: flowClientVariablesServerFormat,
        FlowItemClientVariables: flowItemClientVariablesServerFormat,
        FlowSVDedupes: flowSVDedupesServerFormat,
        FlowItemEndpoints: flowItemEndpoints,
        FlowItemOfferCodes: flowItemOfferCodes,
        FlowSingleViews: flowSingleViews,
        FlowDataLoads: flowDataLoads,
        FlowDataLoadColumns: flowDataLoadColumns,
        //flow-export-distribution-platforms
        FlowExportDistributionPlatforms: flowExportDistributionPlatforms,
        //pivot-layout-parent-labels
        FlowRelationParentLabels: flowRelationParentLabels,
        //flow-tool-offload
        FlowOffloads: flowOffloads,
        FlowOffloadColumns: flowOffloadColumns,
        FlowGuideSettings: flowGuideSettings,
        FlowExportTemplateFields: flowExportTemplateFields,
        FlowExportPinterestTemplateFields: flowExportPinterestTemplateFields,
        FlowExportTikTokTemplateFields: flowExportTikTokTemplateFields,
        FlowExportTradeDeskTemplateFields: flowExportTradeDeskTemplateFields,
        FlowExportTaxonomyFileFields: flowExportTaxonomyFileFields,
        FlowExportFreewheelDriverFileFields: flowExportFreewheelDriverFileFields,
        FlowExportXandrDriverFields: flowExportXandrDriverFields,
        SegmentSplits: flowSegmentSplits,
        SegmentSplitOffers: flowSegmentSplitOffers,
        //flow-control
        FlowExternalServices: flowExternalServicesFormat,
        FlowExternalServiceParameters: flowExternalServiceParameters,
        FlowExternalServiceInputs: flowExternalServiceInputs,
        FlowExternalServiceHardcodes: flowExternalServiceHardcodes,
        FlowExpressions: flowExpressions,
        FlowDescriptions: flowDescriptions,
        FlowExpressionConstraints: flowExpressionConstraints,
        OriginalFlowId: originalFlowId,
    };

    // If we made a copy of an existing flow, then revert it
    if (!overwrite && originalFlowId != null) {
        dispatch(requestFlows(originalFlowId, "merge-overwrite"));
    }

    let body = await gzip(JSON.stringify(dataToSave));
    return request(
        "/flows/SaveFlowCompressed",
        {
            credentials: "same-origin",
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body,
        },
        dispatch
    )
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            if (!data.result) {
                if (flow != null) {
                    dispatch(updateAttribute("flows", flow.FlowId, "isSaving", 0));
                }
                if (flowItemId != -1) {
                    /*Set isRunning right after getting the current state.  Lets ui respond immediately.
                Don't want to save isRunning, engine must set that itself or things will get stuck in a loop*/
                    dispatch(updateAttribute("flowItems", flowItemId, "IsRunning", false));
                }
                if (data.error) {
                    h.error(data.error);
                    dispatch(decAjaxCount());
                    throw new Error(data.error);
                } else {
                    throw new Error("Error saving flow.");
                }
            }

            dispatch(notifyGreen("Flow saved successfully."));

            if (data.flowDateCreated && flow != null) {
                dispatch(updateAttribute("flows", flow.FlowId, "FlowDateCreated", data.flowDateCreated));
            }

            for (const row of prefixesAndEntities) {
                const prefix = row.actionPrefix;
                const entity = row.stateKey;
                const idChanges = data.idChanges[ucFirst(entity)];
                if (idChanges != null) {
                    dispatch({
                        type: `${prefix}_ID_CHANGE`,
                        idChanges,
                    });
                }
            }

            if (companyIdChanged) {
                top.RDX.CAPageChangeCompanyNoUnsavedCheck(selectedCompany, false);
            }

            dispatch(updateFlowShouldValidate(flowId, false));
            dispatch(changeFlowUrlAfterIdChanges(data.idChanges));
            dispatch(setHasUnsavedChanges(false));

            if (processEntireFlow) {
                // #NANI-47710 The flow id is being refreshed for the calculate process on simple flow right after save
                let stateToProcess = getState();
                let flowIdToProcess: number = stateToProcess.selected.flow;
                if (isSimpleFlowDeploy) {
                    flowIdToProcess = flowId;
                }
                dispatch(requestFlowProcess(flowIdToProcess, isSimpleFlowDeploy));
            } else if (flowItemId > -1) {
                dispatch(requestFlowItemProcess(flowItemId, isRunChildren));
            } else {
                const flowinsightsTmp = flowItems.filter(
                    x => x.FlowItemId == flowItemId && x.FlowItemType == "insights"
                );
                let flowinsightId = -1;
                if (flowinsightsTmp != null && flowinsightsTmp.length > 0) {
                    const parentIds = flowRelations
                        .filter(x => x.ChildFlowItemId == flowItemId)
                        .map(x => x.ParentFlowItemId);
                    const parentFilters = flowItems.filter(
                        x => parentIds.includes(x.FlowItemId) && x.FlowItemQty != null
                    );
                    if (parentFilters != null && parentFilters.length > 0) {
                        if (data.idChanges && data.idChanges.FlowItems.length > 0) {
                            for (let val of data.idChanges.FlowItems) {
                                if (val[0] == flowItemId) {
                                    flowinsightId = val[1];
                                }
                            }
                        }
                        if (flowinsightId > -1) {
                            dispatch(requestFlowItemProcess(flowinsightId, false));
                        }
                    }
                }
            }

            if (deployFlowExportAfterSave && !isSimpleFlowDeploy) {
                dispatch(deployFlowExport(flowId));
            }

            // Sometimes someone tried to do something, like, switch flows or clear flows, but they
            // were prompted to save first. In this case, this will run their original intent after saving.
            // If not, does nothing...
            dispatch(lossOfWorkRunQueuedAction());
            dispatch(decAjaxCount());
        })
        .catch(error => {
            if (flow != null) {
                dispatch(updateAttribute("flows", flow.FlowId, "isSaving", 0));
            }
            dispatch(decAjaxCount());
            dispatch(updateFlowShouldValidate(flowId, false));
            h.error("Error saving flow.", error);
            throw new Error("Error saving flow.");
        });
};

export const setFlowUserConfig = (flowUserConfig: FlowUserConfig) => (dispatch: Dispatch, getState: GetState) => {
    let state = getState();
    let newVal: { [flowId: number]: FlowUserConfig } = clone(state.vars["flowUserConfigsCyclone"] || {});
    newVal[flowUserConfig.FlowId] = flowUserConfig;

    dispatch({ type: `SET_FLOW_USER_CONFIGS`, newVal });
};

// This must be idempotent.
export const flowEnsureItemDetailsExist = () => (dispatch: Dispatch, getState: GetState) => {
    let state = getState();

    const flowItems = getFlowItemsForSelectedFlow(state);
    const flowFiltersByFlowItemId = getFlowFiltersByFlowItemId(state);
    const flowScriptsByFlowItemId = getFlowScriptsByFlowItemId(state);
    const flowScriptsDBUIByFlowItemId = getFlowScriptsDBUIByFlowItemId(state);
    const flowMultiExportsByFlowItemId = getFlowMultiExportsByFlowItemId(state);
    const flowExportsByFlowItemId = getFlowExportsByFlowItemId(state);
    const flowOutputsByFlowItemId = getFlowOutputsByFlowItemId(state);
    const flowFromCloudsByFlowItemId = getFlowFromCloudsByFlowItemId(state);
    const flowToCloudsByFlowItemId = getFlowToCloudsByFlowItemId(state);
    const flowDataLoadsByFlowItemId = getFlowDataLoadsByFlowItemId(state);
    const flowReportsByFlowItemId = getFlowReportsByFlowItemId(state);
    const flowModelsByFlowItemId = getFlowModelsByFlowItemId(state);
    const flowSVDedupesByFlowItemId = getFlowSVDedupesByFlowItemId(state);
    const flowSingleViewsByFlowItemId = getFlowSingleViewsByFlowItemId(state);
    const flowOffloadByFlowItemId = getFlowOffloadsByFlowItemId(state);
    const flowExternalServicesByFlowItemId = getFlowExternalServicesByFlowItemId(state);
    const flowExpressionsByFlowItemId = getFlowExpressionsByFlowItemId(state);
    const flowCannedReportsByFlowItemId = getFlowCannedReportsByFlowItemId(state);
    const flowDescriptionsByFlowItemId = getFlowDescriptionsByFlowItemId(state);
    const flowItemSubEntities = prefixesAndEntities.filter(x => x.flowItemType != "" && x.flowItemType != null); // Split, Filter, Merge, etc...

    for (const flowItem of flowItems) {
        // Add rows if needed - Filter
        if (flowItem.FlowItemType.toLowerCase() == "filter") {
            // Look for a filter entry - if one is missing, add it
            const existingFilter = flowFiltersByFlowItemId[flowItem.FlowItemId];
            if (existingFilter == null) {
                dispatch(newFlowFilter(flowItem.FlowItemId));
            }
        }
        if (flowItem.FlowItemType.toLowerCase() == "script") {
            // Look for a filter entry - if one is missing, add it
            const existingScript = flowScriptsByFlowItemId[flowItem.FlowItemId];
            if (existingScript == null) {
                dispatch(newFlowScript(flowItem.FlowItemId));
            }
        }
        if (flowItem.FlowItemType.toLowerCase() == "scriptdbui") {
            // Look for a filter entry - if one is missing, add it
            const existingScriptDBUI = flowScriptsDBUIByFlowItemId[flowItem.FlowItemId];
            if (existingScriptDBUI == null) {
                dispatch(newFlowScriptDBUI(flowItem.FlowItemId));
            }
        }
        if (flowItem.FlowItemType.toLowerCase() == "singleview") {
            // Look for a filter entry - if one is missing, add it
            const existingSingleView = flowSingleViewsByFlowItemId[flowItem.FlowItemId];
            if (existingSingleView == null) {
                // dispatch(newFlowSingleView(flowItem.FlowItemId));
            }
        }
        if (flowItem.FlowItemType.toLowerCase() == "export") {
            const existingExport = flowExportsByFlowItemId[flowItem.FlowItemId];
            if (existingExport == null) {
                dispatch(newFlowExport(flowItem.FlowItemId));
            }
        }
        if (flowItem.FlowItemType.toLowerCase() == "multiexport") {
            const existingMultiExport = flowMultiExportsByFlowItemId[flowItem.FlowItemId];
            if (existingMultiExport == null) {
                dispatch(newFlowMultiExport(flowItem.FlowItemId));
            }
        }
        if (flowItem.FlowItemType.toLowerCase() == "output") {
            const existingOutput = flowOutputsByFlowItemId[flowItem.FlowItemId];
            if (existingOutput == null) {
                dispatch(newFlowOutput(flowItem.FlowItemId));
            }
        }
        if (flowItem.FlowItemType.toLowerCase() == "fromcloud") {
            const existingFromCloud = flowFromCloudsByFlowItemId[flowItem.FlowItemId];
            if (existingFromCloud == null) {
                dispatch(newFlowFromCloud(flowItem.FlowItemId));
            }
        }
        if (flowItem.FlowItemType.toLowerCase() == "tocloud") {
            const existingToCloud = flowToCloudsByFlowItemId[flowItem.FlowItemId];
            if (existingToCloud == null) {
                dispatch(newFlowToCloud(flowItem.FlowItemId));
            }
        }
        if (flowItem.FlowItemType.toLowerCase() == "report") {
            const existingReport = flowReportsByFlowItemId[flowItem.FlowItemId];
            if (existingReport == null) {
                dispatch(newFlowReport(flowItem.FlowItemId));
            }
        }
        if (flowItem.FlowItemType.toLowerCase() == "model") {
            const existingModel = flowModelsByFlowItemId[flowItem.FlowItemId];
            if (existingModel == null) {
                dispatch(newFlowModel(flowItem.FlowItemId));
            }
        }
        // if (flowItem.FlowItemType.toLowerCase() == "exportreport") {
        //     const existingExportReport = flowExportReportsByFlowItemId[flowItem.FlowItemId];
        //     if (existingExportReport == null) {
        //         dispatch(newFlowExportReport(flowItem.FlowItemId, 0));
        //     }
        // }
        if (flowItem.FlowItemType.toLowerCase() == "svdedupe") {
            const existingSVDedupe = flowSVDedupesByFlowItemId[flowItem.FlowItemId];
            if (existingSVDedupe == null) {
                dispatch(newFlowSVDedupe(flowItem.FlowItemId));
            }
        }
        if (flowItem.FlowItemType.toLowerCase() == "dataload") {
            const existingDataLoad = flowDataLoadsByFlowItemId[flowItem.FlowItemId];
            if (existingDataLoad == null) {
                dispatch(newFlowDataLoad(flowItem.FlowItemId));
            }
        }

        if (flowItem.FlowItemType.toLowerCase() == "offload") {
            const existingOffload = flowOffloadByFlowItemId[flowItem.FlowItemId];
            if (!existingOffload) {
                dispatch(newFlowOffload(flowItem.FlowItemId));
            }
        }

        if (flowItem.FlowItemType.toLowerCase() == "flowcontrol") {
            const existingExternalService = flowExternalServicesByFlowItemId[flowItem.FlowItemId];
            if (!existingExternalService) {
                dispatch(newFlowExternalService(flowItem.FlowItemId));
            }
        }

        if (flowItem.FlowItemType.toLowerCase() == "flowexpression") {
            const existingFlowExpression = flowExpressionsByFlowItemId[flowItem.FlowItemId];
            if (!existingFlowExpression) {
                dispatch(newFlowExpression(flowItem.FlowItemId));
            }
        }

        if (flowItem.FlowItemType.toLowerCase() == "cannedreport") {
            const existingFlowCannedReport = flowCannedReportsByFlowItemId[flowItem.FlowItemId];
            if (existingFlowCannedReport == null) {
                dispatch(newFlowCannedReport(flowItem.FlowItemId));
            }
        }

        if (flowItem.FlowItemType.toLowerCase() == "flowdescription") {
            const existingFlowDescription = flowDescriptionsByFlowItemId[flowItem.FlowItemId];
            if (existingFlowDescription == null) {
                dispatch(newFlowDescription(flowItem.FlowItemId));
            }
        }

        // Add rows if needed - Split - 0 rows is ok, do nothing.  (Should we generalize all types somehow?)

        // Delete things that aren't my type -- generalized to all type
        // Example:  If FlowItem1234 is type FILTER, then any SPLIT rows with FlowItemId=1234 should be deleted.
        const subEntitiesDeleteExceptionTypes = [
            "FlowItemClientVariableD",
            "FlowItemEndpoint",
            "FlowItemOfferCode",
            "svDedupe",
            "offerMerge",
            "FlowDataLoadColumn",
            "ScriptResult",
            //flow-export-distribution-platforms
            "FlowExportDistributionPlatform",
            // flow-tool-offload
            "FlowOffloadColumn",
            "FlowExportTemplateFields",
            "FlowExportPinterestTemplateFields",
            "FlowExportTikTokTemplateFields",
            "FlowExportTradeDeskTemplateFields",
            "FlowExportTaxonomyFileFields",
            "FlowExportFreewheelDriverFileFields",
            "FlowExportXanderDriverFields",
            "FlowSegmentSplit",
            "FlowSegmentSplitOffer",
            "FlowExternalServiceParameter",
            "FlowExpression",
            "FlowExpressionConstraint",
            "FlowDescription",
            "FlowExternalServiceInput",
            "FlowExternalServiceHardcode",
        ];

        const otherSubEntities = flowItemSubEntities.filter(
            x => !subEntitiesDeleteExceptionTypes.includes(x.flowItemType) && x.flowItemType != flowItem.FlowItemType
        );

        for (const entityRow of otherSubEntities) {
            const { stateKey, id: idName, deleteAc } = entityRow;

            const theseThings: Array<any> = Object.values(state[stateKey].byId);
            const theseIdsToDelete = theseThings.filter(x => x.FlowItemId == flowItem.FlowItemId).map(x => x[idName]);

            for (const id of theseIdsToDelete) {
                dispatch(deleteAc(id));
            }
        }

        state = getState();
    }
};

export const flowRemoveUnsavedItems = () => (dispatch: Dispatch, getState: GetState) => {
    let state = getState();

    const flowItems = getFlowItemsForSelectedFlow(state);
    const flowRelations = getFlowRelationsForSelectedFlow(state);

    const flowItemSubEntities = prefixesAndEntities.filter(x => x.flowItemType != "" && x.flowItemType != null); // Split, Filter, Merge, etc...

    for (const flowItem of flowItems) {
        if (flowItem.FlowId > 0) {
            if (flowItem.FlowItemId < 0) {
                dispatch(deleteFlowItem(flowItem.FlowItemId));

                const otherSubEntities = flowItemSubEntities.filter(x => x.flowItemType != flowItem.FlowItemType);
                for (const entityRow of otherSubEntities) {
                    const { stateKey, id: idName, deleteAc } = entityRow;

                    const theseThings: Array<any> = Object.values(state[stateKey].byId);
                    const theseIdsToDelete = theseThings.filter(x => x.FlowItemId < 0).map(x => x[idName]);
                    for (const id of theseIdsToDelete) {
                        dispatch(deleteAc(id));
                    }
                }
            }

            const relationIdsToDelete = flowRelations
                .filter(
                    x =>
                        x.FlowRelationId < 0 &&
                        (x.ParentFlowItemId == flowItem.FlowItemId || x.ChildFlowItemId == flowItem.FlowItemId)
                )
                .map(x => x.FlowRelationId);

            for (const flowRelationId of relationIdsToDelete) {
                dispatch(deleteFlowRelation(flowRelationId));
            }
        }
        state = getState();
    }

    const flowId = state.selected.flow;
    const flowEndpoints = getFlowEndpointsForSelectedFlow(state);
    const endpointsToDelete = flowEndpoints.filter(x => x.FlowId == flowId && x.EndpointId == 0).map(x => x.Id);
    for (const endpointId of endpointsToDelete) {
        dispatch(deleteFlowItemEndpoint(endpointId));
    }

    const flowClientVariables = getFlowClientVariablesForSelectedFlow(state);
    const variablesToDelete = flowClientVariables.filter(x => x.FlowId == flowId && x.Id < 0).map(x => x.Id);
    for (const variableId of variablesToDelete) {
        dispatch(deleteFlowClientVariable(variableId));
    }

    const flowItemOffers = getFlowItemOfferCodesForSelectedFlow(state);
    const offersToDelete = flowItemOffers.filter(x => x.FlowItemOfferCodeId < 0).map(x => x.FlowItemOfferCodeId);
    for (const offerId of offersToDelete) {
        dispatch(deleteFlowItemOfferCode(offerId));
    }

    dispatch(setHasUnsavedChanges(false));
};

export const copySelectedNodesToClipboard = () => (dispatch: Dispatch, getState: GetState) => {
    let state = getState();
    const flowItemTypeLookup = getFlowItemTypeLookupForSelectedFlow(state);
    const selectedNodes = state.selected.flowItems;
    const selectedNodeLinks = state.selected.flowRelations;

    const nodesToCopy = selectedNodes.filter(x => flowItemTypeLookup[x] != null);
    const showDisclaimer = false;

    const excludedNodes = selectedNodes.filter(x => !nodesToCopy.includes(x));
    const excludedTypes = uniqArray(
        excludedNodes
            .map(x => flowItemTypeLookup[x] || "")
            .filter(x => x != "")
            .map(x => titleCase(renderFlowItemType(x)))
    );
    dispatch(updateClipboardNodes(nodesToCopy));
    dispatch(updateClipboardRelations(selectedNodeLinks));
    const num = nodesToCopy.length;
    let message = num == 0 ? "Cleared clipboard." : `Copied ${num} flow item${num > 1 ? "s" : ""} to clipboard.`;
    if (showDisclaimer) {
        message = (
            <div id="message-id">
                {message}
                <br />
                <em>
                    (Flow Item Type
                    {excludedNodes.length > 1 ? "s" : ""} <strong>{excludedTypes.join(", ")}</strong> cannot be copy
                    pasted.)
                </em>
            </div>
        );
    }
    dispatch(notifyBlue(message));
};
// For the selected flow, set all flowitems to be not renaming.
export const clearAllRenamingFlowItems = () => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const flowItems = getFlowItemsForSelectedFlow(state);
    const renamingFlowItems = flowItems.filter(x => x.isRenaming).map(x => x.FlowItemId);
    if (renamingFlowItems.length == 0) {
        return;
    }
    for (const id of renamingFlowItems) {
        dispatch(updateAttribute("flowItems", id, "isRenaming", false));
    }
};

export const commitAllFlowItemRenames = () => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const flowItems = getFlowItemsForSelectedFlow(state);
    const renamingFlowItems = flowItems.filter(x => x.isRenaming).map(x => x.FlowItemId);
    if (renamingFlowItems.length == 0) {
        return;
    }
    for (const id of renamingFlowItems) {
        const newName = state.flowVars.flowItemRenamingNames[id];
        const updatesToMake: { [string]: any } = { isRenaming: false };
        if (newName != null && newName != "") {
            updatesToMake.FlowItemName = newName;
        }
        dispatch(updateMultipleAttribute("flowItems", id, updatesToMake, true));
    }
};

// For the selected flow, save the new renamed FlowItem name
export const saveAllRenamingFlowAndFlowItems =
    (flowId: number, model: any /* FlowDiagramModel: Make a jest type for me */) =>
    (dispatch: Dispatch, getState: GetState) => {
        const nodes = (Object.values(model.getNodes()): Array<any>); // Array<FlowNodeModel> : Make a jest type for me
        for (const node of nodes.filter(x => x.isRenaming == true)) {
            const flowItem = node.flowItem;
            if (flowItem) {
                if (node.newName != node.name) {
                    let itemName = node.newName;

                    if (flowItem.FlowItemType == "export") {
                        // #6800 - Export Nodes cannot have a pipe in the item name due to Freewheel Driver File
                        itemName = itemName.replace("|", "");
                    }

                    dispatch(updateAttribute("flowItems", flowItem.FlowItemId, "FlowItemName", itemName));
                }
                dispatch(updateAttribute("flowItems", flowItem.FlowItemId, "isRenaming", false));
            }
        }

        // Clear isRenaming if set - not clear why
        const state = getState();
        const flow = state.flows.byId[flowId];
        if (flow != null && flow.isRenaming) {
            dispatch(updateAttribute("flows", flowId, "isRenaming", false));
        }
    };

///////////////////// DYNAMIC ACTIONS BELOW ////////////////////////////////////
// See "prefixesAndEntities" at the bottom of this file
const _mergeBehaviorToVerb = (mergeBehavior: MergeBehavior): string => {
    if (mergeBehavior == "replace") {
        return "REPLACE";
    }
    if (mergeBehavior == "merge-overwrite") {
        return "MERGEOVERWRITE";
    }
    if (mergeBehavior == "merge") {
        return "MERGE";
    }
    return "MERGE";
};

const _entityPluralNameToPrefix = (entityPluralName: EntityPluralName) => {
    const rowMatches = prefixesAndEntities.filter(x => x.stateKey == entityPluralName);
    if (rowMatches.length == 0) {
        h.error(`entityPluralNameToPrefix:  Couldn't find an entity named [${entityPluralName}]`);
        return "";
    }
    const row = rowMatches[0];
    const prefix = row.actionPrefix;
    return prefix;
};

const _entityPluralNameToIdColumn = (entityPluralName: EntityPluralName) => {
    const rowMatches = prefixesAndEntities.filter(x => x.stateKey == entityPluralName);
    if (rowMatches.length == 0) {
        h.error(`entityPluralNameToColumn:  Couldn't find an entity named [${entityPluralName}]`);
        return "Id";
    }
    const row = rowMatches[0];
    const idColumn = row.id;
    if (idColumn == null) {
        h.error(`entityPluralNameToColumn:  Couldn't find an ID column for an entity named [${entityPluralName}]`);
        return "Id";
    }
    return idColumn;
};

export const clearFlowOutputOfferMerges = (flowItemId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const flowOfferMergesArray = getFlowOfferMergesArray(state);
    const flowItemOfferMerges = flowOfferMergesArray.filter(x => x.FlowItemId == flowItemId);
    for (const offerMerge of flowItemOfferMerges) {
        dispatch(deleteFlowOfferMerge(offerMerge.FlowOfferMergeId, false));
    }
};

// Finds the next "new" id.  Ids for new items will be -1, then -2, then -3, then -4, etc.
const _makeNewId = (state: any, entityPluralName: EntityPluralName): number => {
    if (state[entityPluralName] == null || state[entityPluralName].byId == null) {
        h.errorSilent(`makeNewId: Couldn't find ${entityPluralName} byId in state.`);
        return 0;
    }

    const ids: Array<number> = Object.keys(state[entityPluralName].byId).map(x => parseInt(x, 10));
    return Math.min.apply(null, ids.concat(0)) - 1;
};
///////////////////// DYNAMIC ACTIONS BELOW ////////////////////////////////////
// These attributes don't cause unsaved changes, removed "x", "y" for #2560
const attributesDontCauseUnsaved = [
    "isSaving",
    "hasUnsavedChanges",
    "FlowFolderId",
    "isRenaming",
    "IsRunning",
    "IsValid",
    "FlowFilterCriteriaText",
    "width",
    "height",
    "flowItemsRunning",
    "IsError",
    "IsCancelled",
    "shouldValidate",
    "isLoadingFlowData",
    "WFJobClientKey",
    "FlowBaseType",
];

// Use "updateImmediately" when the user is typing, and each keystroke sends a redux update.
// Otherwise, we shouldn't use it.  Actions will be batched and subscription update notifications
// will be debounced at ~8ms.
export const updateMultipleAttribute =
    (
        entityPluralName: EntityPluralName,
        id: number,
        updates: { [string]: any },
        updateImmediately: boolean = false,
        triggerLossOfWork: boolean = true
    ): ThunkAction =>
    (dispatch: Dispatch, getState: GetState) => {
        const prefix = _entityPluralNameToPrefix(entityPluralName);

        // updates = {x: 100, y: 200} --> actionUpdates = [ {attribute: x, value: 100}, {attribute: y, value: 200} ]
        const actionUpdates = Object.entries(updates).map(x => ({ attribute: x[0], value: x[1] }));
        dispatch({
            type: `${prefix}_CHANGE_MULTIPLE_ATTRIBUTE`,
            id,
            updates: actionUpdates,
            meta: { doNotBatch: updateImmediately },
        });

        ////// Smart actions after the actual update //////

        // If we're changing a FlowItem's type, then make sure the subitems exist.
        if (Object.keys(updates).includes("FlowItemType")) {
            dispatch(flowEnsureItemDetailsExist());
        }

        const state = getState();
        // Updating a flowEmpty's FlowEmptyCriteria automatically changes the associated FlowItem's FlowItemName.
        if (entityPluralName == "flowEmpties" && Object.keys(updates).includes("FlowEmptyCriteria")) {
            const flowEmpty = state[entityPluralName].byId[id];
            if (flowEmpty != null) {
                dispatch(updateAttribute("flowItems", flowEmpty.FlowItemId, "FlowItemName", updates.FlowEmptyCriteria));
            }
        }
        // Set unsaved changes, unless every attribute changed doesn't cause unsaved changes
        // This can be optimized, since a lot of times we end up doing another round trip :(
        if (!Object.keys(updates).every(x => attributesDontCauseUnsaved.includes(x)) && triggerLossOfWork) {
            dispatch(setHasUnsavedChanges());
        }

        if (
            entityPluralName == "flowItems" &&
            Object.keys(updates).includes("FlowItemName") &&
            state.flowItems &&
            state.flowItems.byId[id] &&
            state.flowItems.byId[id].FlowItemType == "multiexport"
        ) {
            const flowRelations = getFlowRelationsArray(state);
            const childRelations = flowRelations.filter(x => x.ParentFlowItemId == id && x.ChildFlowItemId != 0);

            for (const childRelation of childRelations) {
                dispatch(
                    updateAttribute(
                        entityPluralName,
                        childRelation.ChildFlowItemId,
                        "FlowItemName",
                        updates.FlowItemName,
                        updateImmediately,
                        triggerLossOfWork
                    )
                );
            }
        }
    };

// Use "updateImmediately" when the user is typing, and each keystroke sends a redux update.
// Otherwise, we shouldn't use it.  Actions will be batched and subscription update notifications
// will be debounced at ~8ms.
export const updateAttribute =
    (
        entityPluralName: EntityPluralName,
        id: number,
        attribute: string,
        value: any,
        updateImmediately: boolean = false,
        triggerLossOfWork: boolean = true
    ): ThunkAction =>
    (dispatch: Dispatch) => {
        if (top.AE) {
            top.AE.Push.consoleLogWithGroupCollapsed(
                {
                    entityPluralName,
                    id,
                    attribute,
                    value,
                    updateImmediately,
                },
                "Flow Update Attribute"
            );
        }
        dispatch(
            updateMultipleAttribute(entityPluralName, id, { [attribute]: value }, updateImmediately, triggerLossOfWork)
        );
    };

export const addThing =
    (
        entityPluralName: EntityPluralName,
        thing: FlowThings,
        idCallback?: number => any,
        isSetUnsaved?: boolean = true
    ) =>
    (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const id = _makeNewId(state, entityPluralName);
        const prefix = _entityPluralNameToPrefix(entityPluralName);
        const idColumn = _entityPluralNameToIdColumn(entityPluralName);
        thing[idColumn] = id;
        dispatch({
            type: `${prefix}_ADD`,
            id,
            thing,
        });
        if (idCallback != null && typeof idCallback == "function") {
            idCallback(id);
        }

        if (isSetUnsaved) {
            dispatch(setHasUnsavedChanges());
        }
    };

export const clearAllFlowData = () => (dispatch: Dispatch) => {
    const mergeBehavior = "replace";
    for (const row of prefixesAndEntities) {
        const prefix = row.actionPrefix;
        dispatch({
            type: `${prefix}_${_mergeBehaviorToVerb(mergeBehavior)}_LIST`,
            things: [],
        });
        dispatch({
            type: `${prefix}_${_mergeBehaviorToVerb(mergeBehavior)}_LIST`,
            things: [],
        });
    }
};

// Not an action creator.  Helper function for requestFlow/requestFlows.
const loadFlowData = (data, dispatch, getState, mergeBehavior, getAllFlowEntities: boolean = true) => {
    data = parseJsonInsideFlowData(data);
    const normalizedData = normalizeFlowData(data);
    let flowClientVariableIdsToDelete: ?Array<number> = null;
    for (const row of prefixesAndEntities) {
        const prefix = row.actionPrefix;
        const entity = row.stateKey;
        if (entity == "flows" || getAllFlowEntities == true) {
            dispatch({
                type: `${prefix}_${_mergeBehaviorToVerb(mergeBehavior)}_LIST`,
                things: normalizedData.entities[entity] || [],
            });
        }
    }

    if (normalizedData.entities.flowClientVariables != null) {
        const flowClientVariablesFromServer: Array<FlowClientVariableD> = Object.values(
            normalizedData.entities.flowClientVariables
        );
        let flowIdsSeen: Array<number> = flowClientVariablesFromServer.map(x => x.FlowId);
        flowIdsSeen = [...new Set(flowIdsSeen)]; // uniq
        const state = getState();

        const flowClientVariablesInState: Array<FlowClientVariableD> = Object.values(state.flowClientVariables.byId);
        flowClientVariableIdsToDelete = flowClientVariablesInState
            .filter(x => flowIdsSeen.includes(x.FlowId))
            .map(x => x.Id);
    }

    // Remove existing ScriptResults from redux. Have to check both from server and state to make it work correctly.
    const state = getState();

    const flowScriptResultsInState: Array<FlowScriptResult> = Object.values(state.flowScriptResults.byId);
    if (flowScriptResultsInState.length > 0 && normalizedData.entities.flowScriptResults != null) {
        const flowScriptResultFromServer: Array<FlowScriptResult> = Object.values(
            normalizedData.entities.flowScriptResults
        );
        const newIds = flowScriptResultFromServer.map(x => x.FlowScriptResultId);
        const flowItemIds = flowScriptResultFromServer.map(x => x.FlowItemId);

        const flowScriptResultsToDelete = flowScriptResultsInState
            .filter(x => flowItemIds.includes(x.FlowItemId))
            .map(x => x.FlowScriptResultId)
            .filter(x => !newIds.includes(x));

        for (const id of flowScriptResultsToDelete) {
            dispatch(deleteFlowScriptResult(id));
            dispatch(setHasUnsavedChanges(false));
        }
    }

    if (normalizedData.entities.flowItems) {
        const flowItemsFromServer: Array<FlowItem> = Object.values(normalizedData.entities.flowItems);
        const flowItemsIdsAffected = flowItemsFromServer
            .filter(x => x.FlowItemType == "dataload")
            .map(x => x.FlowItemId);

        const flowDataLoadColumnsInState: Array<FlowDataLoadColumn> = Object.values(state.flowDataLoadColumns.byId);
        const affectedDataLoadColumns = flowDataLoadColumnsInState.filter(x =>
            flowItemsIdsAffected.includes(x.FlowItemId)
        );

        if (affectedDataLoadColumns.length > 0) {
            const flowDataLoadColumnsFromServer: Array<FlowDataLoadColumn> = normalizedData.entities.flowDataLoadColumns
                ? Object.values(normalizedData.entities.flowDataLoadColumns)
                : [];
            const dataLoadColumnIdsFromServer = flowDataLoadColumnsFromServer.map(x => x.FlowDataLoadColumnId);
            const dataLoadColumnsToDelete = affectedDataLoadColumns.filter(
                x => !dataLoadColumnIdsFromServer.includes(x.FlowDataLoadColumnId)
            );
            for (const dataLoadColumn of dataLoadColumnsToDelete) {
                dispatch(deleteFlowDataLoadColumn(dataLoadColumn.FlowDataLoadColumnId));
            }
            dispatch(setHasUnsavedChanges(false));
        }
    }

    // To go directly to audiences when a flow is a Simple Flows
    if (
        data.flowItems &&
        data.flows &&
        data.flows.length == 1 &&
        data.flows[0].FlowBaseType &&
        data.flows[0].FlowBaseType != 0
    ) {
        if (state.vars.previousUrlIdForSimpleFlow != data.flows[0].FlowId) {
            const flowItemId = data.flowItems.filter(
                x => x.FlowId == data.flows[0].FlowId && (x.FlowItemType == "filter" || x.FlowItemType == "case")
            );
            dispatch(setSelectedFlowItemId(flowItemId[0].FlowItemId)); //Changes faster the selected flowItemId, avoiding errors
            dispatch(UrlIdForSimpleFlow(data.flows[0].FlowId)); // If the ID is the same don't reload, to avoid loops
        }
    }
    dispatch(updateFlowList(normalizedData, "replace", flowClientVariableIdsToDelete));
    dispatch(decAjaxCount());
};

export const loadSingleViewDataVars = (destinations: Array<Destination>) => (dispatch: Dispatch) => {
    dispatch({
        type: "LOAD_SINGLE_VIEW_DESTINATIONS",
        destinations,
    });
};
export const loadDataloadPartners = (destinations: Array<Destination>) => (dispatch: Dispatch) => {
    dispatch({
        type: "LOAD_DATALOAD_DESTINATIONS",
        destinations,
    });
};

// requestFlowItem(flowItemId):  Get the information about one flow item from the database.
export const requestFlowItem =
    (flowItemId: number): ThunkAction =>
    (dispatch: Dispatch) => {
        let url = "/flows/GetFlowItem/" + flowItemId;
        dispatch(incAjaxCount());
        return fetch(url, { credentials: "same-origin" })
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                dispatch(decAjaxCount());
                const normalizedData = normalizeFlowData({ flowItems: data });
                dispatch({
                    type: "FLOW_ITEM_MERGE_LIST",
                    things: normalizedData.entities.flowItems,
                });
            })
            .catch(error => {
                dispatch(decAjaxCount());
                h.error("Error", error);
            });
    };
top.RDX.requestFlowItem = flowItemId => top.store.dispatch(requestFlowItem(flowItemId));

import { requestUserNames } from "./actionCreators";
export const requestFlows =
    (
        flowId: ?number = null,
        mergeBehavior: MergeBehavior = "replace",
        getAllFlowEntities: boolean = true
    ): ThunkAction =>
    (dispatch: Dispatch, getState: GetState) => {
        let url = "/flows/getflows";
        const state = getState();

        if (flowId != null) {
            url += "/" + flowId;
            if (mergeBehavior == "replace") {
                mergeBehavior = "merge";
            }
        }

        dispatch(incAjaxCount());

        if (!state.treeBehaviors.isFlowTreeLoadingExclude) {
            dispatch(ToggleIsFlowTreeLoading(true));
        }

        if (state.session.isAuthenticated) {
            return request(url, { credentials: "same-origin" }, dispatch)
                .then(h.checkStatus)
                .then(toJson)
                .then(data => {
                    // Net to load user names for ALL flow owners
                    const userIds = [...new Set(data.flows.map(item => item.FlowCreatedBy))];
                    dispatch(requestUserNames(userIds, "merge"));
                    loadFlowData(data, dispatch, getState, mergeBehavior, getAllFlowEntities);
                    dispatch(ToggleIsFlowTreeLoading(false));
                    if (state.treeBehaviors.isFlowTreeLoadingExclude) {
                        dispatch(SetIsFlowLoadingExcludes(false));
                    }
                })
                .catch(error => {
                    dispatch(decAjaxCount());
                    dispatch(ToggleIsFlowTreeLoading(false));
                    if (state.treeBehaviors.isFlowTreeLoadingExclude) {
                        dispatch(SetIsFlowLoadingExcludes(false));
                    }
                    h.error("Error loading flows.", error);
                });
        }
    };

// Special case for RDX.requestFlows.  Because this is called whenever a flowItem completes (via NotifyChange in C#),
// and often, several flowItems finish right after each other (splits, for example), we will debounce this quite heavily
// in order to reduce the amount of needless http requests and processing done on the client.
const RDXRequestFlows = (flowId, mergeBehavior) => {
    top.store.dispatch(requestFlows(flowId, mergeBehavior));
};
top.RDX.requestFlows = wrap(
    memoize(
        () => debounce(RDXRequestFlows, 1100),
        flowId => flowId
    ),
    (func, flowId, mergeBehavior) => func(flowId)(flowId, mergeBehavior)
);

export const checkFlowSaveStatus =
    (flowId: number): ThunkAction =>
    (dispatch: Dispatch, getState: GetState) => {
        let flow = getState().flows.byId[flowId];
        if (flow != null) {
            if (flow.isSaving != null && flow.isSaving != 0) {
                dispatch(updateAttribute("flows", flowId, "isSaving", 2)); // Show success icon for a few seconds
                setTimeout(() => {
                    if (flow != null && flowId > 0) {
                        // Flow might have been deleted within 5 seconds(Browser Tests)
                        dispatch(updateAttribute("flows", flowId, "isSaving", 0));
                    }
                }, 3500);
            }
        }
    };

export const checkFlowPermission = (flowId: number) => (dispatch: Dispatch) => {
    request(`/flows/CheckFlowCompanyAccess/${flowId}`, { method: "POST", credentials: "same-origin" }, dispatch)
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            if (data.canSwitch) {
                window.location.reload();
            }
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error checking flow company.", error);
        });
};
top.RDX.checkFlowPermission = flowId => top.store.dispatch(checkFlowPermission(flowId));
export const requestFlow =
    (flowId: number, mergeBehavior: MergeBehavior = "replace"): ThunkAction =>
    (dispatch: Dispatch, getState: GetState) => {
        //If we switch companies to one that doesn't have flows after executing
        //This makes sure that we don't request the information
        //Since the mew current company doesn't have the feature
        const state = getState();
        if (!state.session.enabledFeatures.includes("flow")) {
            return;
        }

        if (mergeBehavior == "replace") {
            mergeBehavior = "merge";
        }
        dispatch(incAjaxCount());
        dispatch(updateFlowShouldValidate(flowId, true));

        // only show loading in designer when we're on the flow, else loadin the background
        if (state.selected.flow && state.selected.flow == flowId) {
            dispatch(flowRelatedRequestStart());
        }

        dispatch(updateIsLoadingFlowData(flowId, true));

        dispatch(requestExternalServiceLayoutData(state.session.companyId));

        return request(`/flows/getflow/${flowId}`, { credentials: "same-origin" }, dispatch)
            .then(h.checkStatus)
            .then(toJson)
            .then(res => {
                loadFlowData(res, dispatch, getState, mergeBehavior);
                if (!state.vars.destinations || (state.vars.destinations && state.vars.destinations.length == 0)) {
                    dispatch(requestDestinations());
                }
                if (
                    state.session.enabledFeatures.includes("export-destination-ui-redesign") &&
                    (!state.vars.availableDestinations || !state.vars.requestableDestinations)
                ) {
                    dispatch(requestDestinationsByAccess());
                }
                dispatch(GetTapadDeliveryTemplates(state.session.companyId));
                dispatch(requestFlowFieldFilters(flowId));
                dispatch(checkFlowSaveStatus(flowId));
                dispatch(updateFlowItemsRunning(flowId));
                dispatch(updateFlowShouldValidate(flowId, false));
                dispatch(updateIsLoadingFlowData(flowId, false));
                dispatch(flowRelatedRequestCompleted());
                if (state.session.enabledFeatures.includes("export-unknown-field-validation")) {
                    dispatch(requestFlowLayoutErrors(flowId));
                }
            })
            .catch(error => {
                dispatch(decAjaxCount());
                h.error("Error loading flow.", error);
                dispatch(updateIsLoadingFlowData(flowId, false));
                dispatch(updateAttribute("flows", flowId, "isLoadingFlowData", false));
                dispatch(flowRelatedRequestCompleted());
            });
    };
top.RDX.requestFlow = (flowId, mergeBehavior) => top.store.dispatch(requestFlow(flowId, mergeBehavior));

const updateFlowItemsRunning = (flowId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const flow = state.flows.byId[flowId];
    if (flow) {
        const allFlowItems = getFlowItemsArray(state);
        const flowItemsRunning = allFlowItems.filter(x => x.FlowId == flowId && x.IsRunning);
        dispatch(updateAttribute("flows", flowId, "flowItemsRunning", flowItemsRunning.length));
    }
};

const RDXRequestFlow = (flowId, mergeBehavior) => {
    top.store.dispatch(requestFlow(flowId, mergeBehavior));
};
top.RDX.requestFlow = wrap(
    memoize(
        () => debounce(RDXRequestFlow, 1100),
        flowId => flowId
    ),
    (func, flowId, mergeBehavior) => func(flowId)(flowId, mergeBehavior)
);

export const updateFlowDeploymentQueueItem =
    (flowLogType: string, workFlowId: number, newStatus: number) => (dispatch: Dispatch) => {
        dispatch({
            type: "UPDATE_FLOWDEPLOYMENTQUEUE_STATUS",
            flowLogType,
            workFlowId,
            newStatus,
        });
    };

export const getFlowItemDeployStatuses =
    (flowItemId: number, companyId: number, workflowId: number) => (dispatch: Dispatch) => {
        dispatch(incAjaxCount());
        dispatch({ type: "SET_FLOW_DEPLOY_STATUSES", audienceStatuses: [], loading: true });

        return fetch(
            "/Flows/GetFlowItemDeployStatuses?" +
                h.serialize({
                    flowItemId,
                    companyId,
                    workflowId,
                }),
            { credentials: "same-origin" }
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch({ type: "SET_FLOW_DEPLOY_STATUSES", audienceStatuses: data.audienceStatus, loading: false });
                dispatch(decAjaxCount());
            })
            .catch(error => {
                dispatch(decAjaxCount());

                dispatch({ type: "SET_FLOW_DEPLOY_STATUSES", audienceStatuses: [], loading: false });
                h.error("Error getting Audience statuses.", error);
            });
    };
top.RDX.getFlowItemDeployStatuses = (flowItemId: number, companyId: number) =>
    top.store.dispatch(getFlowItemDeployStatuses(flowItemId, companyId));

export const requestFlowDeploymentQueue =
    (flowLogType: string, flowId: number, companyId: number) => (dispatch: Dispatch) => {
        const getFlowLogUrl = "/Flows/GetFlowLogs?" + h.serialize({ flowLogType, flowId, companyId });
        dispatch(incAjaxCount());

        if (flowLogType == "" || flowLogType == "Active") {
            dispatch({ type: "SET_FLOW_DEPLOYMENT_QUEUE_ACTIVE_LIST", activeList: [], loading: false });
        }
        if (flowLogType == "" || flowLogType == "Historical") {
            dispatch({ type: "SET_FLOW_DEPLOYMENT_QUEUE_HISTORICAL_LIST", historicalList: [], loading: false });
        }
        return fetch(getFlowLogUrl, { credentials: "same-origin" })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(flowLogsList => {
                if (flowLogType == "" || flowLogType == "Active") {
                    const activeList = flowLogsList.filter(
                        x => x.WorkflowStatusId == 7 || x.WorkflowStatusId == 8 || x.WorkflowStatusId == 11
                    );
                    dispatch({ type: "SET_FLOW_DEPLOYMENT_QUEUE_ACTIVE_LIST", activeList, loading: false });
                }
                if (flowLogType == "" || flowLogType == "Historical") {
                    const historicalList = flowLogsList.filter(
                        x => x.WorkflowStatusId != 7 && x.WorkflowStatusId != 8 && x.WorkflowStatusId != 11
                    );
                    dispatch({ type: "SET_FLOW_DEPLOYMENT_QUEUE_HISTORICAL_LIST", historicalList, loading: false });
                }
                dispatch(decAjaxCount());
            })
            .catch(error => {
                dispatch(decAjaxCount());
                h.error("Error getting Flow Logs List.", error);
            });
    };
top.RDX.requestFlowDeploymentQueue = (flowLogType: string, flowId: number, companyId: number) =>
    top.store.dispatch(requestFlowDeploymentQueue(flowLogType, flowId, companyId));

export const requestFlowCountLog = (flowLogType: string) => (dispatch: Dispatch) => {
    const getFlowLogUrl = "/Flows/GetCountLog?" + h.serialize({ flowLogType });
    dispatch(incAjaxCount());
    if (flowLogType == "" || flowLogType == "Active") {
        dispatch({ type: "SET_COUNT_LOG_ACTIVE_LIST", activeList: [], loading: false });
    }
    if (flowLogType == "" || flowLogType == "Historical") {
        dispatch({ type: "SET_COUNT_LOG_HISTORICAL_LIST", historicalList: [], loading: false });
    }
    return fetch(getFlowLogUrl, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(flowCountsList => {
            if (flowLogType == "" || flowLogType == "Active") {
                const activeList = flowCountsList.filter(x => x.WorkflowStatusId == 1);
                dispatch({ type: "SET_COUNT_LOG_ACTIVE_LIST", activeList, loading: false });
            }
            if (flowLogType == "" || flowLogType == "Historical") {
                const historicalList = flowCountsList.filter(x => x.WorkflowStatusId != 1);
                dispatch({ type: "SET_COUNT_LOG_HISTORICAL_LIST", historicalList, loading: false });
            }
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error getting Count Logs List.", error);
        });
};
top.RDX.requestFlowCountLog = (flowLogType: string) => top.store.dispatch(requestFlowCountLog((flowLogType: string)));

export const resetCloudFlowObjects = () => (dispatch: Dispatch) => {
    dispatch({
        type: "RESET_CLOUD_FLOWS",
    });
};

export const requestFlowObjectsFromCloud = () => (dispatch: Dispatch) => {
    const getFlowLogUrl = "/Flows/GetFlowObjectsFromCloud";
    dispatch(incAjaxCount());
    fetch(getFlowLogUrl, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(h.toJson)
        .then(flowCloud => {
            dispatch({ type: "SET_FLOW_CLOUD_OBJECTS", flowCloud });
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error getting flow objects from cloud.", error);
        });
};
top.RDX.requestFlowObjectsFromCloud = () => top.store.dispatch(requestFlowObjectsFromCloud());

export const requestFlowExportApproval = (flowId: number) => (dispatch: Dispatch) => {
    const apiUrl = "/Flows/RequestExportApproval?" + h.serialize({ flowId });
    dispatch(incAjaxCount());
    fetch(apiUrl, { method: "POST", credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            dispatch(decAjaxCount());
            if (!data.result) {
                if (data.error) {
                    h.error(data.error);
                    return;
                } else {
                    throw new Error("Error requesting flow export approval.");
                }
            } else {
                dispatch(updateAttribute("flows", flowId, "IsLocked", true));
                dispatch(flowRemoveUnsavedItems());
            }
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("There was an issue with requesting flow export approval.", error);
        });
};
top.RDX.requestFlowExportApproval = (flowId: number) => top.store.dispatch(requestFlowExportApproval((flowId: number)));

export const requestFlowCountApproval = (flowId: number) => (dispatch: Dispatch) => {
    const apiUrl = "/Flows/RequestCountApproval?" + h.serialize({ flowId });
    dispatch(incAjaxCount());
    fetch(apiUrl, { method: "POST", credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            dispatch(decAjaxCount());
            if (!data.result) {
                if (data.error) {
                    h.error(data.error);
                    return;
                } else {
                    throw new Error("Error requesting flow count log.");
                }
            } else {
                dispatch(updateAttribute("flows", flowId, "IsLocked", true));
                dispatch(flowRemoveUnsavedItems());
            }
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error requesting flow count log.", error);
        });
};
top.RDX.requestFlowCountApproval = (flowId: number) => top.store.dispatch(requestFlowCountApproval((flowId: number)));

export const requestFlowLayoutErrors = (flowId: number) => (dispatch: Dispatch) => {
    const apiUrl = "/Flows/ValidateFlowDestinationLayouts?" + h.serialize({ flowId });
    dispatch(incAjaxCount());
    fetch(apiUrl, { method: "POST", credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            dispatch(decAjaxCount());
            if (data.itemLayoutErrors) {
                dispatch(setFlowItemLayoutErrors(data.itemLayoutErrors));
            }
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error checking Flow layout errors.", error);
        });
};
top.RDX.requestFlowExportApproval = (flowId: number) => top.store.dispatch(requestFlowExportApproval((flowId: number)));

export const deployFlowExport = (flowId: number) => (dispatch: Dispatch) => {
    const apiUrl = "/Flows/DeployExport?" + h.serialize({ flowId });
    dispatch(incAjaxCount());
    fetch(apiUrl, { method: "POST", credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            dispatch(decAjaxCount());
            if (!data.result) {
                if (data.error) {
                    h.error(data.error);
                    return;
                } else {
                    throw new Error("Error deploying flow export.");
                }
            } else {
                dispatch(updateAttribute("flows", flowId, "IsLocked", true));
                dispatch(flowRemoveUnsavedItems());
            }
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error deploying flow export.", error);
        });
};
top.RDX.deployFlowExport = (flowId: number) => top.store.dispatch(deployFlowExport((flowId: number)));

export const cancelFlowExportRequest = (flowId: number) => (dispatch: Dispatch) => {
    const apiUrl = "/Flows/CancelFlowExportRequest?" + h.serialize({ flowId });
    dispatch(incAjaxCount());
    fetch(apiUrl, { method: "POST", credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            if (!data.result) {
                if (data.error) {
                    h.error(data.error);
                    dispatch(decAjaxCount());
                    return;
                } else {
                    throw new Error("Error cancelling flow approval request.");
                }
            } else if (data && data.result && !data.IsLockedPermanently) {
                dispatch(updateAttribute("flows", flowId, "IsLocked", false));
            }

            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error cancelling flow approval request.", error);
        });
};
top.RDX.cancelFlowExportRequest = (flowId: number) => top.store.dispatch(cancelFlowExportRequest((flowId: number)));

export const decideFlowExport = (workflowId: number, actionName: string, reason: string) => (dispatch: Dispatch) => {
    const apiUrl = "/Flows/DecideExportRequestAsync?" + h.serialize({ workflowId, actionName, reason });
    fetch(apiUrl, { method: "POST", credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            dispatch(requestFlowDeploymentQueue("", 0));
            dispatch(decAjaxCount());
            if (data.error) {
                h.error(data.error);
            }
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error deciding Flow Export (action " + actionName + ").", error);
        });
};
top.RDX.decideFlowExport = (workflowId, actionName, reason) =>
    top.store.dispatch(decideFlowExport(workflowId, actionName, reason));

export const decideCountApproval =
    (workflowId: number, actionName: string, flowItemId: number, flowItemType: string) => (dispatch: Dispatch) => {
        const apiUrl =
            "/Flows/DecideCountApproval?" + h.serialize({ workflowId, actionName, flowItemId, flowItemType });
        fetch(apiUrl, { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(toJson)
            .then(data => {
                dispatch(requestFlowCountLog(""));
                dispatch(decAjaxCount());
                if (data.error) {
                    h.error(data.error);
                }
            })
            .catch(error => {
                dispatch(decAjaxCount());
                h.error("Error deciding Count Log (action " + actionName + ").", error);
            });
    };
top.RDX.decideCountApproval = (workflowId, actionName, flowItemId, flowItemType) =>
    top.store.dispatch(decideCountApproval(workflowId, actionName, flowItemId, flowItemType));

export const requestFinalizeDiscoveries = (flowId: number) => (dispatch: Dispatch) => {
    const apiUrl = "/Flows/FinalizeFlowDiscoveries?" + h.serialize({ flowId });
    dispatch(incAjaxCount());
    fetch(apiUrl, { method: "POST", credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            dispatch(decAjaxCount());
            if (!data.result) {
                if (data.error) {
                    h.error(data.error);
                    return;
                } else {
                    throw new Error("Error finalizing Flow Discoveries.");
                }
            }
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error finalizing Flow Discoveries.", error);
        });
};
top.RDX.requestFinalizeDiscoveries = (flowId: number) =>
    top.store.dispatch(requestFinalizeDiscoveries((flowId: number)));

////////////////// BEGIN DELETE SECTION  //////////////////////
// Simply delete a thing with no checks or cascading deletes
// We should probably build 'smarter' deletes on top of this one.
export const _deleteThing = (entityPluralName: EntityPluralName, id: number) => (dispatch: Dispatch) => {
    const prefix = _entityPluralNameToPrefix(entityPluralName);
    dispatch({
        type: `${prefix}_DELETE`,
        id,
    });
    dispatch(setHasUnsavedChanges());
};

export const deleteAllItemsInFlow = (flowId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const items = getFlowItemsArray(state);
    const myItemIds = items.filter(x => x.FlowId == flowId).map(x => x.FlowItemId);
    for (const id of myItemIds) {
        dispatch(deleteFlowItem(id));
    }
};

export const requestDeleteFlow = (flowId: number) => (dispatch: Dispatch, getState: GetState) => {
    dispatch(incAjaxCount());
    dispatch(updateAttribute("flows", flowId, "hasUnsavedChanges", false));

    const deleteResultsDbUrl =
        "/Flows/DeleteFlow?" +
        h.serialize({
            flowId,
        });

    // Local updates
    if (getState().selected.flow == flowId) {
        dispatch(setSelectedFlowNoUnsavedCheck(-1));
    }
    dispatch(deleteFlow(flowId));

    // Server request
    return request(deleteResultsDbUrl, { method: "POST", credentials: "same-origin" }, dispatch)
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            dispatch(decAjaxCount());
            if (!data.result) {
                if (data.error) {
                    h.error(data.error);
                    throw new Error(data.error);
                } else {
                    throw new Error("Error deleting flow.");
                }
            }
            dispatch(notifyGreen("Flow deleted successfully."));
            dispatch(SetIsFlowLoadingExcludes(true));
            dispatch(requestFlows());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            dispatch(SetIsFlowLoadingExcludes(false));

            h.error("Error deleting flow", error);
            throw new Error("Error deleting flow.");
        });
};

// Delete an array of flowItemIds, but only if permissions allow.  Assumes currently selected flow.
export const deleteFlowItemsCheckPerms = (ids: Array<number>) => (dispatch: Dispatch, getState: GetState) => {
    // We are assuming the flowItems belong to the currently selected flow.
    const getPermissions = makeGetPermissionsItemFromProps();
    for (const id of ids) {
        const state = getState();
        const permissions: FlowAndItemPermissions = getPermissions(state, { flowItemId: id });
        if (permissions.item.canRemove) {
            dispatch(deleteFlowItem(id));
        }
    }
};

// Delete an array of flowRelationIds, but only if permissions allow.  Assumes currently selected flow.
export const deleteFlowRelationsCheckPerms = (ids: Array<number>) => (dispatch: Dispatch, getState: GetState) => {
    // We are assuming the relations belong to the currently selected flow.
    const state = getState();
    const getPermissions = makeGetSelectedFlowPermissions();

    const flowPermissions: FlowPermissions = getPermissions(state);
    if (!flowPermissions.canEdit) {
        return;
    }

    for (const id of ids) {
        dispatch(deleteFlowRelation(id));
    }
};

//TODO will likely need to add flowSingleViews here
// Delete a flow item, and also all relations and subtypes involving it
export const deleteFlowItem = (id: number) => (dispatch: Dispatch, getState: GetState) => {
    let state = getState();
    const thisFlowItem: ?FlowItem = state.flowItems.byId[id];
    if (thisFlowItem == null) {
        return;
    }
    dispatch(updateFlowShouldValidate(state.selected.flow, true));
    dispatch(invalidateItemAndChildren(id));

    let allRelations = getFlowRelationsArray(state);

    const relationIdsToUpdate = allRelations.filter(x => x.ParentFlowItemId == id).map(x => x.FlowRelationId);
    const relationIdsToDelete = allRelations.filter(x => x.ChildFlowItemId == id).map(x => x.FlowRelationId);

    // Deleting the FlowItem itself
    dispatch(_deleteThing("flowItems", id));

    const merges = getFlowMergesArray(state);
    const offerMerges = getFlowOfferMergesArray(state);
    // Moving relations to 0
    for (const relativeId of relationIdsToUpdate) {
        // Moving the relation to 0, but check that that link doesn't already exist first

        const thisRelation = state.flowRelations.byId[relativeId];
        if (thisRelation == null) {
            continue;
        }

        const mergeEntryExists =
            merges.filter(
                x => x.FlowItemId == thisRelation.ChildFlowItemId && x.ParentFlowItemId == thisRelation.ParentFlowItemId
            ).length > 0;

        const offerMergeEntryExists =
            offerMerges.filter(
                x => x.FlowItemId == thisRelation.ChildFlowItemId && x.ParentFlowItemId == thisRelation.ParentFlowItemId
            ).length > 0;

        const linkToZeroAlreadyExists =
            allRelations.filter(x => x.ParentFlowItemId == 0 && x.ChildFlowItemId == thisRelation.ChildFlowItemId)
                .length > 0;

        if (mergeEntryExists || offerMergeEntryExists || linkToZeroAlreadyExists) {
            dispatch(deleteFlowRelation(relativeId));
        } else {
            dispatch(updateAttribute("flowRelations", relativeId, "ParentFlowItemId", 0));
        }

        state = getState();
        allRelations = getFlowRelationsArray(state);
    }

    // Delete relations
    for (const relativeId of relationIdsToDelete) {
        dispatch(deleteFlowRelation(relativeId));
    }
    // Delete SubType  (If this Item = filter, delete the filter.  If item = split, delete the split.)
    const flowItemSubEntities = prefixesAndEntities.filter(x => x.flowItemType != "" && x.flowItemType != null); // Split, Filter, Merge, etc...
    for (const entityRow of flowItemSubEntities) {
        const { stateKey, id: idName, deleteAc } = entityRow;

        const theseThings: Array<any> = Object.values(state[stateKey].byId);
        const theseIdsToDelete = theseThings
            .filter(x => x.FlowItemId == id || x.ChildFlowItemId == id || x.ParentFlowItemId == id)
            .map(x => x[idName]);

        for (const id of theseIdsToDelete) {
            dispatch(deleteAc(id));
        }
    }

    //delete flowItemClientVariables - #928
    if (thisFlowItem.FlowItemType == "offerMerge") {
        //get all the flow item variables
        const flowItemClientVariables = getFlowItemClientVariablesForSelectedFlow(state);

        const flowItemCVs: Array<FlowItemClientVariable> = Object.values(flowItemClientVariables);

        //variables are tied to the offer merge parent
        const allParentRelations = allRelations.filter(x => x.ChildFlowItemId == thisFlowItem.FlowItemId);

        // get only client variables that are offer codes
        const flowItemCVOfferCodes = [];

        const filterOfferCodes = getClientVariablesWithValuesForSelectedFlow(state).filter(
            x => x.VariableScope == "OfferCode" && x.IsVisible
        );

        flowItemCVs.forEach(fi => {
            filterOfferCodes.forEach(oc => {
                if (fi.VariableId == oc.Id) {
                    flowItemCVOfferCodes.push(fi);
                }
            });
        });

        //delete paren client variables
        for (const parent of allParentRelations) {
            const flowItemClientVariablesToDelete = flowItemCVOfferCodes.filter(
                x => x.FlowItemId == parent.ParentFlowItemId
            );

            if (flowItemClientVariablesToDelete.length > 0) {
                for (const flowItemCV of flowItemClientVariablesToDelete) {
                    dispatch({
                        type: "FLOW_ITEM_CLIENT_VARIABLE_DELETE",
                        id: flowItemCV.FlowItemClientVariableId,
                    });
                }
            }
        }
        dispatch(updateFlowShouldValidate(state.selected.flow, false));
    }

    //code added for flow-export-distribution-platforms
    if (thisFlowItem.FlowItemType == "export") {
        const allDistributionPlatforms = getFlowExportDistributionPlatformsArray(state);
        const flowExportDistributionPlatforms = allDistributionPlatforms.filter(
            x => x.FlowItemId == thisFlowItem.FlowItemId
        );

        for (const distributionPlatform of flowExportDistributionPlatforms) {
            dispatch(deleteFlowExportDistributionPlatform(distributionPlatform.FlowExportDistributionPlatformId));
        }
    }

    dispatch(setHasUnsavedChanges());
};

export const deleteFlowSplit = (id: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const thisFlowSplit: ?FlowSplit = state.flowSplits.byId[id];
    if (thisFlowSplit == null) {
        return;
    }

    // Find the Child FlowItem
    const childFlowItemId = thisFlowSplit.ChildFlowItemId;

    // Delete the FlowEmpty belonging to the Child FlowItem
    const flowEmptiesByFlowItemId = getFlowEmptiesByFlowItemId(state);
    const flowEmpty = flowEmptiesByFlowItemId[childFlowItemId];
    if (flowEmpty != null) {
        // Realign children according to the item to delete
        dispatch(realignChildren(thisFlowSplit.FlowItemId, childFlowItemId));

        dispatch(deleteFlowEmpty(flowEmpty.FlowEmptyId));
    }

    // Delete the Child FlowItem (type=empty)
    dispatch(deleteFlowItem(childFlowItemId));

    // Delete the split row
    dispatch(_deleteThing("flowSplits", id));
};

export const deleteFlowCase = (id: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const thisFlowCase: ?FlowCase = state.flowCases.byId[id];
    if (thisFlowCase == null) {
        return;
    }

    // Find the Child FlowItem
    const childFlowItemId = thisFlowCase.ChildFlowItemId;

    // Delete the FlowEmpty belonging to the Child FlowItem
    const flowEmptiesByFlowItemId = getFlowEmptiesByFlowItemId(state);
    const flowEmpty = flowEmptiesByFlowItemId[childFlowItemId];
    if (flowEmpty != null) {
        // Realign children according to the item to delete
        dispatch(realignChildren(thisFlowCase.FlowItemId, childFlowItemId));

        dispatch(deleteFlowEmpty(flowEmpty.FlowEmptyId));
    }

    // Delete the Child FlowItem (type=empty)
    dispatch(deleteFlowItem(childFlowItemId));

    // Delete the case row
    dispatch(_deleteThing("flowCases", id));
};

export const realignChildren =
    (flowItemId: number, childFlowItemId: number) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const itemsById = state.flowItems.byId;
        const item: ?FlowItem = itemsById[flowItemId];
        let childItem: FlowItem = itemsById[childFlowItemId];

        if (item && childItem) {
            const position = {
                x: childItem.x,
                y: childItem.y,
            };

            if (item.FlowItemType == "split") {
                const flowSplitsByItemId = getFlowSplitsByFlowItemId(state);
                const splitEmpties = flowSplitsByItemId[flowItemId] || [];
                const startIndex = splitEmpties.findIndex(x => x.ChildFlowItemId == childFlowItemId) + 1;

                splitEmpties.slice(startIndex, splitEmpties.length).forEach(emptyItem => {
                    const newPosition = { ...position };

                    // Position for the next item
                    childItem = itemsById[emptyItem.ChildFlowItemId];
                    position.x = childItem.x;
                    position.y = childItem.y;

                    dispatch(
                        updateMultipleAttribute("flowItems", emptyItem.ChildFlowItemId, {
                            x: newPosition.x,
                            y: newPosition.y,
                        })
                    );
                });
            }

            if (item.FlowItemType == "case") {
                const flowCasesByItemId = getFlowCasesByFlowItemId(state);
                const caseEmpties = flowCasesByItemId[flowItemId] || [];
                const startIndex = caseEmpties.findIndex(x => x.ChildFlowItemId == childFlowItemId) + 1;

                caseEmpties.slice(startIndex, caseEmpties.length).forEach(emptyItem => {
                    const newPosition = { ...position };

                    // Position for the next item
                    childItem = itemsById[emptyItem.ChildFlowItemId];
                    position.x = childItem.x;
                    position.y = childItem.y;

                    dispatch(
                        updateMultipleAttribute("flowItems", emptyItem.ChildFlowItemId, {
                            x: newPosition.x,
                            y: newPosition.y,
                        })
                    );
                });
            }
        }
    };

export const deleteFlowReport = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowReports", id));
};
export const deleteFlowModel = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowModels", id));
};
export const deleteFlowExportReport = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowExportReports", id));
};
export const deleteFlowCannedReport = (id: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const selectedCannedReport = state.selected.flowCannedReport;
    if (id == selectedCannedReport) {
        dispatch(clearSelectedCannedReport());
    }
    dispatch(_deleteThing("flowCannedReports", id));
};
export const deleteFlowMerge = (id: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const merge = state.flowMerges.byId[id];
    if (!merge) {
        return;
    }
    const relations = getFlowRelationsArray(state);
    const relationIdsToDelete = merge
        ? relations
              .filter(x => x.ParentFlowItemId == merge.ParentFlowItemId && x.ChildFlowItemId == merge.FlowItemId)
              .map(x => x.FlowRelationId)
        : null;

    for (const relationId of relationIdsToDelete) {
        dispatch(_deleteThing("flowRelations", relationId));
    }
    dispatch(_deleteThing("flowMerges", id));
};
export const deleteFlowOfferMerge =
    (id: number, deleteRelations: boolean = true) =>
    (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const merge = state.flowOfferMerges.byId[id];
        if (merge == null) {
            return;
        }

        if (deleteRelations) {
            const relations = getFlowRelationsArray(state);
            const relationIdsToDelete = relations
                .filter(x => x.ParentFlowItemId == merge.ParentFlowItemId && x.ChildFlowItemId == merge.FlowItemId)
                .map(x => x.FlowRelationId);

            for (const relationId of relationIdsToDelete) {
                dispatch(_deleteThing("flowRelations", relationId));
            }
        }
        dispatch(_deleteThing("flowOfferMerges", id));
    };

export const disableFlowReportTabs = (flowItemId: number) => (dispatch: Dispatch) => {
    dispatch(updateAttribute("flowItems", flowItemId, "HasResultTable", false));
    dispatch(updateAttribute("flowItems", flowItemId, "FlowItemQty", ""));
};

export const deleteFlowFilter = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowFilters", id));
};
export const deleteFlowScript = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowScripts", id));
};
export const deleteFlowScriptDBUI = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowScriptsDBUI", id));
};
export const deleteFlowScriptResult = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowScriptResults", id));
};
export const deleteFlowDataLoad = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowDataLoads", id));
};
export const deleteFlowDataLoadColumn = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowDataLoadColumns", id));
};
export const deleteFlowMultiExport = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowMultiExports", id));
};
export const deleteFlowExport = (id: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const thisExport: FlowExport = state.flowExports.byId[id];
    const reports: Array<FlowExportReport> = getFlowExportReportsArray(state).filter(
        x => x.ParentFlowItemId == thisExport.FlowItemId
    );

    dispatch(_deleteThing("flowExports", id));
    reports.forEach(x => {
        dispatch(_deleteThing("flowExportReports", x.FlowExportReportId));
        dispatch(deleteFlowItem(x.FlowItemId));
    });
};
export const deleteFlowFromCloud = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowFromClouds", id));
};
export const deleteFlowToCloud = (id: number) => (dispatch: Dispatch) => {
    dispatch(resetCloudFlowObjects());
    dispatch(_deleteThing("flowToClouds", id));
};
export const deleteFlowSingleView = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowSingleViews", id));
};
export const deleteFlowOutput = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowOutputs", id));
};
export const deleteFlowSVDedupe = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowSVDedupes", id));
};
export const deleteFlowRelation = (id: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const relation: ?FlowRelation = state.flowRelations.byId[id];
    if (relation == null) {
        return;
    }
    dispatch(updateFlowShouldValidate(state.selected.flow, true));
    dispatch(_deleteThing("flowRelations", id));

    // Invalidate Child Items
    dispatch(invalidateItemAndChildren(relation.ChildFlowItemId));

    // Check for corresponding merges or offermerges that should also be deleted.
    const stateOfNow = getState();
    const merges = getFlowMergesArray(stateOfNow);
    const mergesToDelete = merges
        .filter(x => x.FlowItemId == relation.ChildFlowItemId && x.ParentFlowItemId == relation.ParentFlowItemId)
        .map(x => x.FlowMergeId);
    for (const id of mergesToDelete) {
        dispatch(deleteFlowMerge(id));
    }

    const offers = getFlowOfferMergesArray(stateOfNow);
    const offersToDelete = offers
        .filter(x => x.FlowItemId == relation.ChildFlowItemId && x.ParentFlowItemId == relation.ParentFlowItemId)
        .map(x => x.FlowOfferMergeId);
    for (const id of offersToDelete) {
        dispatch(deleteFlowOfferMerge(id));
    }

    if (state.session.enabledFeatures.includes("flow-export-template-fields")) {
        const exportTemplates = getFlowExportTemplateFieldsArray(state);
        const templatesToDelete = exportTemplates
            .filter(x => x.FlowItemId == relation.ChildFlowItemId && x.ParentFlowItemId == relation.ParentFlowItemId)
            .map(x => x.FlowExportTemplateId);
        for (const id of templatesToDelete) {
            dispatch(deleteFlowExportTemplateFields(id));
        }
    }

    const exportPinterestTemplates = getFlowExportPinterestTemplateFieldsArray(state);
    const pinterestTemplatesToDelete = exportPinterestTemplates
        .filter(x => x.FlowItemId == relation.ChildFlowItemId && x.ParentFlowItemId == relation.ParentFlowItemId)
        .map(x => x.FlowExportTemplateId);
    for (const id of pinterestTemplatesToDelete) {
        dispatch(deleteFlowExportPinterestTemplateFields(id));
    }

    const exportTikTokTemplates = getFlowExportTikTokTemplateFieldsArray(state);
    const tikTokTemplatesToDelete = exportTikTokTemplates
        .filter(x => x.FlowItemId == relation.ChildFlowItemId && x.ParentFlowItemId == relation.ParentFlowItemId)
        .map(x => x.FlowExportTemplateId);
    for (const id of tikTokTemplatesToDelete) {
        dispatch(deleteFlowExportTikTokTemplateFields(id));
    }

    const exportTradeDeskTemplates = getFlowExportTradeDeskTemplateFieldsArray(state);
    const tradeDeskTemplatesToDelete = exportTradeDeskTemplates
        .filter(x => x.FlowItemId == relation.ChildFlowItemId && x.ParentFlowItemId == relation.ParentFlowItemId)
        .map(x => x.FlowExportTemplateId);
    for (const id of tradeDeskTemplatesToDelete) {
        dispatch(deleteFlowExportTradeDeskTemplateFields(id));
    }

    const exportTaxonomyTemplates = getFlowExportTaxonomyFileFieldsArray(state);
    const taxonomyToDelete = exportTaxonomyTemplates
        .filter(x => x.FlowItemId == relation.ChildFlowItemId && x.ParentFlowItemId == relation.ParentFlowItemId)
        .map(x => x.FlowExportTaxonomyFileFieldId);
    for (const id of taxonomyToDelete) {
        dispatch(deleteFlowExportTaxonomyFileFields(id));
    }

    const freewheelDriverFileFields = getFlowExportFreewheelDriverFileFieldsArray(state);
    const freewheelDriverFileFieldsToDelete = freewheelDriverFileFields
        .filter(x => x.FlowItemId == relation.ChildFlowItemId && x.ParentFlowItemId == relation.ParentFlowItemId)
        .map(x => x.FlowExportFreewheelDriverFileFieldId);
    for (const id of freewheelDriverFileFieldsToDelete) {
        dispatch(deleteFlowExportFreewheelDriverFileFields(id));
    }

    const xandrDriverFields = getFlowExportXandrDriverFieldsArray(state);
    const xandrDriverFieldsToDelete = xandrDriverFields
        .filter(x => x.FlowItemId == relation.ChildFlowItemId && x.ParentFlowItemId == relation.ParentFlowItemId)
        .map(x => x.XandrDriverFieldId);
    for (const id of xandrDriverFieldsToDelete) {
        dispatch(deleteFlowExportXandrDriverFields(id));
    }

    //begin flow-export-pivot-labels
    // Check for flow relation parent labels that need to be deleted
    const parentLabelsArray = getFlowRelationsParentLabelsArray(state);
    const relationParentLabels = parentLabelsArray.filter(x => x.FlowRelationId == id);
    for (const parentLabel of relationParentLabels) {
        dispatch(deleteFlowRelationParentLabel(parentLabel.FlowRelationParentLabelId));
    }
    //check if child item was an export report and delete - #2616
    const childFlowItem = state.flowItems.byId[relation.ChildFlowItemId];
    if (childFlowItem && childFlowItem.FlowItemType == "exportreport") {
        dispatch(deleteFlowItem(childFlowItem.FlowItemId));
    }
    //end flow-export-pivot-labels
    dispatch(updateFlowShouldValidate(state.selected.flow, false));
};
export const deleteFlowEmpty = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowEmpties", id));
};
export const deleteFlowClientVariable = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowClientVariables", id));
};
export const deleteFlowItemClientVariable = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowItemClientVariables", id));
};
export const deleteFlowItemEndpoint = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowItemEndpoints", id));
};
export const deleteFlowItemOfferCode = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowItemOfferCodes", id));
};
export const deleteFlow = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flows", id));
};

export const deleteFlowOffload = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowOffloads", id));
};

export const deleteFlowOffloadColumn = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowOffloadColumns", id));
};

export const deleteFlowExportTemplateFields = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowExportTemplateFields", id));
};

export const deleteFlowExportPinterestTemplateFields = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowExportPinterestTemplateFields", id));
};

export const deleteFlowExportTikTokTemplateFields = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowExportTikTokTemplateFields", id));
};

export const deleteFlowExportTradeDeskTemplateFields = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowExportTradeDeskTemplateFields", id));
};

export const deleteFlowExportTaxonomyFileFields = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowExportTaxonomyFileFields", id));
};

export const deleteFlowExportFreewheelDriverFileFields = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowExportFreewheelDriverFileFields", id));
};

export const deleteFlowSegmentSplit = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowSegmentSplits", id));
};

export const deleteFlowSegmentSplitOffer = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowSegmentSplitOffers", id));
};

export const deleteFlowExternalService = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowExternalServices", id));
};

export const deleteFlowExternalServiceParameter = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowExternalServiceParameters", id));
};

export const deleteFlowExternalServiceInput = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowExternalServiceInputs", id));
};

export const deleteFlowExternalServiceHardcode = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowExternalServiceHardcodes", id));
};

export const deleteFlowExpression = (id: number) => (dispatch: Dispatch) =>
    dispatch(_deleteThing("flowExpressions", id));
export const deleteFlowExpressionConstraint = (id: number) => (dispatch: Dispatch) =>
    dispatch(_deleteThing("flowExpressionConstraints", id));

export const deleteFlowDescription = (id: number) => (dispatch: Dispatch) =>
    dispatch(_deleteThing("flowDescriptions", id));

export const clearFlowOffloadColumnsForFlowItem = (id: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const offloadColumns = getFlowOffloadColumnsByFlowItemId(state);
    const columnsToDelete = offloadColumns[id];
    if (!columnsToDelete) {
        return;
    }
    for (const column of columnsToDelete) {
        dispatch(deleteFlowOffloadColumn(column.FlowOffloadColumnId));
    }
};

// Helper to delete relations without knowing the ID of the relation to delete
export const deleteFlowRelationBetween =
    (parentId: number, childId: number) => (dispatch: Dispatch, getState: GetState) => {
        let state = getState();
        let flowRelations = getFlowRelationsForSelectedFlow(state);
        dispatch(updateFlowShouldValidate(state.selected.flow, true));

        const relationIdsToDelete = flowRelations
            .filter(x => x.ParentFlowItemId == parentId && x.ChildFlowItemId == childId)
            .map(x => x.FlowRelationId);

        // We'll actually only delete 1 at most.
        // This is because SRD sometimes deletes duplicate relations and wants to keep the other.
        if (relationIdsToDelete.length > 0) {
            const id = relationIdsToDelete[0];
            dispatch(deleteFlowRelation(id));
        }

        // After we have deleted the relation, add a new relation from the child to Zero if the child has no relations.
        dispatch(add0AsParentIfNoParents(childId));
        dispatch(updateFlowShouldValidate(state.selected.flow, false));
    };

export const add0AsParentIfNoParents = (flowItemId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const flowRelations = getFlowRelationsForSelectedFlow(state);
    const existingCount = flowRelations.filter(x => x.ChildFlowItemId == flowItemId).length;
    if (existingCount == 0) {
        dispatch(newFlowRelation(0, flowItemId));
    }
};
////////////////// END DELETE SECTION  //////////////////////

///////////////// BEGIN OVERWRITE=OFF SECTION //////////////////////////
const _copyThingToNewId =
    (entityPluralName: EntityPluralName, oldId: number, newId: number) => (dispatch: Dispatch) => {
        const prefix = _entityPluralNameToPrefix(entityPluralName);
        dispatch({
            type: `${prefix}_COPY_TO_NEW_ID`,
            oldId,
            newId,
        });
    };

export const removeErrorOnFlowItem = (flowItemId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const itemsById = state.flowItems.byId;

    const item: ?FlowItem = itemsById[flowItemId];
    if (item != null) {
        dispatch(
            updateMultipleAttribute("flowItems", flowItemId, {
                IsError: false,
                IsCancelled: false,
            })
        );

        // Case and Split need to clear error on child Empties
        if (item.FlowItemType == "split") {
            const flowSplitsByItemId = getFlowSplitsByFlowItemId(state);
            const splitEmpties = flowSplitsByItemId[flowItemId] || [];
            splitEmpties.forEach(x => {
                dispatch(
                    updateMultipleAttribute("flowItems", x.ChildFlowItemId, {
                        IsError: false,
                        IsCancelled: false,
                    })
                );
            });
        }
        if (item.FlowItemType == "case") {
            const flowCasesByItemId = getFlowCasesByFlowItemId(state);
            const caseEmpties = flowCasesByItemId[flowItemId] || [];
            caseEmpties.forEach(x => {
                dispatch(
                    updateMultipleAttribute("flowItems", x.ChildFlowItemId, {
                        IsError: false,
                        IsCancelled: false,
                    })
                );
            });
        }
    }
};

export const flowCritriaChanged = (flowItemId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const parent: ?FlowItem = state.flowItems.byId[flowItemId];
    const exportTradeDeskTemplates = getFlowExportTradeDeskTemplateFieldsArray(state);
    const exportTradeDeskTemplateINeed = exportTradeDeskTemplates.filter(x => x.ParentFlowItemId == flowItemId);
    const hasFieldRatePricing = state.session.enabledFeatures.includes("display-field-rates");
    const flowExportsByFlowItemId = getFlowExportsByFlowItemId(state);
    const flowExportsArray = Object.values(flowExportsByFlowItemId);

    if (exportTradeDeskTemplateINeed) {
        for (let i = 0; i < exportTradeDeskTemplateINeed.length; i++) {
            const idToUpdate = exportTradeDeskTemplateINeed[i].FlowExportTemplateId;
            const childId = exportTradeDeskTemplateINeed[i].FlowItemId;
            const parentId = flowItemId;
            const FlowExport = flowExportsArray.find(x => x.FlowItemId == childId);
            const tapadDeliveryTypeId = FlowExport.DeliveryTypeId;
            const tapadDeliveryTypes = state.vars.tapadDeliveryTypes;
            const thisDeliveryType = tapadDeliveryTypes.find(x => x.TapadDeliveryTypeId == tapadDeliveryTypeId);
            if (thisDeliveryType && DeliveryType.ThirdPartyCustom == thisDeliveryType.DeliveryType) {
                let newCPM = newCPMandPOM(state, parent, childId, parentId, hasFieldRatePricing, 1, true);
                let newPOM = newCPMandPOM(state, parent, childId, parentId, hasFieldRatePricing, 2, true);
                dispatch(updateAttribute("flowExportTradeDeskTemplateFields", idToUpdate, "CostPerMillion", newCPM));
                dispatch(
                    updateAttribute("flowExportTradeDeskTemplateFields", idToUpdate, "PercentOfMediaCost", newPOM)
                );
            }
        }
    }
};


// Set an item's HasQATable, HasResultTable to false, and FlowItemQty to null.
// Also does the same to its children (recursively).
// This is done when changing the item such that its results are no longer valid.
// Need to call this manually when making relevant changes - attaching this to updateAttribute wouldn't make sense.
export const invalidateItemAndChildren = (flowItemId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const childrenIndex = getFlowItemChildrenIndex(state);
    const itemsById = state.flowItems.byId;
    const flowSingleViewsByFlowItemId = getFlowSingleViewsByFlowItemId(state);
    const flowRelations = getFlowRelationsForAllFlows(state);
    const allFlowModels = getFlowModelsArray(state);

    const flowItem = itemsById[flowItemId];
    if (flowItemId > 0 && flowItem) {
        dispatch(updateAttribute("flowItems", flowItemId, "IsError", false));
    }

    const invalidate = (flowItemId, visitedNodes = []) => {
        if (visitedNodes.includes(flowItemId)) {
            return visitedNodes;
        }

        visitedNodes.push(flowItemId);
        // Return early if item is already invalidated
        const item: ?FlowItem = itemsById[flowItemId];
        if (item == null) {
            return visitedNodes;
        }
        if (item.FlowItemType.toLowerCase() == "singleview") {
            const existingSingleView = flowSingleViewsByFlowItemId[item.FlowItemId];
            if (existingSingleView != null) {
                existingSingleView.forEach(x => {
                    dispatch(updateAttribute("flowSingleViews", x.FlowSingleViewId, "FlowSingleViewQuantity", 0));
                    dispatch(updateAttribute("flowSingleViews", x.FlowSingleViewId, "IndividualQuantity", 0));
                    dispatch(updateAttribute("flowSingleViews", x.FlowSingleViewId, "HouseholdQuantity", 0));
                    dispatch(updateAttribute("flowSingleViews", x.FlowSingleViewId, "Counted", false));
                });
            }
        }

        if (item.FlowItemType.toLowerCase() == "model") {
            const fm = allFlowModels.find(x => x.FlowItemId == item.FlowItemId);
            const parentFlowItemIds = getExistingRelations(flowRelations, null, item.FlowItemId).map(
                x => x.ParentFlowItemId
            );
            if (parentFlowItemIds.length == 2 && fm) {
                if (!parentFlowItemIds.includes(fm.TargetFlowItemId)) {
                    dispatch(updateAttribute("flowModels", fm.FlowModelId, "TargetFlowItemId", null));
                }
                if (!parentFlowItemIds.includes(fm.BaseFlowItemId)) {
                    dispatch(updateAttribute("flowModels", fm.FlowModelId, "BaseFlowItemId", null));
                }
            }
        }

        const itemIsAlreadyInvalid =
            !item.HasQATable && !item.HasResultTable && item.FlowItemQty == null && !item.IsCancelled;
        if (itemIsAlreadyInvalid) {
            return visitedNodes;
        }

        // Invalidate the item
        dispatch(
            updateMultipleAttribute("flowItems", flowItemId, {
                HasQATable: false,
                HasResultTable: false,
                FlowItemQty: null,
                IsCancelled: false,
                CustomText: null,
                AudienceID: null,
            })
        );

        // Invalidate all my children recursively
        const childrenIds = childrenIndex[flowItemId] || [];
        for (const childId of childrenIds) {
            visitedNodes = invalidate(childId, visitedNodes);
        }
        return visitedNodes;
    };
    invalidate(flowItemId);
};

// Set the "hasUnsavedChanges" flag for the currently selected flow to a value.
// Note that, a lot of times, we assume that actions are being fired off for the currently selected flow,
// which may not be right.  For example, if I run updateAttribute on any random flowItem, it
// sets unsaved changes on the currently selected flow, even if that item doesn't belong to that flow.
export const setHasUnsavedChanges =
    (newHasUnsavedChanges: boolean = true) =>
    (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const flowId: number = state.selected.flow;
        const flow: ?Flow = state.flows.byId[flowId];
        if (flow != null && flow.hasUnsavedChanges != newHasUnsavedChanges) {
            dispatch(updateAttribute("flows", flowId, "hasUnsavedChanges", newHasUnsavedChanges));
        }
    };

export const pasteFlowItems =
    (
        flowItemIds: Array<number>,
        relationIds: Array<number>,
        offsetX: number,
        offsetY: number,
        selectedNodeLinks: Array<number>
    ) =>
    (dispatch: Dispatch, getState: GetState) => {
        updatedNodeIDs = [
            {
                oldId: 0,
                newId: 0,
            },
        ];
        const state = getState();
        let newSelectedNodeLinks = selectedNodeLinks;
        for (const flowItemId of flowItemIds) {
            const flowItem = state.flowItems.byId[flowItemId];
            if (flowItem == null || !FlowConstants.allowsCopy.includes(flowItem.FlowItemType)) {
                continue;
            }
            let newFlowItemIDForCopiedItem = dispatch(
                duplicateFlowItem(
                    flowItemId,
                    flowItem.x + offsetX,
                    flowItem.y + offsetY,
                    flowItemIds,
                    selectedNodeLinks,
                    true
                )
            );
            updatedNodeIDs.push({
                oldId: flowItemId,
                newId: newFlowItemIDForCopiedItem,
            });
        }
        // here we want to make a new array of the selectedNodeLinks where the old ids are replaced with the new ids
        for (const idToUpdate of updatedNodeIDs) {
            const selectedNodeLinksWithNewIDs = newSelectedNodeLinks.map(links => {
                if (links.ParentFlowItemId == idToUpdate.oldId) {
                    return {
                        ...links,
                        ParentFlowItemId: idToUpdate.newId,
                    };
                } else if (links.ChildFlowItemId == idToUpdate.oldId) {
                    return {
                        ...links,
                        ChildFlowItemId: idToUpdate.newId,
                    };
                } else {
                    return links;
                }
            });
            newSelectedNodeLinks = selectedNodeLinksWithNewIDs;
        }
        let state2 = getState();
        const flowItemTypeLookup2 = getFlowItemTypeLookupForSelectedFlow(state2);
        newSelectedNodeLinks.forEach(x => {
            if (flowItemTypeLookup2[x.ChildFlowItemId] != "empty") {
                // look to seeif there is an existing flow relation with a zero for a parent and a matching child ID
                // if so then update it
                // if not then create one.
                let state3 = getState();
                const flowRelations = getFlowRelationsForAllFlows(state3);

                const possibleZeroRelationDup = flowRelations.filter(
                    rel => rel.ParentFlowItemId == 0 && rel.ChildFlowItemId == x.ChildFlowItemId
                );
                if (possibleZeroRelationDup.length > 0) {
                    // update the relation
                    dispatch(
                        updateAttribute(
                            "flowRelations",
                            possibleZeroRelationDup[0].FlowRelationId,
                            "ParentFlowItemId",
                            x.ParentFlowItemId
                        )
                    );
                } else {
                    // create one
                    dispatch(newFlowRelation(x.ParentFlowItemId, x.ChildFlowItemId));
                }
            }
        });
        // Now iterate through each Pasted flow Item and copy the insides
        for (const flowItemId of flowItemIds) {
            let stateNow = getState();
            let currentFlowItemType = flowItemTypeLookup2[flowItemId];
            const merges = getFlowMergesByFlowItemId(state);
            const mergeArray = merges[flowItemId];
            const flowOfferMerges = getFlowOfferMergesByFlowItemId(stateNow);
            const flowOfferMerge = flowOfferMerges[flowItemId];
            const flowModelsByFlowItemId = getFlowModelsByFlowItemId(state);
            const exportTradeDeskTemplates = getFlowExportTradeDeskTemplateFieldsByFlowItemId(stateNow);
            const exportTradeDeskTemplateForFlowItem = exportTradeDeskTemplates[flowItemId];
            const segmentSplitsByItemId = getFlowSegmentSplitsByFlowItemId(stateNow);
            const freeWheelDriverFieldsByFlowItemId = getFlowExportFreewheelDriverFileFieldsByFlowItemId(state);
            const freewheelDriverFileFields = freeWheelDriverFieldsByFlowItemId[flowItemId];
            const exportTikTokTemplatesbyId = getFlowExportTikTokTemplateFieldsByFlowItemId(state);
            const exportTikTokTemplates = exportTikTokTemplatesbyId[flowItemId];
            const xandrDriverFieldsbyId = getFlowExportXandrDriverFieldsByFlowItemId(stateNow);
            const xandrDriverFields = xandrDriverFieldsbyId[flowItemId];
            const exportPinterestTemplatesByFlowItemId = getFlowExportPinterestTemplateFieldsByFlowItemId(stateNow);
            const exportPinterestTemplates = exportPinterestTemplatesByFlowItemId[flowItemId];
            const flowItemCVs = getFlowItemClientVariablesArray(state);
            const exportTemplates = getFlowExportTemplateFieldsByFlowItemId(stateNow);
            const flowSVDedupesByFlowItemId = getFlowSVDedupesByFlowItemId(stateNow);
            const flowSVDedupes = flowSVDedupesByFlowItemId[flowItemId];
            const flowSegmentSplitOffers = getFlowSegmentSplitOffersByFlowItemId(state);
            const flowSegmentSplitOffersArray = flowSegmentSplitOffers[flowItemId];

            // I need a switch with a case for currentFlowItemType

            switch (currentFlowItemType) {
                case "merge":
                    for (let i = 0; i < mergeArray.length; i++) {
                        const merge = mergeArray[i];
                        const newFlowMergeItemID = updatedNodeIDs.filter(upid => upid.oldId == merge.FlowItemId);
                        const newFlowMergeParentItemID = updatedNodeIDs.filter(
                            upid => upid.oldId == merge.ParentFlowItemId
                        );

                        const thing: FlowMerge = {
                            FlowMergeId: 0,
                            FlowItemId: newFlowMergeItemID[0].newId,
                            ParentFlowItemId: newFlowMergeParentItemID[0].newId,
                            FlowMergeIsSuppresion: merge.FlowMergeIsSuppresion,
                            DupesQty: merge.DupesQty,
                            UniquesQty: merge.UniquesQty,
                            FlowMergeIsInnerJoin: merge.FlowMergeIsInnerJoin,
                            WillTrackDuplicates: merge.WillTrackDuplicates,
                        };
                        dispatch(addThing("flowMerges", thing));
                    }
                    break;

                // add a model if one does not exist.
                case "model":
                    {
                        //  for (let i = 0; i < flowModelArray.length; i++) {
                        const model = flowModelsByFlowItemId[flowItemId];
                        const newModelItemID = updatedNodeIDs.filter(upid => upid.oldId == model.FlowItemId);
                        const newTargetItemID = updatedNodeIDs.filter(upid => upid.oldId == model.TargetFlowItemId);
                        const newBaseItemID = updatedNodeIDs.filter(upid => upid.oldId == model.BaseFlowItemId);
                        const thing: FlowModel = {
                            FlowModelId: 0,
                            FlowItemId: newModelItemID[0].newId,
                            DesiredHHQuantity: model.DesiredHHQuantity,
                            ModelFilename: model.ModelFilename,
                            TargetFlowItemId: newTargetItemID[0].newId,
                            BaseFlowItemId: newBaseItemID[0].newId,
                            BaseFilename: model.BaseFilename,
                            DestinationCompanyId: model.DestinationCompanyId,
                            ModelStatus: model.ModelStatus,
                            BaseLuidQty: model.BaseLuidQty,
                            TargetLuidQty: model.TargetLuidQty,
                            Bundle: model.Bundle,
                        };
                        dispatch(addThing("flowModels", thing));
                        // }
                    }
                    break;

                case "export":
                    {
                        if (flowOfferMerge) {
                            for (let i = 0; i < flowOfferMerge.length; i++) {
                                const flowOfferMergeTemplate = flowOfferMerge[i];
                                const newTemplateID = updatedNodeIDs.filter(
                                    upid => upid.oldId == flowOfferMergeTemplate.FlowItemId
                                );
                                const newTemplateParentItemID = updatedNodeIDs.filter(
                                    upid => upid.oldId == flowOfferMergeTemplate.ParentFlowItemId
                                ); // if none exists then add it.
                                // if so then updateit
                                if (newTemplateID.length > 0 && newTemplateParentItemID.length > 0) {
                                    const thing: FlowOfferMerge = {
                                        FlowOfferMergeId: 0,
                                        FlowItemId: newTemplateID[0].newId,
                                        ParentFlowItemId: newTemplateParentItemID[0].newId,
                                        FlowOfferMergeIsSuppresion: flowOfferMergeTemplate.FlowOfferMergeIsSuppresion,
                                        DupesQty: flowOfferMergeTemplate.DupesQty,
                                        UniquesQty: flowOfferMergeTemplate.UniquesQty,
                                        FlowOfferPriority: flowOfferMergeTemplate.FlowOfferPriority,
                                        MaxQty: flowOfferMergeTemplate.MaxQty,
                                        OutputQty: flowOfferMergeTemplate.OutputQty,
                                        FinalQty: flowOfferMergeTemplate.FinalQty,
                                    };
                                    dispatch(addThing("flowOfferMerges", thing));
                                }
                            }
                        }

                        if (exportTradeDeskTemplateForFlowItem) {
                            for (let i = 0; i < exportTradeDeskTemplateForFlowItem.length; i++) {
                                const exportTradeDeskTemplate = exportTradeDeskTemplateForFlowItem[i];
                                const newTemplateID = updatedNodeIDs.filter(
                                    upid => upid.oldId == exportTradeDeskTemplate.FlowItemId
                                );
                                const newTemplateParentItemID = updatedNodeIDs.filter(
                                    upid => upid.oldId == exportTradeDeskTemplate.ParentFlowItemId
                                ); // if none exists then add it.
                                // if so then updateit
                                if (newTemplateID.length > 0 && newTemplateParentItemID.length > 0) {
                                    const thing: FlowExportTradeDeskTemplateFields = {
                                        AudienceName: exportTradeDeskTemplate.AudienceName,
                                        FlowExportTemplateId: 0,
                                        FlowItemId: newTemplateID[0].newId,
                                        ParentFlowItemId: newTemplateParentItemID[0].newId,
                                        Description: exportTradeDeskTemplate.Description,
                                        ExpansionType: exportTradeDeskTemplate.ExpansionType,
                                        RateType: exportTradeDeskTemplate.RateType,
                                        CostPerMillion: exportTradeDeskTemplate.CostPerMillion,
                                        PercentOfMediaCost: exportTradeDeskTemplate.PercentOfMediaCost,
                                        TTL: exportTradeDeskTemplate.TTL,
                                        ParentElementId: exportTradeDeskTemplate.ParentElementId,
                                        ParentElementName: exportTradeDeskTemplate.ParentElementName,
                                        ExpirationDate: exportTradeDeskTemplate.ExpirationDate,
                                        IsAutoGeneratedCPM: exportTradeDeskTemplate.IsAutoGeneratedCPM,
                                        IsAutoGeneratedPOM: exportTradeDeskTemplate.IsAutoGeneratedPOM,
                                    };
                                    dispatch(addThing("flowExportTradeDeskTemplateFields", thing));
                                }
                            }
                        }
                        // flowSVDedupes
                        if (flowSVDedupes) {
                            //     for (let i = 0; i < flowSVDedupes.length; i++) {
                            const flowSVDedupe = flowSVDedupes;
                            const newTemplateID = updatedNodeIDs.filter(upid => upid.oldId == flowSVDedupe.FlowItemId);
                            if (newTemplateID.length > 0) {
                                const thing: FlowSVDedupeD = {
                                    FlowSVDedupeId: 0,
                                    FlowItemId: newTemplateID[0].newId,
                                    SVField: flowSVDedupe.SVField,
                                    SortByFields: flowSVDedupe.SortByFields,
                                };
                                dispatch(addThing("flowSVDedupes", thing));
                            }
                        }

                        // copy the segmentsplits
                        const splitsINeed = segmentSplitsByItemId[flowItemId];
                        if (splitsINeed) {
                            for (let i = 0; i < splitsINeed.length; i++) {
                                const segmentSplit = splitsINeed[i];
                                const newTemplateID = updatedNodeIDs.filter(
                                    upid => upid.oldId == segmentSplit.FlowItemId
                                );
                                const newTemplateParentItemID = updatedNodeIDs.filter(
                                    upid => upid.oldId == segmentSplit.ParentFlowItemId
                                ); // if none exists then add it.
                                if (newTemplateID.length > 0 && newTemplateParentItemID.length > 0) {
                                    const thing: FlowSegmentSplit = {
                                        SegmentSplitId: 0,
                                        SegmentName: segmentSplit.SegmentName,
                                        FlowItemId: newTemplateID[0].newId,
                                        ParentFlowItemId: newTemplateParentItemID[0].newId,
                                        AbsoluteOrRelative: segmentSplit.AbsoluteOrRelative,
                                        AbsoluteNumRows: segmentSplit.AbsoluteNumRows,
                                        RelativePercentRows: segmentSplit.RelativePercentRows,
                                        SplitQuantity: segmentSplit.SplitQuantity,
                                        IsIncludeInDeploy: segmentSplit.IsIncludeInDeploy,
                                    };

                                    dispatch(addThing("flowSegmentSplits", thing));
                                    // lookup the flowSegmentSplitOffers that have the segmentSplit.SegmentSplitId and the flowItemID
                                    // add a record with the new thing.SegmentSplitId copied over with the newTemplateId[0].newId
                                    if (flowSegmentSplitOffersArray) {
                                        const flowSegmentSplitOffersINeed = flowSegmentSplitOffersArray.filter(
                                            x => x.SegmentSplitId == segmentSplit.SegmentSplitId
                                        );
                                        if (flowSegmentSplitOffersINeed) {
                                            for (let i = 0; i < flowSegmentSplitOffersINeed.length; i++) {
                                                const flowSegmentSplitOffer = flowSegmentSplitOffersINeed[i];
                                                const newTemplateID = updatedNodeIDs.filter(
                                                    upid => upid.oldId == flowSegmentSplitOffer.FlowItemId
                                                );
                                                const offer: FlowSegmentSplitOffer = {
                                                    SegmentSplitOfferId: 0,
                                                    SegmentSplitId: thing.SegmentSplitId,
                                                    DestinationOfferId: flowSegmentSplitOffer.DestinationOfferId,
                                                    Value: flowSegmentSplitOffer.Value,
                                                    FlowItemId: newTemplateID[0].newId,
                                                };
                                                dispatch(addThing("flowSegmentSplitOffers", offer));
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        if (freewheelDriverFileFields) {
                            for (let i = 0; i < freewheelDriverFileFields.length; i++) {
                                const freewheelSplit = freewheelDriverFileFields[i];
                                const newTemplateID = updatedNodeIDs.filter(
                                    upid => upid.oldId == freewheelSplit.FlowItemId
                                );
                                const newTemplateParentItemID = updatedNodeIDs.filter(
                                    upid => upid.oldId == freewheelSplit.ParentFlowItemId
                                ); // if none exists then add it.
                                if (newTemplateID.length > 0) {
                                    const thing: FlowExportFreewheelDriverFileFields = {
                                        FlowExportFreewheelDriverFileFieldId: 0,
                                        FlowItemId: newTemplateID[0].newId,
                                        ParentFlowItemId: newTemplateParentItemID[0].newId,
                                        IdTypes: freewheelSplit.IdTypes,
                                        SegmentName: freewheelSplit.SegmentName,
                                        NetworkId: freewheelSplit.NetworkId,
                                        CategoryName: freewheelSplit.CategoryName,
                                        SegmentDescription: freewheelSplit.SegmentDescription,
                                        SubGroupDescription: freewheelSplit.SubGroupDescription,
                                        Custom: freewheelSplit.Custom,
                                        Price: freewheelSplit.Price,
                                        CompanyName: freewheelSplit.CompanyName,
                                    };

                                    dispatch(addThing("flowExportFreewheelDriverFileFields", thing));
                                }
                            }
                        }

                        if (exportTikTokTemplates) {
                            for (let i = 0; i < exportTikTokTemplates.length; i++) {
                                const tikTokSplit = exportTikTokTemplates[i];
                                const newTemplateID = updatedNodeIDs.filter(
                                    upid => upid.oldId == tikTokSplit.FlowItemId
                                );
                                const newTemplateParentItemID = updatedNodeIDs.filter(
                                    upid => upid.oldId == tikTokSplit.ParentFlowItemId
                                ); // if none exists then add it.
                                if (newTemplateID.length > 0 && newTemplateParentItemID.length > 0) {
                                    const thing: FlowExportTikTokTemplateFields = {
                                        TargetingAudienceName: tikTokSplit.TargetingAudienceName,
                                        FlowExportTemplateId: 0,
                                        FlowItemId: newTemplateID[0].newId,
                                        ParentFlowItemId: newTemplateParentItemID[0].newId,
                                        AdvertiserID: tikTokSplit.AdvertiserID,
                                    };
                                    dispatch(addThing("flowExportTikTokTemplateFields", thing));
                                }
                            }
                        }
                        // xandrDriverFields

                        if (xandrDriverFields) {
                            for (let i = 0; i < xandrDriverFields.length; i++) {
                                const xandrSplit = xandrDriverFields[i];
                                const newTemplateID = updatedNodeIDs.filter(
                                    upid => upid.oldId == xandrSplit.FlowItemId
                                );
                                const newTemplateParentItemID = updatedNodeIDs.filter(
                                    upid => upid.oldId == xandrSplit.ParentFlowItemId
                                ); // if none exists then add it.
                                if (newTemplateID.length > 0 && newTemplateParentItemID.length > 0) {
                                    const thing: FlowExportXandrDriverFields = {
                                        XandrDriverFieldId: 0,
                                        FlowItemId: newTemplateID[0].newId,
                                        ParentFlowItemId: newTemplateParentItemID[0].newId,
                                        AudienceName: xandrSplit.AudienceName,
                                        Description: xandrSplit.Description,
                                        ExpansionType: xandrSplit.ExpansionType,
                                        TTL: xandrSplit.TTL,
                                        ExpirationDate: xandrSplit.ExpirationDate,
                                        BillingCategoryId: xandrSplit.BillingCategoryId,
                                        DataSegementTypeId: xandrSplit.DataSegementTypeId,
                                    };
                                    dispatch(addThing("flowExportXandrDriverFields", thing));
                                }
                            }
                        }
                        // exportPinterestTemplates

                        if (exportPinterestTemplates) {
                            for (let i = 0; i < exportPinterestTemplates.length; i++) {
                                const pintrestSplit = exportPinterestTemplates[i];
                                const newTemplateID = updatedNodeIDs.filter(
                                    upid => upid.oldId == pintrestSplit.FlowItemId
                                );
                                const newTemplateParentItemID = updatedNodeIDs.filter(
                                    upid => upid.oldId == pintrestSplit.ParentFlowItemId
                                ); // if none exists then add it.
                                if (newTemplateID.length > 0 && newTemplateParentItemID.length > 0) {
                                    const thing: FlowExportPinterestTemplateFields = {
                                        TargetingAudienceName: pintrestSplit.TargetingAudienceName,
                                        FlowExportTemplateId: 0,
                                        FlowItemId: newTemplateID[0].newId,
                                        ParentFlowItemId: newTemplateParentItemID[0].newId,
                                        PinterestAccountID: pintrestSplit.PinterestAccountID,
                                        OldAudienceID: pintrestSplit.OldAudienceID,
                                        NotificationEmails: pintrestSplit.NotificationEmails,
                                    };
                                    dispatch(addThing("flowExportPinterestTemplateFields", thing));
                                }
                            }
                        }

                        if (exportTemplates) {
                            const exportTemplatesINeed = exportTemplates[flowItemId];
                            if (exportTemplatesINeed) {
                                for (let i = 0; i < exportTemplatesINeed.length; i++) {
                                    const exportSplit = exportTemplatesINeed[i];
                                    const newTemplateID = updatedNodeIDs.filter(
                                        upid => upid.oldId == exportSplit.FlowItemId
                                    );
                                    const newTemplateParentItemID = updatedNodeIDs.filter(
                                        upid => upid.oldId == exportSplit.ParentFlowItemId
                                    ); // if none exists then add it.
                                    if (newTemplateID.length > 0 && newTemplateParentItemID.length > 0) {
                                        const thing: FlowExportTemplateFields = {
                                            FlowExportTemplateId: 0,
                                            FlowItemId: newTemplateID[0].newId,
                                            ParentFlowItemId: newTemplateParentItemID[0].newId,
                                            Description: exportSplit.Description,
                                            AdAccountIds: exportSplit.AdAccountIds,
                                            CustomerFileSource: exportSplit.CustomerFileSource,
                                            EmailAddress: exportSplit.EmailAddress,
                                        };
                                        dispatch(addThing("flowExportTemplateFields", thing));
                                    }
                                }
                            }
                        }

                        //

                        if (flowItemCVs) {
                            const flowItemCVsINeed = flowItemCVs.filter(x => x.ChildFlowItemId == flowItemId);
                            for (let i = 0; i < flowItemCVsINeed.length; i++) {
                                const flowItemCV = flowItemCVsINeed[i];
                                if (flowItemCV.ChildFlowItemId == flowItemId) {
                                    const newTemplateID = updatedNodeIDs.filter(
                                        upid => upid.oldId == flowItemCV.FlowItemId
                                    );
                                    const newTemplateChildItemID = updatedNodeIDs.filter(
                                        upid => upid.oldId == flowItemCV.ChildFlowItemId
                                    ); // if none exists then add it.
                                    if (newTemplateID.length > 0 && newTemplateChildItemID.length > 0) {
                                        const thing: FlowItemClientVariableD = {
                                            FlowItemClientVariableId: 0,
                                            VariableId: flowItemCV.VariableId,
                                            FlowId: stateNow.selected.flow,
                                            FlowItemId: newTemplateID[0].newId,
                                            ChildFlowItemId: newTemplateChildItemID[0].newId,
                                            VariableValue: flowItemCV.VariableValue,
                                        };
                                        dispatch(addThing("flowItemClientVariables", thing));
                                    }
                                }
                            }
                        }
                    }
                    break;
            }
        }
        dispatch(updateFlowShouldValidate(state.selected.flow, true));
    };

export const canPlaceFlowItem = (x: number, y: number) => (dispatch: Dispatch, getState: GetState) => {
    let state = getState();
    const flowItems = getFlowItemsForSelectedFlow(state);
    const nodeSize = 80;
    return !flowItems.find(
        item =>
            item.x != null &&
            item.y != null &&
            ((item.x == x && item.y == y) ||
                (item.x - 80 <= x && item.x + nodeSize >= x && item.y - nodeSize <= y && item.y + nodeSize >= y))
    );
};

export const canPlaceInRow = (x: number, y: number) => (dispatch: Dispatch, getState: GetState) => {
    let state = getState();
    const flowItems = getFlowItemsForSelectedFlow(state);
    const nodeSize = 80;
    return (
        flowItems.filter(
            item =>
                item.x != null &&
                item.y != null &&
                ((item.x == x && item.y == y) || (item.y - nodeSize <= y && item.y + nodeSize >= y))
        ).length < 10
    );
};

export const duplicateFlowItem =
    (
        flowItemId: number,
        newX: ?number,
        newY: ?number,
        flowItemIds: Array<number>,
        selectedNodeLinks: Array<number>,
        updateFlowIdToSelectedFlow: ?boolean
    ) =>
    (dispatch: Dispatch, getState: GetState) => {
        let state = getState();
        const flowItem = state.flowItems.byId[flowItemId];
        if (flowItem == null) {
            return;
        }

        let x = newX == null ? Math.round(flowItem.x + 100) : Math.round(newX);
        let y = newY == null ? Math.round(flowItem.y) : Math.round(newY);

        while (!canPlaceFlowItem(x, y)) {
            x = x + 100;
        }

        dispatch(updateFlowShouldValidate(updateFlowIdToSelectedFlow ? state.selected.flow : flowItem.FlowId, true));

        // Copy the Item and set its parent to 0.
        const newFlowItemId = dispatch(flowItemCopyToIdZero(flowItemId));

        dispatch(newFlowRelation(0, newFlowItemId));

        // Various updates, clearing stuff out and positioning
        const updates = {
            FlowItemName: flowItem.FlowItemName,
            HasResultTable: false,
            HasQATable: false,
            FlowItemQty: null,
            FlowItemStart: null,
            FlowItemEnd: null,
            IsRunning: 0,
            IsError: false,
            x,
            y,
            FlowId: updateFlowIdToSelectedFlow ? state.selected.flow : flowItem.FlowId,
        };
        const xDifference = Math.round(updates.x - flowItem.x);
        const yDifference = Math.round(updates.y - flowItem.y);
        dispatch(updateMultipleAttribute("flowItems", newFlowItemId, updates));

        // Merges:  Stop additional steps
        if (flowItem.FlowItemType == "merge" || flowItem.FlowItemType == "offerMerge") {
            return newFlowItemId;
        }

        const prefixAndEntity = prefixesAndEntities.filter(x => x.flowItemType == flowItem.FlowItemType)[0];
        const { stateKey, id: idName } = prefixAndEntity;

        const theseThings: Array<any> = Object.values(state[stateKey].byId);
        const arrEntities = theseThings
            .filter(x => x.FlowItemId == flowItemId)
            .map(x => ({
                EntityId: x[idName],
                FlowItemId: x.FlowItemId,
                ChildFlowItemId: x.ChildFlowItemId || null,
            }));

        let newEntityId;
        for (const entity of arrEntities) {
            const currentEntityId = entity.EntityId;
            newEntityId = _makeNewId(state, stateKey);
            dispatch(_copyThingToNewId(stateKey, currentEntityId, newEntityId));

            // Check for FlowEmpties
            if (entity.ChildFlowItemId) {
                const childItem = state.flowItems.byId[entity.ChildFlowItemId];
                let newChildX = childItem.x + xDifference;
                let newChildY = childItem.y + yDifference;
                if (newChildX) {
                    newChildX = Math.round(newChildX);
                }
                if (newChildY) {
                    newChildY = Math.round(newChildY);
                }
                const newChildFlowItemId = dispatch(
                    duplicateFlowItem(entity.ChildFlowItemId, newChildX, newChildY, flowItemIds, selectedNodeLinks)
                );
                dispatch(updateAttribute(stateKey, newEntityId, "ChildFlowItemId", newChildFlowItemId));
                if (updateFlowIdToSelectedFlow) {
                    dispatch(updateAttribute("flowItems", newChildFlowItemId, "FlowId", state.selected.flow));
                }

                state = getState();

                const flowRelations = getFlowRelationsForAllFlows(state);
                const relationIdsToUpdate = flowRelations
                    .filter(x => x.ParentFlowItemId == 0 && x.ChildFlowItemId == newChildFlowItemId)
                    .map(x => x.FlowRelationId);

                for (const flowRelationId of relationIdsToUpdate) {
                    dispatch(updateAttribute("flowRelations", flowRelationId, "ParentFlowItemId", newFlowItemId));
                    updatedNodeIDs.push({
                        oldId: entity.ChildFlowItemId,
                        newId: newChildFlowItemId,
                    });
                }
            }

            const endLoopUpdates = {
                [prefixAndEntity.id]: newEntityId,
                FlowItemId: newFlowItemId,
            };
            dispatch(updateMultipleAttribute(stateKey, newEntityId, endLoopUpdates));

            state = getState();
        }
        //For FlowControl Duplications
        if (flowItem.FlowItemType == "flowControl") {
            // Service Paramters
            const prefixAndParameterEntity = prefixesAndEntities.filter(
                x => x.flowItemType == "FlowExternalServiceParameter"
            )[0];

            const { stateKey: parameterStateKey, id: parameterIdName } = prefixAndParameterEntity;
            const theseParameterThings: Array<any> = Object.values(state[parameterStateKey].byId);

            const arrParameterEntities = theseParameterThings
                .filter(x => x.FlowItemId == flowItemId)
                .map(x => ({
                    EntityId: x[parameterIdName],
                    FlowItemId: x.FlowItemId,
                    ChildFlowItemId: x.ChildFlowItemId || null,
                }));
            let newParameterEntityId;
            for (const entity of arrParameterEntities) {
                const currentEntityId = entity.EntityId;
                newParameterEntityId = _makeNewId(state, parameterStateKey);
                dispatch(_copyThingToNewId(parameterStateKey, currentEntityId, newParameterEntityId));
                const endParameterLoopUpdates = {
                    [prefixAndParameterEntity.id]: newParameterEntityId,
                    FlowItemId: newFlowItemId,
                };
                dispatch(updateMultipleAttribute(parameterStateKey, newParameterEntityId, endParameterLoopUpdates));
                state = getState();
            }

            // Input Files
            const prefixAndInputEntity = prefixesAndEntities.filter(
                x => x.flowItemType == "FlowExternalServiceInput"
            )[0];

            const { stateKey: inputStateKey, id: inputIdName } = prefixAndInputEntity;
            const theseInputThings: Array<any> = Object.values(state[inputStateKey].byId);

            const arrInputEntities = theseInputThings
                .filter(x => x.FlowItemId == flowItemId)
                .map(x => ({
                    EntityId: x[inputIdName],
                    FlowItemId: x.FlowItemId,
                    ChildFlowItemId: x.ChildFlowItemId || null,
                }));
            let newInputEntityId;
            for (const entity of arrInputEntities) {
                const currentEntityId = entity.EntityId;
                newInputEntityId = _makeNewId(state, inputStateKey);
                dispatch(_copyThingToNewId(inputStateKey, currentEntityId, newInputEntityId));
                const endInputLoopUpdates = {
                    [prefixAndInputEntity.id]: newInputEntityId,
                    FlowItemId: newFlowItemId,
                };
                dispatch(updateMultipleAttribute(inputStateKey, newInputEntityId, endInputLoopUpdates));
                state = getState();
            }

            // Hardcodes
            const prefixAndHardcodeEntity = prefixesAndEntities.filter(
                x => x.flowItemType == "FlowExternalServiceHardcode"
            )[0];

            const { stateKey: hardcodeStateKey, id: hardcodeIdName } = prefixAndHardcodeEntity;
            const theseHardcodeThings: Array<any> = Object.values(state[hardcodeStateKey].byId);

            const arrHardcodeEntities = theseHardcodeThings
                .filter(x => x.FlowItemId == flowItemId)
                .map(x => ({
                    EntityId: x[hardcodeIdName],
                    FlowItemId: x.FlowItemId,
                    ChildFlowItemId: x.ChildFlowItemId || null,
                }));
            let newHardcodeEntityId;
            for (const entity of arrHardcodeEntities) {
                const currentEntityId = entity.EntityId;
                newHardcodeEntityId = _makeNewId(state, hardcodeStateKey);
                dispatch(_copyThingToNewId(hardcodeStateKey, currentEntityId, newHardcodeEntityId));
                const endHardcodeLoopUpdates = {
                    [prefixAndHardcodeEntity.id]: newHardcodeEntityId,
                    FlowItemId: newFlowItemId,
                };
                dispatch(updateMultipleAttribute(hardcodeStateKey, newHardcodeEntityId, endHardcodeLoopUpdates));
                state = getState();
            }
        }
        //For Export Duplications
        if (flowItem.FlowItemType == "export") {
            let exportId = 0;
            const exportIdList = state.flowExports.byId;
            for (const key in exportIdList) {
                if (exportIdList[key].FlowItemId == flowItemId) {
                    exportId = exportIdList[key].ExportId;
                }
            }
            const flowRelations = [];
            const allFlowRelations = state.flowRelations.byId;
            for (const keys in allFlowRelations) {
                const thisFlowItem: ?FlowItem = state.flowItems.byId[allFlowRelations[keys].ChildFlowItemId];
                if (
                    allFlowRelations[keys].ParentFlowItemId == flowItemId &&
                    thisFlowItem != null &&
                    thisFlowItem.FlowItemType == "exportreport"
                ) {
                    flowRelations.push(allFlowRelations[keys].ChildFlowItemId);
                }
            }
            for (const childId of flowRelations) {
                dispatch(duplicatedFlowExportReport(newFlowItemId, exportId, childId));
            }
        }
        dispatch(updateFlowShouldValidate(updateFlowIdToSelectedFlow ? state.selected.flow : flowItem.FlowId, false));

        return newFlowItemId;
    };
top.RDX.duplicateFlowItem = (flowItemId, newX, newY) => top.store.dispatch(duplicateFlowItem(flowItemId, newX, newY));

// This method is almost identical to below "flowItemCopyToIdZeroBuffer"
// Only difference is setting FlowItemQty to "0" vs "null"
// This is called on Duplicate node
export const flowItemCopyToIdZero =
    (flowItemId: number, newflowId: ?number) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();

        // Make new ID and record it
        const newFlowItemId = _makeNewId(state, "flowItems");

        // Make a copy of the FlowItem and update its FlowItemId and FlowId fields
        dispatch(_copyThingToNewId("flowItems", flowItemId, newFlowItemId));

        const updates: { [string]: any } = {
            FlowItemId: newFlowItemId,
        };

        // Update FlowItem values
        // Always clear out Tracking ID fields on a copy
        updates.CustomText = null;
        updates.AudienceID = null;
        if (newflowId != null) {
            updates.FlowId = newflowId;
            updates.FlowItemQty = 0;
            updates.IsRunning = 0;
        }
        dispatch(updateMultipleAttribute("flowItems", newFlowItemId, updates));

        return newFlowItemId;
    };
top.RDX.flowItemCopyToIdZero = (flowItemId, newFlowId) =>
    top.store.dispatch(flowItemCopyToIdZero(flowItemId, newFlowId));

// This method is almost identical to above "flowItemCopyToIdZero"
// Only difference is setting FlowItemQty to "null" vs "0"
// This is called on Copy/Paste a node
export const flowItemCopyToIdZeroBuffer =
    (flowItemId: number, newflowId: ?number) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();

        // Make new ID and record it
        const newFlowItemId = _makeNewId(state, "flowItems");

        // Make a copy of the FlowItem and update its FlowItemId and FlowId fields
        dispatch(_copyThingToNewId("flowItems", flowItemId, newFlowItemId));

        const updates: { [string]: any } = {
            FlowItemId: newFlowItemId,
        };

        // Update FlowItem values
        // Always clear out Tracking ID fields on a copy
        updates.CustomText = null;
        updates.AudienceID = null;
        if (newflowId != null) {
            updates.FlowId = newflowId;
            updates.FlowItemQty = null;
            updates.IsRunning = 0;
        }
        dispatch(updateMultipleAttribute("flowItems", newFlowItemId, updates));

        return newFlowItemId;
    };
top.RDX.flowItemCopyToIdZeroBuffer = (flowItemId, newFlowId) =>
    top.store.dispatch(flowItemCopyToIdZeroBuffer(flowItemId, newFlowId));

import {
    crosstabFieldKeysStringToArray,
    crosstabFieldKeysArrayToString,
    measureFieldsArrayToString,
    measureFieldsStringToArray,
} from "../helpers/crosstabHelper";

export const removeFlowDimensionField =
    (fieldKey: number, flowReportId: number, isExportReport: boolean) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        let report = isExportReport ? state.flowExportReports.byId[flowReportId] : state.flowReports.byId[flowReportId];
        const oldString = report.DimensionFieldKeys;
        const oldArray = crosstabFieldKeysStringToArray(oldString);
        const newArray = oldArray.filter(x => x != fieldKey);
        const newString = crosstabFieldKeysArrayToString(newArray);
        if (isExportReport) {
            dispatch(updateAttribute("flowExportReports", flowReportId, "DimensionFieldKeys", newString));
        } else {
            dispatch(updateAttribute("flowReports", flowReportId, "DimensionFieldKeys", newString));
        }
    };
top.RDX.removeFlowDimensionField = (fieldKey, flowReportId, isExportReport) =>
    top.store.dispatch(removeFlowDimensionField(fieldKey, flowReportId, isExportReport));

export const removeFlowDimensionMeasureField =
    (fieldKey: number, flowReportId: number, isExportReport: boolean, flowItemId: number) =>
    (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        let report = isExportReport ? state.flowExportReports.byId[flowReportId] : state.flowReports.byId[flowReportId];
        const oldString = report.DimensionFieldKeys;
        const oldArray = measureFieldsStringToArray(oldString);
        const newArray = oldArray.filter(x => x.fieldKey != fieldKey);
        const newString = measureFieldsArrayToString(newArray);
        if (flowItemId) {
            dispatch(disableFlowReportTabs(flowItemId));
        }
        if (isExportReport) {
            dispatch(updateAttribute("flowExportReports", flowReportId, "DimensionFieldKeys", newString));
        } else {
            dispatch(updateAttribute("flowReports", flowReportId, "DimensionFieldKeys", newString));
        }
    };
top.RDX.removeFlowDimensionMeasureField = (fieldKey, flowReportId, isExportReport, flowItemId) =>
    top.store.dispatch(removeFlowDimensionMeasureField(fieldKey, flowReportId, isExportReport, flowItemId));

export const removeFlowDimensionFromCannedReport =
    (fieldKey: string, flowReportId: number) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        let report = state.flowCannedReports.byId[flowReportId];
        const oldString = report.DimensionFieldKeys;
        const oldArray = measureFieldsStringToArray(oldString);
        const newArray = oldArray.filter(x => x.columnalias != fieldKey);
        const newString = measureFieldsArrayToString(newArray);
        dispatch(updateAttribute("flowCannedReports", flowReportId, "DimensionFieldKeys", newString));
    };
top.RDX.removeFlowDimensionFromCannedReport = (fieldKey, flowReportId) =>
    top.store.dispatch(removeFlowDimensionFromCannedReport(fieldKey, flowReportId));

export const removeFlowMeasureField =
    (fieldKey: number, flowReportId: number, isExportReport: boolean) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        let report = isExportReport ? state.flowExportReports.byId[flowReportId] : state.flowReports.byId[flowReportId];
        const oldString = report.MeasureFieldKeys;
        const oldArray = measureFieldsStringToArray(oldString);
        const newArray = oldArray.filter(x => x.fieldKey != fieldKey);
        const newString = measureFieldsArrayToString(newArray);
        if (isExportReport) {
            dispatch(updateAttribute("flowExportReports", flowReportId, "MeasureFieldKeys", newString));
        } else {
            dispatch(updateAttribute("flowReports", flowReportId, "MeasureFieldKeys", newString));
        }
    };
top.RDX.removeFlowMeasureField = (fieldKey, flowReportId, isExportReport) =>
    top.store.dispatch(removeFlowMeasureField(fieldKey, flowReportId, isExportReport));

export const removeFlowMeasureFieldByIndex =
    (index: number, flowReportId: number, isExportReport: boolean, flowItemId: number) =>
    (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        let report = isExportReport ? state.flowExportReports.byId[flowReportId] : state.flowReports.byId[flowReportId];
        const oldString = report.MeasureFieldKeys;
        const oldArray = measureFieldsStringToArray(oldString);
        if (flowItemId) {
            dispatch(disableFlowReportTabs(flowItemId));
        }
        // Delete at most 1 element that matches the action's index
        const newArray = clone(oldArray);
        if (index > -1) {
            newArray.splice(index, 1);
        }

        const newString = measureFieldsArrayToString(newArray);
        if (isExportReport) {
            dispatch(updateAttribute("flowExportReports", flowReportId, "MeasureFieldKeys", newString));
        } else {
            dispatch(updateAttribute("flowReports", flowReportId, "MeasureFieldKeys", newString));
        }
    };
top.RDX.removeFlowMeasureFieldByIndex = (fieldKey, flowReportId, isExportReport, flowItemId) =>
    top.store.dispatch(removeFlowMeasureFieldByIndex(fieldKey, flowReportId, isExportReport, flowItemId));

export const addFlowDimensionField =
    (fieldKey: number, flowReportId: number) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        let report = state.flowReports.byId[flowReportId];
        const oldString = report.DimensionFieldKeys;
        const oldArray = crosstabFieldKeysStringToArray(oldString);

        // Don't add if it's already there
        if (oldArray.includes(fieldKey) || oldArray.includes(parseInt(fieldKey))) {
            return state;
        }

        const newArray = oldArray.concat([fieldKey]);
        const newString = crosstabFieldKeysArrayToString(newArray);
        dispatch(updateAttribute("flowReports", flowReportId, "DimensionFieldKeys", newString));
    };
top.RDX.addFlowDimensionField = (fieldKey, flowReportId) =>
    top.store.dispatch(addFlowDimensionField(fieldKey, flowReportId));

export const addFlowDimensionObject =
    (objectId: number, flowExportReportId: number) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        let report = state.flowExportReports.byId[flowExportReportId];
        const oldString = report.DimensionFieldKeys;
        const oldArray = crosstabFieldKeysStringToArray(oldString);

        // Don't add if it's already there
        if (oldArray.includes(objectId) || oldArray.includes(parseInt(objectId))) {
            return state;
        }

        const newArray = oldArray.concat([objectId]);
        const newString = crosstabFieldKeysArrayToString(newArray);
        dispatch(updateAttribute("flowExportReports", flowExportReportId, "DimensionFieldKeys", newString));
    };
top.RDX.addFlowDimensionObject = (fieldKey, flowExportReportId) =>
    top.store.dispatch(addFlowDimensionObject(fieldKey, flowExportReportId));

// Add measure field that is an actual fieldd
import { CrosstabMeasureType } from "../types/types";
export const flowReportAddMeasureField =
    (fieldKey: number, itemId: number, isExportReport: boolean, flowItemId: number) =>
    (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        let report = isExportReport ? state.flowExportReports.byId[itemId] : state.flowReports.byId[itemId];
        const measureFieldsString = report.MeasureFieldKeys;
        const measureFields = measureFieldsStringToArray(measureFieldsString);
        // CalculatedFieldType FieldEquality can have two fields
        const measureTypeFieldEquality =
            measureFields.filter(x => x.measureType == CrosstabMeasureType.FIELD_EQUALITY).length > 0;

        const measureTypeDateDiff =
            !measureTypeFieldEquality &&
            measureFields.filter(x => x.measureType == CrosstabMeasureType.FIELD_DIFF).length > 0;

        const fields = state.fields;
        const fieldToAdd = fields.byId[fieldKey];
        // we already have a field so need to compare the field types
        if (measureTypeFieldEquality || measureTypeDateDiff) {
            if (measureTypeDateDiff && fieldToAdd.fieldDataType != "D" && fieldToAdd.fieldDataType != "N") {
                h.error("Compare options is for date/numeric fields only.");
                return;
            }
            if (measureFields.length == 2) {
                const fieldA = fields.byId[measureFields[1].fieldKey];

                if (fieldA.fieldDataType != fieldToAdd.fieldDataType) {
                    h.error("Can't compare fields with different data types.");
                    return;
                }
                if (fieldA.id == fieldToAdd.id) {
                    h.error("Field already selected, choose another field to compare.");
                    return;
                }
            } else if (measureFields.length > 2) {
                h.error("Can't compare more than two fields.");
                return;
            }
        }
        dispatch(disableFlowReportTabs(flowItemId));

        if (measureTypeDateDiff) {
            const oldString = report.MeasureFieldKeys;
            const oldArray = measureFieldsStringToArray(oldString);

            const newArray = clone(oldArray);
            if (!newArray[0].dateDiffOptions) {
                newArray[0].dateDiffOptions = {
                    fieldDataType: fieldToAdd.fieldDataType,
                };
            } else {
                newArray[0].dateDiffOptions.fieldDataType = fieldToAdd.fieldDataType;
            }

            const newString = measureFieldsArrayToString(newArray);
            if (isExportReport) {
                dispatch(updateAttribute("flowExportReports", itemId, "MeasureFieldKeys", newString));
            } else {
                dispatch(updateAttribute("flowReports", itemId, "MeasureFieldKeys", newString));
            }
        }

        const oldString = report.MeasureFieldKeys;
        const oldArray = measureFieldsStringToArray(oldString);

        const newArray = oldArray.concat([{ fieldKey, aggregationOperator: null }]);

        const newString = measureFieldsArrayToString(newArray);
        if (isExportReport) {
            dispatch(updateAttribute("flowExportReports", itemId, "MeasureFieldKeys", newString));
        } else {
            dispatch(updateAttribute("flowReports", itemId, "MeasureFieldKeys", newString));
        }
    };
top.RDX.flowReportAddMeasureField = (fieldKey, flowReportId, isExportReport, flowitemId) =>
    top.store.dispatch(flowReportAddMeasureField(fieldKey, flowReportId, isExportReport, flowitemId));

export const addFlowDimensionMeasureField =
    (fieldKey: number, itemId: number, isExportReport: boolean, flowItemId: number) =>
    (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        let report = isExportReport ? state.flowExportReports.byId[itemId] : state.flowReports.byId[itemId];
        const measureFieldsString = report.DimensionFieldKeys;
        const measureFields = measureFieldsStringToArray(measureFieldsString);
        let canAdd = true;
        measureFields.forEach(x => {
            if (x.fieldKey == fieldKey) {
                h.error("This field has already been added.");
                canAdd = false;
            }
        });
        if (!canAdd) {
            return;
        }
        // CalculatedFieldType FieldEquality can have two fields
        const measureTypeFieldEquality =
            measureFields.filter(x => x.measureType == CrosstabMeasureType.FIELD_EQUALITY).length > 0;

        const measureTypeDateDiff =
            !measureTypeFieldEquality &&
            measureFields.filter(x => x.measureType == CrosstabMeasureType.FIELD_DIFF).length > 0;

        const fields = state.fields;
        const fieldToAdd = fields.byId[fieldKey];
        // we already have a field so need to compare the field types
        if (measureTypeFieldEquality || measureTypeDateDiff) {
            if (measureTypeDateDiff && fieldToAdd.fieldDataType != "D" && fieldToAdd.fieldDataType != "N") {
                h.error("Compare options is for date/numeric fields only.");
                return;
            }
            if (measureFields.length == 2) {
                const fieldA = fields.byId[measureFields[1].fieldKey];

                if (fieldA.fieldDataType != fieldToAdd.fieldDataType) {
                    h.error("Can't compare fields with different data types.");
                    return;
                }
                if (fieldA.id == fieldToAdd.id) {
                    h.error("Field already selected, choose another field to compare.");
                    return;
                }
            } else if (measureFields.length > 2) {
                h.error("Can't compare more than two fields.");
                return;
            }
        }
        dispatch(disableFlowReportTabs(flowItemId));

        if (measureTypeDateDiff) {
            const oldString = report.DimensionFieldKeys;
            const oldArray = measureFieldsStringToArray(oldString);

            const newArray = clone(oldArray);
            if (!newArray[0].dateDiffOptions) {
                newArray[0].dateDiffOptions = {
                    fieldDataType: fieldToAdd.fieldDataType,
                };
            } else {
                newArray[0].dateDiffOptions.fieldDataType = fieldToAdd.fieldDataType;
            }

            const newString = measureFieldsArrayToString(newArray);
            if (isExportReport) {
                dispatch(updateAttribute("flowExportReports", itemId, "DimensionFieldKeys", newString));
            } else {
                dispatch(updateAttribute("flowReports", itemId, "DimensionFieldKeys", newString));
            }
        }

        const oldString = report.DimensionFieldKeys;
        const oldArray = measureFieldsStringToArray(oldString);

        const newArray = oldArray.concat([{ fieldKey, windowOperator: null }]);

        const newString = measureFieldsArrayToString(newArray);
        if (isExportReport) {
            dispatch(updateAttribute("flowExportReports", itemId, "DimensionFieldKeys", newString));
        } else {
            dispatch(updateAttribute("flowReports", itemId, "DimensionFieldKeys", newString));
        }
    };
top.RDX.addFlowDimensionMeasureField = (fieldKey, flowReportId, isExportReport, flowitemId) =>
    top.store.dispatch(addFlowDimensionMeasureField(fieldKey, flowReportId, isExportReport, flowitemId));

// export const addFlowDimensionToCannedReport =
//     (fieldKey: number, itemId: number, flowItemId: number) =>
export const addFlowDimensionToCannedReport =
    (field: any, itemId: number) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        let report = state.flowCannedReports.byId[itemId];
        const measureFieldsString = report.DimensionFieldKeys;

        let measureFields = [];
        if (measureFieldsString != "") {
            measureFields = JSON.parse(measureFieldsString);
        }
        let canAdd = true;

        if (!Array.isArray(measureFields)) {
            Object.values(measureFields);
        }

        if (Array.isArray(measureFields)) {
            measureFields.forEach(x => {
                if (x.columnname == field.ColumnName) {
                    h.error("This field has already been added.");
                    canAdd = false;
                }
            });
        }

        if (!canAdd) {
            return;
        }

        if (Array.isArray(measureFields)) {
            measureFields.push({
                columnname: field.ColumnName,
                columnalias: field.ColumnAlias,
            });
        } else {
            measureFields = {
                columnname: field.ColumnName,
                columnalias: field.ColumnAlias,
            };
        }
        const newString = JSON.stringify(measureFields);
        dispatch(updateAttribute("flowCannedReports", itemId, "DimensionFieldKeys", newString));
    };
top.RDX.addFlowDimensionToCannedReport = (field, flowReportId, flowitemId) =>
    top.store.dispatch(addFlowDimensionToCannedReport(field, flowReportId, flowitemId));
// top.RDX.addFlowDimensionToCannedReport = (fieldKey, flowReportId, flowitemId) =>
//     top.store.dispatch(addFlowDimensionToCannedReport(fieldKey, flowReportId, flowitemId));

const findTopLevelFlowFolderForUser = (state: any, userId: number) => {
    let folderId = 0;
    const folders: any = Object.values(state.folders.flowFolders);
    const myTopLevelFolders = folders.filter(x => x.userId == userId && x.folderType == "W");
    const companyFolders = folders.filter(x => x.userId == 0 && x.folderType == "W");
    if (myTopLevelFolders.length > 0) {
        return myTopLevelFolders[0].id;
    }
    if (companyFolders.length > 0) {
        companyFolders.forEach(x => {
            x.children.forEach(z => {
                if (z.userId == userId) {
                    folderId = z.id;
                    return folderId;
                }
            });
        });
    }
    return folderId;
};

export const flowCopyToIdZero =
    (flowId: number, newOwnerId: number = 0, copyOfferCodes: boolean = false, copyVariables: boolean = false) =>
    (dispatch: Dispatch, getState: GetState) => {
        let state = getState();
        const flow: ?Flow = state.flows.byId[flowId];

        if (flow == null) {
            return;
        }

        // (1) Copy Flow
        const newFlowId = 0;
        dispatch(_copyThingToNewId("flows", flowId, newFlowId));

        // (1.05) Set ID Unlock the new flow
        const updates: { [string]: any } = {
            FlowId: newFlowId,
            IsLocked: false,
            IsLockedPermanently: false,
            FlowLastCalculated: null,
            FlowStart: null,
            FlowStop: null,
            ScheduleId: null,
        };

        // (1.1) Change the owner info and put the flow in the new owner's folder, if the owner is different
        if (newOwnerId > 0 && newOwnerId != flow.FlowCreatedBy) {
            updates["FlowCreatedBy"] = newOwnerId;
            updates["FlowUpdatedByBy"] = newOwnerId;

            const folderId = findTopLevelFlowFolderForUser(state, newOwnerId);
            if (folderId > 0) {
                updates["FlowFolderId"] = folderId;
            }
        }

        // (1.9) Send out updates
        dispatch(updateMultipleAttribute("flows", newFlowId, updates));

        // (2) Copy FlowItems, which depend on Flows
        // except for SegmentSplits
        const newItemIdFor = {};

        const flowItems: Array<FlowItem> = Object.values(state.flowItems.byId);
        const theseFlowItemIds = flowItems.filter(x => x.FlowId == flowId).map(x => x.FlowItemId);
        for (const flowItemId of theseFlowItemIds) {
            const newFlowItemId = dispatch(flowItemCopyToIdZero(flowItemId, newFlowId));
            newItemIdFor[flowItemId] = newFlowItemId;
        }

        // (3) Copy FlowRelations, which depend on FlowItems and are special because they contain two FlowItems
        const flowRelationParentLabels: Array<any> = Object.values(state.flowRelationParentLabels.byId);

        const flowRelations: Array<FlowRelation> = Object.values(state.flowRelations.byId);
        const theseFlowRelationIds = flowRelations
            .filter(x => theseFlowItemIds.includes(x.ParentFlowItemId) || theseFlowItemIds.includes(x.ChildFlowItemId))
            .map(x => x.FlowRelationId);
        for (const flowRelationId of theseFlowRelationIds) {
            // Make new ID
            state = getState();
            const newFlowRelationId = _makeNewId(state, "flowRelations");

            // Find the relation
            const relation = state.flowRelations.byId[flowRelationId];
            if (relation == null) {
                continue;
            }
            const newParentId = newItemIdFor[relation.ParentFlowItemId] || relation.ParentFlowItemId;
            const newChildId = newItemIdFor[relation.ChildFlowItemId] || relation.ChildFlowItemId;

            if (flowRelationParentLabels.length > 0) {
                if (flowRelationParentLabels.filter(x => x.FlowRelationId == flowRelationId).length > 0) {
                    let parentLabel = flowRelationParentLabels.filter(x => x.FlowRelationId == flowRelationId)[0];
                    const newFlowRelationParentLabelId = _makeNewId(state, "flowRelationParentLabels");
                    dispatch(
                        _copyThingToNewId(
                            "flowRelationParentLabels",
                            parentLabel.FlowRelationParentLabelId,
                            newFlowRelationParentLabelId
                        )
                    );
                    dispatch(
                        updateAttribute(
                            "flowRelationParentLabels",
                            newFlowRelationParentLabelId,
                            "FlowRelationParentLabelId",
                            newFlowRelationParentLabelId
                        )
                    );
                    dispatch(
                        updateAttribute(
                            "flowRelationParentLabels",
                            newFlowRelationParentLabelId,
                            "FlowRelationId",
                            newFlowRelationId
                        )
                    );
                }
            }

            // Copy and make the updates
            dispatch(_copyThingToNewId("flowRelations", flowRelationId, newFlowRelationId));
            dispatch(updateAttribute("flowRelations", newFlowRelationId, "FlowRelationId", newFlowRelationId));
            dispatch(updateAttribute("flowRelations", newFlowRelationId, "ParentFlowItemId", newParentId));
            dispatch(updateAttribute("flowRelations", newFlowRelationId, "ChildFlowItemId", newChildId));
        }

        // (4) Copy all others, which depend on FlowItems, things like FlowSplit and FlowFilter
        // Assuming it has a "FlowItemId" or a "FlowId"
        const alreadyDone = ["flows", "flowItems", "flowRelations"];
        const otherEntities = prefixesAndEntities.filter(
            x => !alreadyDone.includes(x.stateKey) && x.flowItemType != null
        );
        for (const entityRow of otherEntities) {
            const stateKey = entityRow.stateKey;

            const idName = entityRow.id;

            const theseThings: Array<any> = Object.values(state[stateKey].byId);
            const theseIds = theseThings
                .filter(x => theseFlowItemIds.includes(x.FlowItemId) || x.FlowId == flowId)
                .map(x => x[idName]);

            for (const thisId of theseIds) {
                // Make new ID
                state = getState();
                const newFlowThingId = _makeNewId(state, stateKey);

                // Find the thing
                const thing = state[stateKey].byId[thisId];
                const newFlowItemId = newItemIdFor[thing.FlowItemId] || thing.FlowItemId;

                let shouldCopyThing = true;
                if (
                    (stateKey == "flowClientVariables" && !copyVariables) ||
                    (stateKey == "flowItemClientVariables" && !copyOfferCodes) ||
                    stateKey == "flowSegmentSplitOffers" ||
                    stateKey == "flowScriptResults"
                ) {
                    shouldCopyThing = false;
                }

                if (shouldCopyThing) {
                    // Copy and make updates
                    if (stateKey == "flowSegmentSplits") {
                        const theseSplitOfferCodeThings: Array<FlowSegmentSplitOffer> = Object.values(
                            state["flowSegmentSplitOffers"].byId
                        );
                        const theseSplitOfferCodeIds = theseSplitOfferCodeThings.filter(
                            x => x.SegmentSplitId == thisId
                        );
                        for (const thisOfferCodeID of theseSplitOfferCodeIds) {
                            state = getState();
                            const newSSOId = _makeNewId(state, "flowSegmentSplitOffers");
                            const oldSSOId = thisOfferCodeID.SegmentSplitOfferId;
                            dispatch(_copyThingToNewId("flowSegmentSplitOffers", oldSSOId, newSSOId));
                            dispatch(
                                updateAttribute("flowSegmentSplitOffers", newSSOId, "SegmentSplitId", newFlowThingId)
                            );
                            dispatch(
                                updateAttribute(
                                    "flowSegmentSplitOffers",
                                    newSSOId,
                                    "SegmentSplitOfferId",
                                    newFlowThingId
                                )
                            );
                            dispatch(updateAttribute("flowSegmentSplitOffers", newSSOId, "FlowItemId", newFlowItemId));
                        }
                    }

                    dispatch(_copyThingToNewId(stateKey, thisId, newFlowThingId));

                    dispatch(updateAttribute(stateKey, newFlowThingId, idName, newFlowThingId));

                    if (thing.FlowItemId) {
                        dispatch(updateAttribute(stateKey, newFlowThingId, "FlowItemId", newFlowItemId));

                        // For trace
                        dispatch(updateAttribute("flowItems", newFlowItemId, "OldFlowItemId", thing.FlowItemId));
                    }
                    if (thing.FlowId) {
                        dispatch(updateAttribute(stateKey, newFlowThingId, "FlowId", newFlowId));
                    }

                    state = getState();
                    if (thing.ParentFlowItemId) {
                        const newParentFlowItemId = newItemIdFor[thing.ParentFlowItemId];
                        dispatch(updateAttribute(stateKey, newFlowThingId, "ParentFlowItemId", newParentFlowItemId));
                    }
                    if (thing.ChildFlowItemId) {
                        const newChildFlowItemId = newItemIdFor[thing.ChildFlowItemId];
                        dispatch(updateAttribute(stateKey, newFlowThingId, "ChildFlowItemId", newChildFlowItemId));
                    }
                }
            }
        }
    };
top.RDX.flowCopyToIdZero = (flowId, newOwnerId, copyOfferCodes, copyVariables) =>
    top.store.dispatch(flowCopyToIdZero(flowId, newOwnerId, copyOfferCodes, copyVariables));
// See git history for comments showing how the negative ids are applied to both flows and flowItems when copying an entire flow.
///////////////// END OVERWRITE=OFF SECTION //////////////////////////

export const flowCopyToIdZeroFromBuffer =
    (
        currentFlowId: number,
        newFlowId: number,
        clipboardNodes: Array<number>,
        clipboardRelations: Array<number>,
        pasteOffsetX: number,
        pasteOffsetY: number
    ) =>
    (dispatch: Dispatch, getState: GetState) => {
        // do we need to pass the selected nodes? is that in state?
        // do we care about the relations?

        let flowId = currentFlowId;
        let state = getState();
        const flow: ?Flow = state.flows.byId[flowId];

        if (flow == null) {
            return;
        }
        const newItemIdFor = {};
        const flowItemsRaw: Array<FlowItem> = Object.values(state.flowItems.byId);
        const flowItems = flowItemsRaw.filter(item => clipboardNodes.includes(item.FlowItemId));
        flowId = flowItems[0].FlowId;
        const theseFlowItemIds = flowItems.filter(x => x.FlowId == flowId).map(x => x.FlowItemId);
        for (const flowItemId of theseFlowItemIds) {
            const newFlowItemId = dispatch(flowItemCopyToIdZeroBuffer(flowItemId, newFlowId));
            newItemIdFor[flowItemId] = newFlowItemId;
            const flowItemINeed = state.flowItems.byId[flowItemId];
            let newX = flowItemINeed.x + pasteOffsetX;
            let newY = flowItemINeed.y + pasteOffsetY;
            dispatch(updateAttribute("flowItems", newFlowItemId, "x", newX));
            dispatch(updateAttribute("flowItems", newFlowItemId, "y", newY));
            dispatch(updateAttribute("flowItems", newFlowItemId, "HasResultTable", false));
        }

        const flowRelationParentLabels: Array<any> = Object.values(state.flowRelationParentLabels.byId);
        const flowRelations: Array<FlowRelation> = Object.values(state.flowRelations.byId);
        const theseFlowRelationIds = flowRelations
            .filter(x => clipboardRelations.includes(x.FlowRelationId))
            .map(x => x.FlowRelationId);
        for (const flowRelationId of theseFlowRelationIds) {
            // Make new ID
            state = getState();
            const newFlowRelationId = _makeNewId(state, "flowRelations");

            // Find the relation
            const relation = state.flowRelations.byId[flowRelationId];
            if (relation == null) {
                continue;
            }
            const newParentId = newItemIdFor[relation.ParentFlowItemId] || relation.ParentFlowItemId;
            const newChildId = newItemIdFor[relation.ChildFlowItemId] || relation.ChildFlowItemId;

            if (flowRelationParentLabels.length > 0) {
                if (flowRelationParentLabels.filter(x => x.FlowRelationId == flowRelationId).length > 0) {
                    let parentLabel = flowRelationParentLabels.filter(x => x.FlowRelationId == flowRelationId)[0];
                    const newFlowRelationParentLabelId = _makeNewId(state, "flowRelationParentLabels");
                    dispatch(
                        _copyThingToNewId(
                            "flowRelationParentLabels",
                            parentLabel.FlowRelationParentLabelId,
                            newFlowRelationParentLabelId
                        )
                    );
                    dispatch(
                        updateAttribute(
                            "flowRelationParentLabels",
                            newFlowRelationParentLabelId,
                            "FlowRelationParentLabelId",
                            newFlowRelationParentLabelId
                        )
                    );
                    dispatch(
                        updateAttribute(
                            "flowRelationParentLabels",
                            newFlowRelationParentLabelId,
                            "FlowRelationId",
                            newFlowRelationId
                        )
                    );
                }
            }

            // Copy and make the updates
            dispatch(_copyThingToNewId("flowRelations", flowRelationId, newFlowRelationId));
            dispatch(updateAttribute("flowRelations", newFlowRelationId, "FlowRelationId", newFlowRelationId));
            dispatch(updateAttribute("flowRelations", newFlowRelationId, "ParentFlowItemId", newParentId));
            dispatch(updateAttribute("flowRelations", newFlowRelationId, "ChildFlowItemId", newChildId));
        }

        const alreadyDone = ["flows", "flowItems", "flowRelations"];
        const otherEntities = prefixesAndEntities.filter(
            x => !alreadyDone.includes(x.stateKey) && x.flowItemType != null
        );
        for (const entityRow of otherEntities) {
            const stateKey = entityRow.stateKey;

            const idName = entityRow.id;

            const theseThingsRaw: Array<any> = Object.values(state[stateKey].byId);
            const theseThings: Array<any> = theseThingsRaw.filter(item => clipboardNodes.includes(item.FlowItemId));
            const theseIds = theseThings
                .filter(x => theseFlowItemIds.includes(x.FlowItemId) || x.FlowId == flowId)
                .map(x => x[idName]);

            for (const thisId of theseIds) {
                // Make new ID
                state = getState();
                const newFlowThingId = _makeNewId(state, stateKey);

                // Find the thing
                const thing = state[stateKey].byId[thisId];
                const newFlowItemId = newItemIdFor[thing.FlowItemId] || thing.FlowItemId;

                const mustHaveParent = ["flowMerges", "flowOfferMerges", "flowExportTradeDeskTemplateFields"].includes(
                    stateKey
                );
                const parentIsBeingCopied = clipboardNodes.includes(thing.ParentFlowItemId);

                let shouldCopyThing = true;
                if (stateKey == "flowSegmentSplitOffers" || stateKey == "flowScriptResults") {
                    shouldCopyThing = false;
                }
                if (mustHaveParent && !parentIsBeingCopied) {
                    shouldCopyThing = false;
                }

                if (shouldCopyThing) {
                    // Copy and make updates
                    if (stateKey == "flowSegmentSplits") {
                        const theseSplitOfferCodeThings: Array<FlowSegmentSplitOffer> = Object.values(
                            state["flowSegmentSplitOffers"].byId
                        );
                        const theseSplitOfferCodeIds = theseSplitOfferCodeThings.filter(
                            x => x.SegmentSplitId == thisId
                        );
                        for (const thisOfferCodeID of theseSplitOfferCodeIds) {
                            let state2 = getState();
                            const newSSOId = _makeNewId(state2, "flowSegmentSplitOffers");
                            const oldSSOId = thisOfferCodeID.SegmentSplitOfferId;
                            dispatch(_copyThingToNewId("flowSegmentSplitOffers", oldSSOId, newSSOId));
                            dispatch(
                                updateAttribute("flowSegmentSplitOffers", newSSOId, "SegmentSplitId", newFlowThingId)
                            );
                            dispatch(
                                updateAttribute("flowSegmentSplitOffers", newSSOId, "SegmentSplitOfferId", newSSOId)
                            );

                            dispatch(updateAttribute("flowSegmentSplitOffers", newSSOId, "FlowItemId", newFlowItemId));
                        }
                    }

                    dispatch(_copyThingToNewId(stateKey, thisId, newFlowThingId));

                    dispatch(updateAttribute(stateKey, newFlowThingId, idName, newFlowThingId));

                    if (thing.FlowItemId) {
                        dispatch(updateAttribute(stateKey, newFlowThingId, "FlowItemId", newFlowItemId));

                        // For trace
                        dispatch(updateAttribute("flowItems", newFlowItemId, "OldFlowItemId", thing.FlowItemId));
                    }
                    if (thing.FlowId) {
                        dispatch(updateAttribute(stateKey, newFlowThingId, "FlowId", newFlowId));
                    }

                    if (thing.ParentFlowItemId) {
                        const newParentFlowItemId = newItemIdFor[thing.ParentFlowItemId];
                        dispatch(updateAttribute(stateKey, newFlowThingId, "ParentFlowItemId", newParentFlowItemId));
                    }
                    if (thing.ChildFlowItemId) {
                        const newChildFlowItemId = newItemIdFor[thing.ChildFlowItemId];
                        dispatch(updateAttribute(stateKey, newFlowThingId, "ChildFlowItemId", newChildFlowItemId));
                    }
                }
            }
        }
    };
top.RDX.flowCopyToIdZeroFromBuffer = (
    flowId,
    newOwnerId,
    clipboardNodes,
    clipboardRelations,
    pasteOffsetX,
    pasteOffsetY
) =>
    top.store.dispatch(
        flowCopyToIdZeroFromBuffer(flowId, newOwnerId, clipboardNodes, clipboardRelations, pasteOffsetX, pasteOffsetY)
    );
// See git history for comments showing how the negative ids are applied to both flows and flowItems when copying an entire flow.
///////////////// END OVERWRITE=OFF SECTION //////////////////////////

export const requestMoveFlowToFolder =
    (flowId: number, folderId: number) => (dispatch: Dispatch, getState: GetState) => {
        // Optimistic update, first update client side only
        const state = getState();

        if (
            state.session.userId != state.flows.byId[flowId].FlowCreatedBy &&
            !state.flows.byId[flowId].IsLockedPermanently
        ) {
            h.error("Cannot move another user's flow.");
            return;
        }
        dispatch(updateAttribute("flows", flowId, "FlowFolderId", folderId));
        // Then make request to server
        dispatch(incAjaxCount());
        const moveUrl =
            "/Flows/MoveFlow?" +
            h.serialize({
                flowId,
                folderId,
            });

        fetch(moveUrl, { method: "POST", credentials: "same-origin" })
            .then(h.checkStatus)
            .then(toJson)
            .then(() => {
                dispatch(decAjaxCount());
            })
            .catch(error => {
                dispatch(decAjaxCount());
                h.error("Error moving flow", error);
            });
    };

export const addItemEndpoint = (flowId: number, flowItemId: number) => (dispatch: Dispatch, getState: GetState) => {
    let state = getState();
    const thisFlowItem: ?FlowItem = state.flowItems.byId[flowItemId];
    if (thisFlowItem == null) {
        return;
    }

    const thing: FlowItemEndpoint = {
        Id: 0,
        FlowId: flowId,
        FlowItemId: flowItemId,
        EndpointId: 0,
    };
    dispatch(addThing("flowItemEndpoints", thing));
    dispatch(setHasUnsavedChanges());
};

// Disable and re-enable flow items and their children if requested
export const disableEnableItemAndChildren =
    (flowItemId: number, enable: boolean, children: boolean = true) =>
    (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const childrenIndex = getFlowItemChildrenIndex(state);
        const itemsById = state.flowItems.byId;
        dispatch(updateFlowShouldValidate(state.selected.flow, true));

        const disableEnable = flowItemId => {
            // Return early if item not found
            const item: ?FlowItem = itemsById[flowItemId];
            if (item == null) {
                h.error("There was an error disabling the node.");
                return;
            }

            // Update Parent
            dispatch(updateAttribute("flowItems", flowItemId, "IsActive", enable));

            // Update Children if requested
            if (children) {
                // Update all my children recursively
                const childrenIds = childrenIndex[flowItemId] || [];
                for (const childId of childrenIds) {
                    disableEnable(childId);
                }
            }
        };
        disableEnable(flowItemId);
        dispatch(updateFlowShouldValidate(state.selected.flow, false));
    };

//begin - flow-export-distribution-platforms
export const requestFlowExportDestinationPartners = (destinationId: number) => (dispatch: Dispatch) => {
    const url = "/SingleView2/GetFlowExportDestinationPartners?destinationId=" + destinationId;

    dispatch(incAjaxCount());
    fetch(url, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            dispatch({
                type: "UPDATE_DESTINATION_PARTNERS",
                destinationId,
                destinationPartners: data.partners,
            });
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error", error);
        });
};

export const addExportDistributionPlatform = (flowItemId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const thisFlowItem: ?FlowItem = state.flowItems.byId[flowItemId];
    if (thisFlowItem == null) {
        return;
    }

    const thing: FlowExportDistributionPlatform = {
        FlowExportDistributionPlatformId: 0,
        FlowItemId: flowItemId,
        ContactName: "",
        ContactEmail: "",
        DestinationID: "",
        CPM: "",
        PartnerId: 0,
    };
    dispatch(addThing("flowExportDistributionPlatforms", thing));
    dispatch(setHasUnsavedChanges());
};

export const deleteFlowExportDistributionPlatform = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowExportDistributionPlatforms", id));
};

export const clearFlowExportDistributionPlatforms =
    (flowItemId: number) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const allDistributionPlatforms = getFlowExportDistributionPlatformsArray(state);
        const distributionPlatforms = allDistributionPlatforms.filter(x => x.FlowItemId == flowItemId);
        for (const distributionPlatform of distributionPlatforms) {
            dispatch(deleteFlowExportDistributionPlatform(distributionPlatform.FlowExportDistributionPlatformId));
        }
    };
//end flow-export-distribution-platforms

export const clearFlowExportDestinationVariables =
    (flowItemId: number, destinationId: number) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState(); //const flowItemClientVariables = getFlowItemClientVariablesForSelectedFlow(state);
        const flowItemCVs = getFlowItemClientVariablesForSelectedFlow(state);
        const flowItemClientVariables = flowItemCVs
            .filter((value, index, self) => self.findIndex(v => v.VariableId === value.VariableId) === index)
            //.filter(x => x.FlowItemId == parentRelation.ParentFlowItemId)
            .map(item => {
                const container = {};
                container.FlowItemClientVariableId = 0;
                container.VariableId = item.VariableId;
                container.FlowId = item.FlowId;
                container.FlowItemId = item.FlowItemId;
                container.ChildFlowItemId = null;
                return container;
            });
        if (!state.flowExportObjects.destinationVariables[destinationId]) {
            return;
        }
        const destinationVariableIds = state.flowExportObjects.destinationVariables[destinationId].map(
            x => x.VariableId
        );

        for (const id of destinationVariableIds) {
            const destClientVariables = flowItemClientVariables.filter(
                x => x.FlowItemId == flowItemId && x.VariableId == id
            );
            for (const clientVariable of destClientVariables) {
                dispatch(deleteFlowItemClientVariable(clientVariable.FlowItemClientVariableId));
            }
        }
    };

export const requestAllFlowExportDestinationOffers = (flowId: number) => (dispatch: Dispatch) => {
    const url = "/ClientVariable/GetAllDestinationOfferCodes?flowId=" + flowId;
    dispatch(incAjaxCount());
    request(url, { credentials: "same-origin" }, dispatch)
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            if (data) {
                if (data.destinationOffers) {
                    data.destinationOffers.forEach(x => {
                        dispatch({
                            type: "UPDATE_DESTINATION_OFFERS",
                            destinationId: x.DestinationId,
                            destinationOffers: x.offers,
                        });
                    });
                }

                if (data.destinationVariables) {
                    data.destinationVariables.forEach(x => {
                        dispatch({
                            type: "UPDATE_DESTINATION_VARIABLES",
                            destinationId: x.DestinationId,
                            destinationVariables: x.variables,
                        });
                    });
                }
            }
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error", error);
        });
};
export const requestFlowExportDestinationOffers = (destinationId: number) => (dispatch: Dispatch) => {
    const url = "/ClientVariable/GetDestinationOfferCodes?destinationId=" + destinationId;
    dispatch(incAjaxCount());
    fetch(url, { credentials: "same-origin" })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            dispatch({
                type: "UPDATE_DESTINATION_OFFERS",
                destinationId,
                destinationOffers: data.destinationOffers,
            });
            dispatch({
                type: "UPDATE_DESTINATION_VARIABLES",
                destinationId,
                destinationVariables: data.destinationVariables,
            });
            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error", error);
        });
};

export const clearFlowItemSVDedupes = (flowItemId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const flowSVDedupesArray = getFlowSVDedupesArray(state);
    const flowItemSVDedupes = flowSVDedupesArray.filter(x => x.FlowItemId == flowItemId);
    for (const flowSVDedupe of flowItemSVDedupes) {
        dispatch(deleteFlowSVDedupe(flowSVDedupe.FlowSVDedupeId));
    }
};

export const clearFlowExportOfferMerges = (flowItemId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const flowOfferMergesArray = getFlowOfferMergesArray(state);
    const flowItemOfferMerges = flowOfferMergesArray.filter(x => x.FlowItemId == flowItemId);
    for (const offerMerge of flowItemOfferMerges) {
        dispatch(deleteFlowOfferMerge(offerMerge.FlowOfferMergeId, false));
    }
};

export const updateFlowScriptAppendText = (appendText: string) => ({
    type: "UPDATE_FLOW_SCRIPT_APPEND_TEXT",
    appendText,
});

//begin flow-export-pivot-labels

export const addFlowRelationParentLabel = (flowRelationId: number, parentLabel: string) => (dispatch: Dispatch) => {
    const thing: FlowRelationParentLabel = {
        FlowRelationParentLabelId: 0,
        FlowRelationId: flowRelationId,
        ParentLabel: parentLabel,
    };
    dispatch(addThing("flowRelationParentLabels", thing));
    dispatch(setHasUnsavedChanges());
};

export const deleteFlowRelationParentLabel = (id: number) => (dispatch: Dispatch) => {
    dispatch(_deleteThing("flowRelationParentLabels", id));
};

export const clearFlowRelationParentLabels = (flowItemId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const allFlowRelations = getFlowRelationsArray(state);
    const flowRelationParentLabels = getFlowRelationsParentLabelsArray(state);

    const parentRelationsIds = allFlowRelations.filter(x => x.ChildFlowItemId == flowItemId).map(x => x.FlowRelationId);
    const flowRelationParentLabelsToDelete = flowRelationParentLabels.filter(x =>
        parentRelationsIds.includes(x.FlowRelationId)
    );
    for (const parentLabel of flowRelationParentLabelsToDelete) {
        dispatch(deleteFlowRelationParentLabel(parentLabel.FlowRelationParentLabelId));
    }
};

//end flow-export-pivot-labels

//begin field-filters-on-demand
import tryParseJSON from "../helpers/tryParseJSON";
import getFieldIdsFromRules from "../helpers/getFieldIdsFromRules";
export const requestFlowFieldFilters = (flowId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const fieldFilters = state.filters;
    const existingFilters = fieldFilters.map(f => f.id);

    let flowFieldIds = getFlowFieldIds(flowId, state);
    const fieldIdsNeeded = flowFieldIds.filter(x => !existingFilters.includes(x));

    //request any missing filter
    if (fieldIdsNeeded.length > 0) {
        dispatch(requestFieldFilters(fieldIdsNeeded));
    }
};

export const getFlowFieldIds = (flowId: number, state: any) => {
    const flowItems = getFlowItemsArray(state);
    const thisFlowItems = flowItems.filter(f => f.FlowId == flowId);

    let fieldIds: Array<string> = [];
    //flow items that contain query builder
    const filters = thisFlowItems.filter(f => f.FlowItemType == "filter");
    const cases = thisFlowItems.filter(f => f.FlowItemType == "case");
    const reports = thisFlowItems.filter(f => f.FlowItemType == "report");

    const flowFiltersById = getFlowFiltersByFlowItemId(state);
    for (const filter of filters) {
        const filterItem = flowFiltersById[filter.FlowItemId];
        const filterFields = getFlowFilterFieldIds(filterItem);
        if (filterFields.length > 0) {
            fieldIds = fieldIds.concat(filterFields);
        }
    }
    const flowCasesByItemId = getFlowCasesByFlowItemId(state);
    for (const flowCase of cases) {
        const caseItems = flowCasesByItemId[flowCase.FlowItemId];
        const caseFields = getFlowCaseFieldIds(caseItems);
        if (caseFields.length > 0) {
            fieldIds = fieldIds.concat(caseFields);
        }
    }
    const flowReportsByFlowItemId = getFlowReportsByFlowItemId(state);
    for (const report of reports) {
        const reportItem = flowReportsByFlowItemId[report.FlowItemId];
        const reportFields = getFlowReportFieldIds(reportItem);
        if (reportFields.length > 0) {
            fieldIds = fieldIds.concat(reportFields);
        }
    }
    if (fieldIds.length > 0) {
        //removes duplicates
        fieldIds = Array.from(new Set(fieldIds));
    }
    return fieldIds;
};

export const getFlowFilterFieldIds = (filterItem: FlowFilter): Array<string> => {
    if (filterItem && filterItem.FlowFilterCriteria) {
        //parse the criteria rules and get the field ids
        const rules = tryParseJSON(filterItem.FlowFilterCriteria);
        return getFieldIdsFromRules(rules);
    } else {
        return [];
    }
};

export const getFlowReportFieldIds = (reportItem: FlowReport): Array<string> => {
    if (reportItem && reportItem.FlowReportCriteria) {
        //parse the criteria rules and get the field ids
        const rules = tryParseJSON(reportItem.FlowReportCriteria);
        return getFieldIdsFromRules(rules);
    } else {
        return [];
    }
};

export const getFlowCaseFieldIds = (caseItems: Array<FlowCase>): Array<string> => {
    let caseFields: Array<string> = [];
    if (caseItems) {
        for (const caseItem of caseItems) {
            if (!caseItem.IsBalance && caseItem.FlowCaseCriteria) {
                //parse the criteria rules and get the field ids
                const rules = tryParseJSON(caseItem.FlowCaseCriteria);
                caseFields = caseFields.concat(getFieldIdsFromRules(rules));
            }
        }
    }
    return caseFields;
};
//end field-filters-on-demand

//Copy/Paste a node
export const updateClipboardNodes = (clipboardNodes: Array<number>) => ({
    type: "SET_CLIPBOARD_NODES",
    clipboardNodes,
});
//Selection Mode on Flow Canvas
export const toggleSelectionMode = (selectionMode: boolean) => ({
    type: "SET_SELECTION_MODE",
    selectionMode,
});

export const updateClipboardRelations = (clipboardRelations: Array<number>) => ({
    type: "SET_CLIPBOARD_RELATIONS",
    clipboardRelations,
});

export const nextMultiDestinationTab = (multiDestinationTab: number) => ({
    type: "SET_MULTI_DESTINATION_TAB",
    multiDestinationTab,
});

export const setBackupList = (backupList: Array<any>) => ({
    type: "SET_BACKUP_LIST",
    backupList,
});

export const UrlIdForSimpleFlow = (previousUrlIdForSimpleFlow: number) => ({
    type: "SET_PREVIOUS_URL_ID_SIMPLE_FLOW",
    previousUrlIdForSimpleFlow,
});

export const returnToDashboardForSimpleFlow = (returnToDashboard: number) => ({
    type: "SET_DASHBOARD_STATUS",
    returnToDashboard,
});

export const simpleFlowOverrideToDesign = (willOverride: boolean) => ({
    type: "SET_REDIRECT_OVERRIDE_SIMPLE_FLOW",
    overrideSimpleFlowRedirect: willOverride,
});

//start flow-bulk-actions
export const flowMultiSelectionAdd = (flowId: number) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    //check if flow has already been loaded, if not, load it, needed for execute validation!
    const flowItems = getFlowItemsArray(state);
    if (flowId > 0 && flowItems.filter(x => x.FlowId == flowId).length <= 0) {
        dispatch(requestFlow(flowId));
    }

    dispatch({
        type: "FLOW_MULTI_SELECTION_ADD",
        flowId,
    });
};

export const flowMultiSelectToggle = (isMultiSelectOn: boolean) => ({
    type: "FLOW_MULTI_SELECTION_TOGGLE",
    isMultiSelectOn,
});

export const flowMultiSelectionRemove = (flowId: number) => ({
    type: "FLOW_MULTI_SELECTION_REMOVE",
    flowId,
});

export const flowMultiSelectionClear = () => ({
    type: "FLOW_MULTI_SELECTION_CLEAR",
});

export const requestMoveFlowsToFolder =
    (flowIds: Array<number>, folderId: number) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const userId = state.session.userId;
        const folders = state.folders.flowFolders;
        const flowsById = state.flows.byId;

        const flowsArray: Array<Flow> = Object.values(flowsById);
        const selectedFlows = flowsArray.filter(x => flowIds.includes(x.FlowId));
        const folder = searchTree(folders, folderId);
        if (!folder || !selectedFlows) {
            return;
        }

        if (folder.isHistorical) {
            if (folder.userId != 0 || selectedFlows.filter(x => !x.IsLockedPermanently).length > 0) {
                h.error("Cannot move flows not locked into a historical folder.");
                return;
            }
        } else if (selectedFlows.filter(x => x.FlowCreatedBy != userId || x.IsLockedPermanently).length) {
            h.error("Cannot move another user's flows.");
            return;
        }

        // Optimistic update, first update client side only
        for (const flow of selectedFlows) {
            dispatch(updateAttribute("flows", flow.FlowId, "FlowFolderId", folderId));
        }
        // do the request only for ids that exist in the db
        const idsToUpdate = flowIds.filter(x => x > 0);

        if (idsToUpdate.length <= 0) {
            return;
        }
        dispatch(flowMultiSelectionClear());

        // Then make request to server
        dispatch(incAjaxCount());
        const moveUrl = "/Flows/MoveFlows";

        fetch(moveUrl, {
            method: "POST",
            credentials: "same-origin",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                flowIds: idsToUpdate,
                folderId,
            }),
        })
            .then(h.checkStatus)
            .then(toJson)
            .then(() => {
                dispatch(decAjaxCount());
            })
            .catch(error => {
                dispatch(decAjaxCount());
                h.error("Error moving flows", error);
            });
    };

export const requestProcessSelectedFlows = () => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const selectedFlows = state.flowMultiSelection.selectedFlowIds;
    const flowIds = selectedFlows.filter(x => x > 0);
    for (const flowId of flowIds) {
        dispatch(setFlowItemsIsRunning(flowId, FlowIsRunning.CLIENTSUBMITTED));
    }
    dispatch(flowMultiSelectionClear());

    dispatch(incAjaxCount());
    fetch(`/Flows/ClearAndProcessMultipleFlows`, {
        method: "POST",
        credentials: "same-origin",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            flowIds,
        }),
    })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            if (!data.result) {
                for (const flowId of flowIds) {
                    dispatch(setFlowItemsIsRunning(flowId, FlowIsRunning.IDLE));
                }
                if (data.error) {
                    h.error(data.error);
                } else {
                    throw new Error("Error calculating flows.");
                }
            } else {
                dispatch(notifyGreen("Flows submitted successfully."));
            }

            const flowItems = getFlowItemsArray(getState());
            const checkToCloud = flowItems.filter(x => flowIds.includes(x.FlowId) && x.FlowItemType == "toCloud");
            if (checkToCloud.length > 0) {
                dispatch(resetCloudFlowObjects());
            }

            dispatch(decAjaxCount());
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error calculating flows.", error);
        });
};

export const requestDeleteSelectedFlows = () => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const selectedFlow = state.selected.flow;
    const selectedFlows = state.flowMultiSelection.selectedFlowIds;
    const flowIds = selectedFlows.filter(x => x > 0);

    dispatch(incAjaxCount());
    // Local updates
    if (flowIds.includes(selectedFlow)) {
        dispatch(setSelectedFlowNoUnsavedCheck(-1));
    }
    //not sure why this is needed, just replicating what we currently do for a single delete
    dispatch(flowMultiSelectionClear());
    for (const flowId of flowIds) {
        dispatch(updateAttribute("flows", flowId, "hasUnsavedChanges", false));
        dispatch(deleteFlow(flowId));
    }

    // Server request
    return fetch("/Flows/DeleteFlows", {
        method: "POST",
        credentials: "same-origin",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            flowIds,
        }),
    })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            dispatch(decAjaxCount());
            if (!data.result) {
                if (data.error) {
                    h.error(data.error);
                    throw new Error(data.error);
                } else {
                    throw new Error("Error deleting flows.");
                }
            }
            dispatch(notifyGreen("Flows have been subbmitted to delete."));
        })
        .catch(error => {
            dispatch(decAjaxCount());
            h.error("Error deleting flows", error);
            throw new Error("Error deleting flows.");
        });
};

export const finalizeFlowDataLoads = (flowId: number) => (dispatch: Dispatch) => {
    dispatch(incAjaxCount());
    dispatch(updateAttribute("flows", flowId, "isSaving", true));
    // Server request
    return fetch("/Flows/ProcessFlowDataLoads", {
        method: "POST",
        credentials: "same-origin",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            flowId,
        }),
    })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            dispatch(decAjaxCount());
            dispatch(updateAttribute("flows", flowId, "isSaving", false));
            if (!data.success) {
                if (data.error) {
                    h.error(data.error);
                    throw new Error(data.error);
                } else {
                    throw new Error("Error finalizing dataloads.");
                }
            }
            dispatch(notifyGreen("Data loads are being processed."));
        })
        .catch(error => {
            dispatch(decAjaxCount());
            dispatch(updateAttribute("flows", flowId, "isSaving", false));
            h.error("Error finalizing dataloads.", error);
            throw new Error("Error finalizing dataloads.");
        });
};

//end flow-bulk-actions

export const requestSubmitModels = (flowId: number) => (dispatch: Dispatch) => {
    const url = "/Flows/SubmitModels";
    dispatch(updateAttribute("flows", flowId, "isSaving", true));

    fetch(url, {
        credentials: "same-origin",
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({
            flowId,
        }),
    })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            dispatch(updateAttribute("flows", flowId, "isSaving", false));
            if (data.result) {
                dispatch(notifyGreen("Models have been requested successfully."));
            } else {
                h.error("Error submitting flow models.");
            }
        })
        .catch(error => {
            dispatch(updateAttribute("flows", flowId, "isSaving", false));
            h.error("Error submitting flow models.", error);
        });
};

//update local status for isLoadingFlowData only if the flow exists in state!!
export const updateIsLoadingFlowData =
    (flowId: number, isLoadingFlowData: boolean) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const flow: ?Flow = state.flows.byId[flowId];

        if (flow == null) return;
        dispatch(updateAttribute("flows", flowId, "isLoadingFlowData", isLoadingFlowData));
    };

export const requestFlowScriptKeywords = () => (dispatch: Dispatch) => {
    dispatch({ type: "LOAD_FLOW_SCRIPT_KEYWORDS", keywords: [] });

    fetch("/Scripts/GetScriptKeywords", {
        credentials: "same-origin",
        method: "POST",
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
    })
        .then(h.checkStatus)
        .then(toJson)
        .then(data => {
            dispatch({ type: "LOAD_FLOW_SCRIPT_KEYWORDS", keywords: data.keywords });
        })
        .catch(error => {
            h.error("Error loading autocomplete keywords.", error);
        });
};

export const syncFlowOffloadColumns =
    (flowItemId: number, tableName: string) => (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const offloadColumnsByItemId = getFlowOffloadColumnsByFlowItemId(state);
        const tableColumns = state.flowItemResultTables.tableColumns[tableName] || [];
        const columnNames = tableColumns.map(x => x.ColumnName);
        const currentColumns = offloadColumnsByItemId[flowItemId] || [];
        let currentColumns2 = [...currentColumns];
        let needToChange = false;
        let nOrder = 1;
        let chgType = 0;

        tableColumns.forEach((element, index) => {
            let isDeployed = true;
            let nLength = 0;
            let nSortOrder = 0;
            if (index + 1 <= currentColumns2.length) {
                if (element.ColumnName != currentColumns2[index].ColumnName) {
                    needToChange = true;
                    chgType = 3;
                    if (currentColumns2.find(y => y.ColumnName == element.ColumnName)) {
                        const tmpColumn = currentColumns2.filter(x => x.ColumnName == element.ColumnName)[0];
                        isDeployed = tmpColumn.DeployColumn;
                        nLength = tmpColumn.ColumnLength;
                        nSortOrder = tmpColumn.SortOrdinance > 0 ? tmpColumn.SortOrdinance : 0;
                    }

                    const thing = {
                        FlowOffloadColumnId: 0,
                        FlowItemId: flowItemId,
                        FlowItem: null,
                        ColumnName: element.ColumnName,
                        DeployColumn: isDeployed,
                        ColumnLength: nLength > 0 ? nLength : element.MaxLength > 0 ? element.MaxLength : 10,
                        Order: nOrder,
                        SortOrdinance: nSortOrder,
                    };
                    currentColumns2.splice(nOrder - 1, 0, thing);
                } else if (currentColumns2[index].Order != nOrder) {
                    needToChange = true;
                    chgType = 3;
                    currentColumns2[index].Order = nOrder;
                }
            } else {
                const thing = {
                    FlowOffloadColumnId: 0,
                    FlowItemId: flowItemId,
                    FlowItem: null,
                    ColumnName: element.ColumnName,
                    DeployColumn: isDeployed,
                    ColumnLength: element.MaxLength > 0 ? element.MaxLength : 10,
                    Order: nOrder,
                    SortOrdinance: 0,
                };
                currentColumns2.splice(nOrder - 1, 0, thing);
                needToChange = true;
                chgType = 2;
            }
            nOrder++;
        });

        if (currentColumns2.length <= tableColumns.length) {
            currentColumns2.splice(nOrder - 1, tableColumns.length);
        } else {
            needToChange = true;
            if (chgType >= 2) {
                chgType = 3;
            } else {
                chgType = 1;
            }
            currentColumns2.splice(nOrder - 1, currentColumns2.length - tableColumns.length);
        }

        if (needToChange) {
            if (chgType == 3) {
                for (const col of currentColumns) {
                    dispatch(deleteFlowOffloadColumn(col.FlowOffloadColumnId));
                }
                nOrder = 1;
                for (const col of currentColumns2) {
                    dispatch(
                        newFlowOffloadColumn(
                            flowItemId,
                            col.ColumnName,
                            nOrder++,
                            col.ColumnLength,
                            col.DeployColumn,
                            col.SortOrdinance
                        )
                    );
                }
            } else if (chgType == 2) {
                const columnsToAdd = tableColumns.filter(x => !currentColumns.find(y => y.ColumnName == x.ColumnName));
                if (columnsToAdd.length > 0) {
                    let order =
                        currentColumns && currentColumns.length > 0
                            ? currentColumns[currentColumns.length - 1].Order + 1
                            : 1;

                    for (const col of columnsToAdd) {
                        dispatch(newFlowOffloadColumn(flowItemId, col.ColumnName, order++, col.MaxLength));
                    }
                }
            } else if (chgType == 1) {
                const columnsToDelete = currentColumns.filter(x => !columnNames.includes(x.ColumnName));

                if (columnsToDelete.length > 0) {
                    for (const col of columnsToDelete) {
                        dispatch(deleteFlowOffloadColumn(col.FlowOffloadColumnId));
                    }
                }
            }
        }
    };

export const setHasUnsavedLayoutChanges =
    (newHasUnsavedLayoutChanges: boolean = true) =>
    (dispatch: Dispatch, getState: GetState) => {
        const state = getState();
        const flowId: number = state.selected.flow;
        const flow: ?Flow = state.flows.byId[flowId];
        if (flow != null && flow.hasUnsavedLayoutChanges != newHasUnsavedLayoutChanges) {
            dispatch(updateAttribute("flows", flowId, "hasUnsavedLayoutChanges", newHasUnsavedLayoutChanges));
        }
    };

// General list of prefixesAndEntities, used in generic flowtype functions.
// Keep this at the bottom so the references to the deleteAc functions are defined.
const prefixesAndEntities = [
    {
        actionPrefix: "FLOW",
        stateKey: "flows",
        id: "FlowId",
        flowItemType: "",
        deleteAc: id => {
            console.error("There's no AC to delete a flow"); // eslint-disable-line no-console
            return { type: "CANNOT_DELETE_FLOW_" + id };
        }, // lol.  Don't use this!
    },
    { actionPrefix: "FLOW_ITEM", stateKey: "flowItems", id: "FlowItemId", flowItemType: "", deleteAc: deleteFlowItem },
    {
        actionPrefix: "FLOW_RELATION",
        stateKey: "flowRelations",
        id: "FlowRelationId",
        flowItemType: "",
        deleteAc: deleteFlowRelation,
    },
    {
        actionPrefix: "FLOW_FILTER",
        stateKey: "flowFilters",
        id: "FlowFilterId",
        flowItemType: "filter",
        deleteAc: deleteFlowFilter,
    },
    {
        actionPrefix: "FLOW_SCRIPT",
        stateKey: "flowScripts",
        id: "FlowScriptId",
        flowItemType: "script",
        deleteAc: deleteFlowScript,
    },
    {
        actionPrefix: "FLOW_SCRIPT_DBUI",
        stateKey: "flowScriptsDBUI",
        id: "FlowScriptDBUIId",
        flowItemType: "scriptdbui",
        deleteAc: deleteFlowScriptDBUI,
    },
    {
        actionPrefix: "FLOW_SCRIPT_RESULT",
        stateKey: "flowScriptResults",
        id: "FlowScriptResultId",
        flowItemType: "ScriptResult",
        deleteAc: deleteFlowScriptResult,
    },
    {
        actionPrefix: "FLOW_SCRIPT_RESULTS_HISTORY",
        stateKey: "flowScriptResultsHistory",
        id: "FlowScriptResultId",
        flowItemType: "ScriptResultsHistory",
        deleteAc: null,
    },
    {
        actionPrefix: "FLOW_DATA_LOAD",
        stateKey: "flowDataLoads",
        id: "FlowDataLoadId",
        flowItemType: "dataload",
        deleteAc: deleteFlowDataLoad,
    },
    {
        actionPrefix: "FLOW_DATA_LOAD_COLUMN",
        stateKey: "flowDataLoadColumns",
        id: "FlowDataLoadColumnId",
        flowItemType: "FlowDataLoadColumn",
        deleteAc: deleteFlowDataLoadColumn,
    },
    {
        actionPrefix: "FLOW_MULTI_EXPORT",
        stateKey: "flowMultiExports",
        id: "FlowMultiExportId",
        flowItemType: "multiexport",
        deleteAc: deleteFlowMultiExport,
    },
    {
        actionPrefix: "FLOW_EXPORT",
        stateKey: "flowExports",
        id: "FlowExportId",
        flowItemType: "export",
        deleteAc: deleteFlowExport,
    },
    {
        actionPrefix: "FLOW_OUTPUT",
        stateKey: "flowOutputs",
        id: "FlowOutputId",
        flowItemType: "output",
        deleteAc: deleteFlowOutput,
    },
    {
        actionPrefix: "FLOW_SINGLE_VIEW",
        stateKey: "flowSingleViews",
        id: "FlowSingleViewId",
        flowItemType: "singleview",
        deleteAc: deleteFlowSingleView,
    },
    {
        actionPrefix: "FLOW_FROM_CLOUD",
        stateKey: "flowFromClouds",
        id: "FlowFromCloudId",
        flowItemType: "fromCloud",
        deleteAc: deleteFlowFromCloud,
    },
    {
        actionPrefix: "FLOW_TO_CLOUD",
        stateKey: "flowToClouds",
        id: "FlowToCloudId",
        flowItemType: "toCloud",
        deleteAc: deleteFlowToCloud,
    },
    {
        actionPrefix: "FLOW_SVDEDUPE",
        stateKey: "flowSVDedupes",
        id: "FlowSVDedupeId",
        flowItemType: "svDedupe",
        deleteAc: deleteFlowSVDedupe,
    },
    {
        actionPrefix: "FLOW_MERGE",
        stateKey: "flowMerges",
        id: "FlowMergeId",
        flowItemType: "merge",
        deleteAc: deleteFlowMerge,
    },
    {
        actionPrefix: "FLOW_OFFER_MERGE",
        stateKey: "flowOfferMerges",
        id: "FlowOfferMergeId",
        flowItemType: "offerMerge",
        deleteAc: deleteFlowOfferMerge,
    },
    {
        actionPrefix: "FLOW_REPORT",
        stateKey: "flowReports",
        id: "FlowReportId",
        flowItemType: "report",
        deleteAc: deleteFlowReport,
    },
    {
        actionPrefix: "FLOW_MODEL",
        stateKey: "flowModels",
        id: "FlowModelId",
        flowItemType: "model",
        deleteAc: deleteFlowModel,
    },
    {
        actionPrefix: "FLOW_EXPORT_REPORT",
        stateKey: "flowExportReports",
        id: "FlowExportReportId",
        flowItemType: "exportreport",
        deleteAc: deleteFlowExportReport,
    },
    {
        actionPrefix: "FLOW_CANNED_REPORT",
        stateKey: "flowCannedReports",
        id: "FlowCannedReportId",
        flowItemType: "cannedreport",
        deleteAc: deleteFlowCannedReport,
    },
    {
        actionPrefix: "FLOW_SPLIT",
        stateKey: "flowSplits",
        id: "FlowSplitId",
        flowItemType: "split",
        deleteAc: deleteFlowSplit,
    },
    {
        actionPrefix: "FLOW_CASE",
        stateKey: "flowCases",
        id: "FlowCaseId",
        flowItemType: "case",
        deleteAc: deleteFlowCase,
    },
    {
        actionPrefix: "FLOW_EMPTY",
        stateKey: "flowEmpties",
        id: "FlowEmptyId",
        flowItemType: "empty",
        deleteAc: deleteFlowEmpty,
    },
    {
        actionPrefix: "FLOW_CLIENT_VARIABLE",
        stateKey: "flowClientVariables",
        id: "Id",
        flowItemType: "FlowClientVariableD",
        deleteAc: deleteFlowClientVariable,
    },
    {
        actionPrefix: "FLOW_ITEM_CLIENT_VARIABLE",
        stateKey: "flowItemClientVariables",
        id: "FlowItemClientVariableId",
        flowItemType: "FlowItemClientVariableD",
        deleteAc: deleteFlowItemClientVariable,
    },
    {
        actionPrefix: "FLOW_ENDPOINT",
        stateKey: "flowItemEndpoints",
        id: "Id",
        flowItemType: "FlowItemEndpoint",
        deleteAc: deleteFlowItemEndpoint,
    },
    {
        actionPrefix: "FLOW_ITEM_OFFER_CODE",
        stateKey: "flowItemOfferCodes",
        id: "FlowItemOfferCodeId",
        flowItemType: "FlowItemOfferCode",
        deleteAc: deleteFlowItemOfferCode,
    },
    //begin - flow-export-distribution-platforms
    {
        actionPrefix: "FLOW_EXPORT_DISTRIBUTION_PLATFORM",
        stateKey: "flowExportDistributionPlatforms",
        id: "FlowExportDistributionPlatformId",
        flowItemType: "FlowExportDistributionPlatform",
        deleteAc: deleteFlowExportDistributionPlatform,
    },
    //end - flow-export-distribution-platforms
    //
    //begin flow-export-pivot-labels
    {
        actionPrefix: "FLOW_RELATION_PARENT_LABEL",
        stateKey: "flowRelationParentLabels",
        id: "FlowRelationParentLabelId",
        flowItemType: "",
        deleteAc: deleteFlowRelationParentLabel,
    },
    //end flow-export-pivot-labels
    //
    // begin - flow-tool-offload
    {
        actionPrefix: "FLOW_OFFLOAD",
        stateKey: "flowOffloads",
        id: "FlowOffloadId",
        flowItemType: "offload",
        deleteAc: deleteFlowOffload,
    },
    {
        actionPrefix: "FLOW_OFFLOAD_COLUMN",
        stateKey: "flowOffloadColumns",
        id: "FlowOffloadColumnId",
        flowItemType: "FlowOffloadColumn",
        deleteAc: deleteFlowOffloadColumn,
    },
    // end - flow-tool-offload
    {
        actionPrefix: "FLOW_GUIDE_SETTINGS",
        stateKey: "flowGuideSettings",
        id: "FlowGuideId",
        flowItemType: "FlowGuideSetting",
        deleteAc: id => {
            console.error("There's no AC to delete a flow guide setting"); // eslint-disable-line no-console
            return { type: "CANNOT_DELETE_FLOW_GUIDE_SETTING" + id };
        }, // should not need a delete
    },
    {
        actionPrefix: "FLOW_SEGMENT_SPLIT",
        stateKey: "flowSegmentSplits",
        id: "SegmentSplitId",
        flowItemType: "FlowSegmentSplit",
        deleteAc: deleteFlowSegmentSplit,
    },
    {
        actionPrefix: "FLOW_SEGMENT_SPLIT_OFFER",
        stateKey: "flowSegmentSplitOffers",
        id: "SegmentSplitOfferId",
        flowItemType: "FlowSegmentSplitOffer",
        deleteAc: deleteFlowSegmentSplitOffer,
    },
    {
        actionPrefix: "FLOW_EXPORT_TEMPLATE_FIELDS",
        stateKey: "flowExportTemplateFields",
        id: "FlowExportTemplateId",
        flowItemType: "FlowExportTemplateFields",
        deleteAc: deleteFlowExportTemplateFields,
    },
    {
        actionPrefix: "FLOW_EXPORT_PINTEREST_TEMPLATE_FIELDS",
        stateKey: "flowExportPinterestTemplateFields",
        id: "FlowExportTemplateId",
        flowItemType: "FlowExportPinterestTemplateFields",
        deleteAc: deleteFlowExportPinterestTemplateFields,
    },
    {
        actionPrefix: "FLOW_EXPORT_TIKTOK_TEMPLATE_FIELDS",
        stateKey: "flowExportTikTokTemplateFields",
        id: "FlowExportTemplateId",
        flowItemType: "FlowExportTikTokTemplateFields",
        deleteAc: deleteFlowExportTikTokTemplateFields,
    },
    {
        actionPrefix: "FLOW_EXPORT_TRADE_DESK_TEMPLATE_FIELDS",
        stateKey: "flowExportTradeDeskTemplateFields",
        id: "FlowExportTemplateId",
        flowItemType: "FlowExportTradeDeskTemplateFields",
        deleteAc: deleteFlowExportTradeDeskTemplateFields,
    },
    {
        actionPrefix: FREEWHEEL_DRIVER_FILE_FIELDS,
        stateKey: "flowExportFreewheelDriverFileFields",
        id: "FlowExportFreewheelDriverFileFieldId",
        flowItemType: "FlowExportFreewheelDriverFileFields",
        deleteAc: deleteFlowExportFreewheelDriverFileFields,
    },
    {
        actionPrefix: "FLOW_EXPORT_TAXONOMY_FIELDS",
        stateKey: "flowExportTaxonomyFileFields",
        // id: "FlowExportTemplateId",
        id: "FlowExportTaxonomyFileFieldId",
        flowItemType: "FlowExportTaxonomyFileFields",
        deleteAc: deleteFlowExportTaxonomyFileFields,
    },
    {
        ...xandrDriverFieldsPrefixAndEntity,
    },
    {
        actionPrefix: "FLOW_EXTERNAL_SERVICE",
        stateKey: "flowExternalServices",
        id: "FlowExternalServiceId",
        flowItemType: "flowControl",
        deleteAc: deleteFlowExternalService,
    },
    {
        actionPrefix: "FLOW_EXTERNAL_SERVICE_PARAMETER",
        stateKey: "flowExternalServiceParameters",
        id: "FlowServiceParameterId",
        flowItemType: "FlowExternalServiceParameter",
        deleteAc: deleteFlowExternalServiceParameter,
    },
    {
        actionPrefix: "FLOW_EXPRESSION",
        stateKey: "flowExpressions",
        id: "FlowExpressionId",
        flowItemType: "FlowExpression",
        deleteAc: deleteFlowExpression,
    },
    {
        actionPrefix: "FLOW_EXPRESSION_CONSTRAINT",
        stateKey: "flowExpressionConstraints",
        id: "FlowExpressionConstraintId",
        flowItemType: "FlowExpressionConstraint",
        deleteAc: deleteFlowExpressionConstraint,
    },
    {
        actionPrefix: "FLOW_DESCRIPTION",
        stateKey: "flowDescriptions",
        id: "FlowDescriptionId",
        flowItemType: "FlowDescription",
        deleteAc: deleteFlowDescription,
    },
    {
        actionPrefix: "FLOW_EXTERNAL_SERVICE_INPUT",
        stateKey: "flowExternalServiceInputs",
        id: "InputId",
        flowItemType: "FlowExternalServiceInput",
        deleteAc: deleteFlowExternalServiceInput,
    },
    {
        actionPrefix: "FLOW_EXTERNAL_SERVICE_HARDCODE",
        stateKey: "flowExternalServiceHardcodes",
        id: "HardcodeId",
        flowItemType: "FlowExternalServiceHardcode",
        deleteAc: deleteFlowExternalServiceHardcode,
    },
];

export const setCanvasInfo = (width: number, height: number, scrollLeft: number, scrollTop: number) => ({
    type: "CYCLONE_SET_CANVAS_INFO",
    width,
    height,
    scrollLeft,
    scrollTop,
});

export const setFlowItemRenamingName = (flowItemId: number, renamingName: string) => ({
    type: "SET_FLOW_ITEM_RENAMING_NAME",
    flowItemId,
    renamingName,
});

export const setFlowItemLayoutErrors = (flowItemLayoutErrors: Array<IFlowItemLayoutError>) => ({
    type: SET_FLOWITEM_LAYOUT_ERRORS,
    flowItemLayoutErrors,
});
