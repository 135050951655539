import { Dispatch } from "../types/types";
import { request } from "../helpers/httpInterceptor";
import h from "../helpers";
import { notifyGreen, notifyError } from "./notifyActions";
import { updateAttribute } from "./flowActions";
import {
    ISetExternalParameterValues,
    ISetFlowControlData,
    SET_EXTERNAL_PARAMETER_VALUES,
    SET_FLOW_CONTROL_DATA,
} from "../reducers/flowControlData";
import { SET_FLOW_CONTROL_STATUS } from "../reducers/flowControlStatus";
import {
    SET_SERVICE_LAYOUT_DATA,
    SET_SERVICE_LAYOUT_SAVING,
    SET_SERVICE_LAYOUT_SAVING_AS_NEW,
    SET_SERVICE_SELECTED_LAYOUT,
    SET_IMPORT_LAYOUT_FIELDS,
    SET_IMPORT_LAYOUT_IS_FIXED,
    SET_EXTERNAL_SERVICE_FIELDS_USED,
    SET_EXPORT_LOCKED_OUT,
} from "../reducers/externalServiceLayoutData";
import {
    IFlowControlStatus,
    IGetFlowControlStatus,
    IUEComplianceRequestResult,
    ISaveExternalServiceLayoutData,
    IFlowControlDataResult,
    IExternalServiceLayoutDataResult,
    IFieldPerson,
    IExternalParameterValue,
} from "../types/stores/flowControlData";
import {
    IExternalServiceField,
    IExternalServiceLayoutField,
    IImportLayoutField,
    ISetExternalServiceLayoutData,
    ISetExternalServiceLayoutSaving,
    ISetExternalServiceLayoutSavingAsNew,
    ISetExternalServiceSelectedLayout,
    ISetImportLayoutFields,
    ISetImportLayoutIsFixed,
    ISetExternalServiceFieldsUsed,
    ISetExportLockedOut,
} from "../types/stores/externalServiceLayoutData";
import {
    IEnrichmentField,
    IEnrichmentFieldsItem,
    ISetEnrichmentFields,
    ISetCurrEnrichmentFields,
    ISetSelectedFieldsItem,
    ISetIsSearching,
    ISetIsLoading,
    ISetIsValidating,
    IEnrichmentFieldImport,
    ISetValidatedFields,
    IEnrichmentImportField,
    ISetSelectedItemId,
} from "../types/stores/umbrellaData";
import {
    SET_ENRICHMENT_FIELDS,
    SET_CURR_ENRICHMENT_FIELDS,
    SET_SELECTED_FIELDS_ITEM,
    SET_IS_SEARCHING,
    SET_IS_LOADING,
    SET_IS_VALIDATING,
    SET_VALIDATED_FIELDS,
    SET_SELECTED_ITEMID,
} from "../reducers/umbrellaData";
import { LayoutTypes } from "../components/layouts-external-service/LayoutBuilder";
import { IAppState } from "../types/stores";
import { getFlowExternalServiceForSelectedFlowItem } from "../reducers/flowExternalServices";
import { UEFieldParms } from "../helpers/constants";
import { getFlowExternalServiceParametersForSelectedFlowItem } from "../reducers/flowExternalServiceParameters";

export const requestFlowControlData =
    () =>
    (dispatch: Dispatch): void => {
        request("/FlowControl/GetAllFlowControlData", { credentials: "same-origin" }, dispatch)
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch(setFlowControlData(data.flowControlData));
            })
            .catch(error => {
                h.error("Error getting flow control data.", error);
            });
    };

export const requestFlowControlStatusData =
    () =>
    (dispatch: Dispatch): void => {
        dispatch(setFlowControlStatus([], [], [], true));
        request(
            `/FlowControl/GetFlowControlStatus`,
            {
                method: "POST",
                credentials: "same-origin",
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch(setFlowControlStatus(data.running, data.completed, data.failed, false));
            })
            .catch(error => {
                h.error("Error getting flow control status data.", error);
            });
    };

export const requestExternalServiceLayoutData =
    (companyId: number) =>
    (dispatch: Dispatch): void => {
        request(
            `/FlowControl/GetExternalServiceLayouts?companyId=${companyId}`,
            { method: "POST", credentials: "same-origin" },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch(setExternalServiceLayoutData(data.serviceLayouts));
            })
            .catch(error => {
                h.error("Error getting service layout data.", error);
            });
    };

export const requestAddExternalServiceLayout =
    (layoutToAdd: ISaveExternalServiceLayoutData, isSaveAsNew: boolean, WFClientCode: string) =>
    (dispatch: Dispatch): void => {
        if (isSaveAsNew) dispatch(setExternalServiceLayoutSavingAsNew(true));
        else dispatch(setExternalServiceLayoutSaving(true));

        const Layout = layoutToAdd.Layout;
        const Fields = layoutToAdd.Fields;

        request(
            `/FlowControl/SaveLayout`,
            {
                credentials: "same-origin",
                method: "POST",
                body: JSON.stringify({
                    Layout,
                    Fields,
                    WFClientCode,
                }),
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                if (data && data.Success) {
                    dispatch(notifyGreen("Successfully saved your layout."));
                    dispatch(requestExternalServiceLayoutData(Layout.CompanyId));
                    if (layoutToAdd.flowExternalServiceId) {
                        dispatch(
                            updateAttribute(
                                "flowExternalServices",
                                layoutToAdd.flowExternalServiceId,
                                Layout.LayoutType == LayoutTypes.Input ? "InputLayoutId" : "OutputLayoutId",
                                data.LayoutId
                            )
                        );
                    }
                    dispatch(setServiceSelectedLayout(0));
                    dispatch(changeExternalServiceFieldsUsed(data.LayoutId, Fields));
                } else if (data && !data.Success) {
                    h.error("Error saving layout.", data.Message);
                }

                if (isSaveAsNew) dispatch(setExternalServiceLayoutSavingAsNew(false));
                else dispatch(setExternalServiceLayoutSaving(false));
            })
            .catch(error => {
                h.error("Error saving layout.", error);

                if (isSaveAsNew) dispatch(setExternalServiceLayoutSavingAsNew(false));
                else dispatch(setExternalServiceLayoutSaving(false));
            });
    };

export const requestEnrichmentFields =
    (flowItemId: number) =>
    (dispatch: Dispatch): void => {
        dispatch(setLoadingEnrichmentFields(true));
        request("/FlowControl/GetEnrichmentFields?flowItemId=" + flowItemId, { credentials: "same-origin" }, dispatch)
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                if (data.fields) {
                    dispatch(setSelectedEnrichmentFieldsItem(data.fields));
                } else {
                    h.error("No selected enrichment fields found.");
                    dispatch(setSelectedEnrichmentFieldsItem([]));
                }
                dispatch(setSelectedEnrichmentItemId(flowItemId));
                dispatch(setLoadingEnrichmentFields(false));
            })
            .catch(error => {
                h.error("Error getting selected enrichment fields.", error);
                dispatch(setLoadingEnrichmentFields(false));
                dispatch(setSelectedEnrichmentFieldsItem([]));
            });
    };

export const getEnrichmentFieldListByIdAsync =
    (fieldIds: Array<number>) =>
    (dispatch: Dispatch): void => {
        dispatch(setLoadingEnrichmentFields(true));
        request(
            "/FlowControl/GetEnrichmentFieldListByIdAsync",
            {
                credentials: "same-origin",
                method: "POST",
                body: fieldIds,
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                if (data.fields) {
                    dispatch(setCurrEnrichmentFields(data.fields));
                } else {
                    h.error("No current enrichment fields found.");
                }
                dispatch(setLoadingEnrichmentFields(false));
            })
            .catch(error => {
                h.error("Error getting current enrichment fields by id.", error);
                dispatch(setLoadingEnrichmentFields(false));
            });
    };

export const searchEnrichmentFields =
    (text: string) =>
    (dispatch: Dispatch): void => {
        dispatch(setSearchingEnrichmentFields(true));
        request("/FlowControl/SearchEnrichmentFields?searchText=" + text, { credentials: "same-origin" }, dispatch)
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                if (data.fields) {
                    dispatch(setEnrichmentFields(data.fields));
                } else {
                    h.error("No available enrichment fields returned.");
                }
                dispatch(setSearchingEnrichmentFields(false));
            })
            .catch(error => {
                h.error("Error getting available enrichment fields.", error);
                dispatch(setSearchingEnrichmentFields(false));
            });
    };

export const setFlowControlData = (data: IFlowControlDataResult): ISetFlowControlData => ({
    type: SET_FLOW_CONTROL_DATA,
    externalServices: data.ExternalServices,
    externalParameters: data.ExternalParameters,
    externalParameterValues: data.ExternalParameterValues,
    externalServiceParameters: data.ExternalServiceParameters,
    externalFileLocation: data.ExternalFileLocationOutputTrue,
    externalInputFileLocation: data.ExternalFileLocationInputTrue,
    externalServiceDefaultFields: data.ExternalServiceDefaultFields,
});

export const setExternalParameterValues = (data: Array<IExternalParameterValue>): ISetExternalParameterValues => ({
    type: SET_EXTERNAL_PARAMETER_VALUES,
    externalParameterValues: data,
});

export const setFlowControlStatus = (
    running: Array<IFlowControlStatus>,
    completed: Array<IFlowControlStatus>,
    failed: Array<IFlowControlStatus>,
    loading: boolean
): IGetFlowControlStatus => ({
    type: SET_FLOW_CONTROL_STATUS,
    running,
    completed,
    failed,
    loading,
});

export const setExternalServiceLayoutData = (
    data: IExternalServiceLayoutDataResult
): ISetExternalServiceLayoutData => ({
    type: SET_SERVICE_LAYOUT_DATA,
    externalServiceFields: data.ExternalServiceFields,
    externalServiceLayouts: data.ExternalServiceLayouts,
    externalServiceLayoutFields: data.ExternalServiceLayoutFields,
    externalServiceClientFieldAlias: data.ExternalServiceClientFieldAlias,
});

export const setExternalServiceLayoutSaving = (layoutIsSaving: boolean): ISetExternalServiceLayoutSaving => ({
    type: SET_SERVICE_LAYOUT_SAVING,
    layoutIsSaving,
});

export const setExternalServiceLayoutSavingAsNew = (
    layoutIsSavingAsNew: boolean
): ISetExternalServiceLayoutSavingAsNew => ({
    type: SET_SERVICE_LAYOUT_SAVING_AS_NEW,
    layoutIsSavingAsNew,
});

export const setServiceSelectedLayout = (selectedLayout: number): ISetExternalServiceSelectedLayout => ({
    type: SET_SERVICE_SELECTED_LAYOUT,
    selectedLayout,
});

export const setEnrichmentFields = (fields: Array<IEnrichmentField>): ISetEnrichmentFields => ({
    type: SET_ENRICHMENT_FIELDS,
    enrichmentFields: fields,
});

export const setCurrEnrichmentFields = (fields: Array<IEnrichmentField>): ISetCurrEnrichmentFields => ({
    type: SET_CURR_ENRICHMENT_FIELDS,
    currEnrichmentFields: fields,
});

export const setSelectedEnrichmentFieldsItem = (fields: Array<IEnrichmentFieldsItem>): ISetSelectedFieldsItem => ({
    type: SET_SELECTED_FIELDS_ITEM,
    selectedFieldsItem: fields,
});

export const setSelectedEnrichmentItemId = (itemId: number): ISetSelectedItemId => ({
    type: SET_SELECTED_ITEMID,
    selectedItemId: itemId,
});

export const setSearchingEnrichmentFields = (searching: boolean): ISetIsSearching => ({
    type: SET_IS_SEARCHING,
    isSearching: searching,
});

export const setLoadingEnrichmentFields = (loading: boolean): ISetIsLoading => ({
    type: SET_IS_LOADING,
    isLoading: loading,
});

export const setIsValidatingEnrichment = (isValidating: boolean): ISetIsValidating => ({
    type: SET_IS_VALIDATING,
    isValidating,
});

export const setValidatedImportFields = (fields: Array<IEnrichmentFieldImport>): ISetValidatedFields => ({
    type: SET_VALIDATED_FIELDS,
    validatedFields: fields,
});

export const setImportedLayoutFields = (importedFields: Array<IImportLayoutField>): ISetImportLayoutFields => ({
    type: SET_IMPORT_LAYOUT_FIELDS,
    importedFields,
});

export const setImportedLayoutIsFixed = (importedIsFixed: boolean): ISetImportLayoutIsFixed => ({
    type: SET_IMPORT_LAYOUT_IS_FIXED,
    importedIsFixed,
});

export const setExternalServiceFieldsUsed = (
    externalServiceFieldsUsed: Array<IExternalServiceField>
): ISetExternalServiceFieldsUsed => ({
    type: SET_EXTERNAL_SERVICE_FIELDS_USED,
    externalServiceFieldsUsed,
});

export const setExportLockedOut = (isLockedOut: boolean): ISetExportLockedOut => ({
    type: SET_EXPORT_LOCKED_OUT,
    isLockedOut,
});

export const getExportOutputLayoutColumns =
    (outputColumnNameOpt: number, outputLayoutId: number, altUENames: boolean, clientCode: string) =>
    (dispatch: Dispatch): void => {
        setExportLockedOut(true);
        const url = `/FlowControl/ExportOutputLayoutColumns?outputColumnNameOpt=${outputColumnNameOpt}&outputlayoutId=${outputLayoutId}&alternateUENames=${altUENames}&clientCode=${clientCode}`;
        request(
            url,
            {
                credentials: "same-origin",
                method: "GET",
            },
            dispatch
        )
            .then(response => response.blob())
            .then(blob => {
                setTimeout(() => {
                    setExportLockedOut(false);
                }, 3000);
                // Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const anchorLink = document.createElement("a");
                anchorLink.href = url;
                anchorLink.setAttribute("download", `Output Layout Column Format.csv`);

                // Append to html anchorLink element page
                document.body.appendChild(anchorLink);

                // Start download
                anchorLink.click();

                // Clean up and remove the anchorLink
                anchorLink.parentNode?.removeChild(anchorLink);
            })
            .catch(error => {
                setTimeout(() => {
                    setExportLockedOut(false);
                }, 3000);
                h.error("Error downloading file.", error);
            });
    };

export const requestFCLayoutDelete =
    (layoutId: number, companyId: number) =>
    (dispatch: Dispatch): void => {
        request(
            `/FlowControl/RemoveLayout`,
            {
                credentials: "same-origin",
                method: "POST",
                body: JSON.stringify({
                    layoutId,
                }),
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                if (!data.success) {
                    const existerrorcode = "EXIST_RELATIONSHIP";
                    const existerror = "You can't delete the layout, checking if it's being used.";
                    if (data.message === existerrorcode) {
                        dispatch(notifyError(existerror));
                    } else {
                        throw new Error("Error deleting layout.");
                    }
                } else {
                    dispatch(notifyGreen("Successfully deleted your layout."));
                    dispatch(requestExternalServiceLayoutData(companyId));
                }
            });
    };

export const validateUEFieldsCompliance =
    (isManualRefresh: boolean = false) =>
    (dispatch: Dispatch, getState: () => IAppState): void => {
        const state = getState();
        const companyId = state.session.companyId;
        const selectedFields = state.umbrellaData.selectedFieldsItem;
        const flowExternalService = getFlowExternalServiceForSelectedFlowItem(state);
        if (
            companyId &&
            flowExternalService &&
            (!flowExternalService.UECompliancePassed || isManualRefresh) &&
            selectedFields.length > 0
        ) {
            dispatch(
                requestUEFieldsCompliance(
                    companyId,
                    selectedFields.map(x => x.FieldId),
                    flowExternalService.FlowExternalServiceId,
                    flowExternalService.WFClientKey
                )
            );
        }
    };

export const requestUEFieldsCompliance =
    (companyId: number, fieldIds: Array<number>, flowExternalServiceId: number, wfClientKey: number) =>
    (dispatch: Dispatch): void => {
        const url = `/FlowControl/GetFieldsCompliance`;
        dispatch(updateAttribute("flowExternalServices", flowExternalServiceId, "UECompliancePassed", false));
        dispatch(setIsValidatingEnrichment(true));

        request(
            url,
            {
                credentials: "same-origin",
                method: "POST",
                body: JSON.stringify({
                    companyId,
                    fieldIds,
                    wfClientKey,
                }),
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                if (data && data.result) {
                    const result = data.result as IUEComplianceRequestResult;
                    const complianceStatus = result.compliance_status.toUpperCase() == "PASS";
                    const fieldsFailintCompliance = result.fields_fail_compliance;

                    dispatch(
                        updateAttribute(
                            "flowExternalServices",
                            flowExternalServiceId,
                            "UECompliancePassed",
                            complianceStatus
                        )
                    );
                    dispatch(
                        updateAttribute(
                            "flowExternalServices",
                            flowExternalServiceId,
                            "FieldsFailingCompliance",
                            fieldsFailintCompliance
                        )
                    );

                    if (complianceStatus) {
                        dispatch(notifyGreen("Compliance status passed for current fields."));
                    } else {
                        h.error("Some of the fields selected don't pass Universal Enrichment compliance.");
                    }
                    dispatch(setIsValidatingEnrichment(false));
                } else {
                    dispatch(setIsValidatingEnrichment(false));
                    h.error(
                        "There was an error validating universal enrichment compliance.",
                        "Data couldn't be parsed."
                    );
                }
            })
            .catch(error => {
                dispatch(setIsValidatingEnrichment(false));
                h.error("There was an error validating universal enrichment compliance.", error);
                dispatch(updateAttribute("flowExternalServices", flowExternalServiceId, "UECompliancePassed", false));
            });
    };

export const validateImportedEnrichmentFields =
    (flowItemId: number, importFields: Array<IEnrichmentImportField>) =>
    (dispatch: Dispatch): void => {
        dispatch(setIsValidatingEnrichment(true));
        request(
            `/FlowControl/ValidateUEImport`,
            {
                credentials: "same-origin",
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify({
                    flowItemId,
                    importFields,
                }),
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                if (data.validatedFields) {
                    dispatch(setValidatedImportFields(data.validatedFields));
                } else {
                    h.error("No validated enrichment fields returned.");
                    dispatch(setValidatedImportFields([]));
                }
                dispatch(setIsValidatingEnrichment(false));
            })
            .catch(error => {
                h.error("Error validating enrichment fields.", error);
                dispatch(setIsValidatingEnrichment(false));
                dispatch(setValidatedImportFields([]));
            });
    };

export const changeExternalServiceFieldsUsed =
    (layoutId: number | undefined | null, _currentFields?: Array<IExternalServiceLayoutField> | undefined | null) =>
    (dispatch: Dispatch, getState: () => IAppState): void => {
        if (layoutId) {
            const state = getState();
            const currentFields =
                _currentFields == null
                    ? state.externalServiceLayoutData.externalServiceLayoutFields.filter(x => x.LayoutId == layoutId)
                    : [..._currentFields];
            const serviceFields = state.externalServiceLayoutData.externalServiceFields;

            const serviceFieldsUsed =
                currentFields?.length > 0
                    ? serviceFields.filter(f => currentFields.some(x => x.ServiceFieldId == f.ServiceFieldId))
                    : [];

            dispatch(setExternalServiceFieldsUsed(serviceFieldsUsed));
        } else {
            dispatch(setExternalServiceFieldsUsed([]));
        }
    };

export const AddAdditionalPersons =
    (persons: Array<IFieldPerson>, fieldId: number) =>
    (dispatch: Dispatch, getState: () => IAppState): void => {
        const state = getState();
        const externalParameters = state.flowControlData.externalParameters;

        const personIndex = persons.findIndex(x => x.FieldId == fieldId);
        if (personIndex >= 0) {
            const personNumber = parseInt(persons[personIndex].PersonNumber);
            const parmNames = UEFieldParms.filter(x => x.substring(0, 5) == "adult");
            const adultParms = externalParameters.filter(x => parmNames.includes(x.ParameterName));

            dispatch(UpdateServiceParameter(fieldId, "Field_List"));
            for (let i = personNumber; i >= 2; i--) {
                const thisParm = adultParms!.find(x => x.ParameterName.endsWith(i.toString()));
                dispatch(UpdateServiceParameter(fieldId, thisParm?.ParameterName));
            }
        }
    };

export const UpdateServiceParameter =
    (fieldId: number, parameterName: string | undefined) =>
    (dispatch: Dispatch, getState: () => IAppState): void => {
        const state = getState();
        const externalParameters = state.flowControlData.externalParameters;
        const flowServiceParameters = getFlowExternalServiceParametersForSelectedFlowItem(state);

        const fieldList = externalParameters.find(x => x.ParameterName == parameterName);
        const itemParameter = flowServiceParameters.find(x => x.ParameterId == fieldList?.ParameterId);
        let fieldValues: Array<string> = [];

        if (itemParameter!.Value.length > 0) {
            fieldValues = itemParameter!.Value.split(",");
        }

        if (!fieldValues.includes(fieldId.toString())) {
            fieldValues.push(fieldId.toString());
        }

        let sortedValues = fieldValues.map(x => parseInt(x));

        dispatch(
            updateAttribute(
                "flowExternalServiceParameters",
                itemParameter!.FlowServiceParameterId,
                "Value",
                sortedValues.length == 1 ? sortedValues[0].toString() : sortedValues.join(",")
            )
        );
    };
